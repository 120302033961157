import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'understory',
  title: 'The Understory',
  sections: [
    {
      layout: 'mediaTopTextBottom',
      media: [{
        orientation: 'horizontal',
        src: link('understory/understory-1.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>The Understory</div>
            </div>
          )
        }
      }],
      text: {
        items: [
          `Architecture by Heliotrope Architects. Contractor: Tye Contracting`
        ]
      }
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Subtly tucked between the forest canopy and underbrush, a remote getaway masterfully enhances the attributes that drew the city dwellers to the setting: unobstructed views and a forested landscape.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('understory/understory-1.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `With an underlying ethos of ecology, economy, and human experience, Heliotrope Architects aimed to capture the site’s uniqueness with minimal impact.`,
          `The structure was slipped into a natural clearing (only one tree was impacted – and it’s showing new growth), existing quietly amidst the Douglas firs and Pacific madrones that proliferate the shores of Orcas Island, an hour-plus drive and relaxation-inducing ferry ride north of Seattle.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('understory/understory-2.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `A subtle ecosystem is at play. As the home relates to the site, the interior and exterior also have a strong two-way conversation.`,
          `"When you are inside, the whole house disappears, putting the focus on the trees and the views," say the homeowners.`
        ]
      },
      media: [{orientation: 'square', src: link('understory/understory-3.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Functional living areas needed to fold into the architecture quietly, while still being strong enough in performance and quality to stand up to the real demands of living. Something that looked simple, yet is anything but.`,
          `Short on time and resources, the architects turned to Space Theory, who they knew could deliver.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('understory/understory-4.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Space Theory travels far and wide. Like this project on the shores of Orcas Island to the mountains of Telluride or a faraway town in Kentucky, we work remotely on 90% of our projects, so we’ve been perfecting our methods for almost 20 years.`,
          `For clients with the highest standards, it means access to the most sophisticated blend of design and function regardless of where they are seeking refuge.`,
          {render: () => (
            <a className='text-item-link' href='https://henrybuilt.com/page/where-we-work' target='_blank'>
              WHERE WE WORK
            </a>
          )}
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `A simplified form only possible through the distillation of years of experience and knowledge – and a flexible system approach – Space Theory (from the creators of Henrybuilt) made realizing a high quality, refined, and super functional kitchen not such a daunting task.`,
          {render: () => (
            <a className='text-item-link' href='/page/a-complete-system' target='_blank'>
              LEARN MORE ABOUT SPACE THEORY'S COMPLETE SYSTEM
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('understory/understory-5.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `With Space Theory, simple doesn’t mean "off the shelf".  The flexible vocabulary gave room for the architects to design a kitchen that integrated quietly and uniquely.`
        ]
      },
      media: [{orientation: 'square', src: link('understory/understory-6.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `"We didn’t have to compromise on anything," said Heliotrope Principal Joe Herrin. "We were able to leverage and build upon the system with unique elements - like the architectural metalwork of the island - while relying on the quality and standards they have already worked out."`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('understory/understory-7.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The cooking side of the kitchen sits within the dark wood clad wall. Framed like the architecture frames the views.`,
          `"The kitchen has a substance that feels good and satisfying," say the homeowners. "It has a quiet quality and way of working that is not obvious, so it lets us focus on living."`
        ]
      },
      media: [{orientation: 'square', src: link('understory/understory-8.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `It was critical that the Space Theory finish carried the motif of the dark interiors that recede as a backdrop to the views outside. Oak with a black finish was selected to blend into the woods and the woodwork, literally.`,
          `The physical characteristics of the material - texture, strength, longevity, and natural aging - are a fitting understory to the surrounding trees.`,
          {render: () => (
            <a className='text-item-link' href='/page/premium-quality' target='_blank'>
              WHAT DO WE MEAN BY QUALITY?
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('understory/understory-9.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The kitchen island is the literal and metaphorical center. A physical and visual runway of 25 feet, it leads the eyes to the view and draws people together.`
        ]
      },
      media: [{orientation: 'square', src: link('understory/understory-10.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Set between the 12-foot-wide glass pocketing doors that open to the outside, the island does it all. Performance kitchen, indoor dining room, outdoor dining space, game table, and office. "We can have games going at one end and dinner-making at the other. It fits the way we want to live."`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('understory/understory-11.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The relaxed order runs deep. The drawer interiors are designed as carefully as the rest of the system to create an intuitive and free flowing everyday experience. One that can easily be arranged to work for the homeowners. If they want the cutting board off the counter, it looks good enough to go on the wall. If they prefer it tucked away, it fits in a clean, modular way into the drawer.`
        ]
      },
      media: [{orientation: 'square', src: link('understory/understory-12.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `"Space Theory’s system solved our architectural challenges with the added benefit of the clients being able to customize the interiors to the nth degree", says Herrin.`
        ]
      },
      media: [{orientation: 'square', src: link('understory/understory-13.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Two buildings. Two unique spaces.  Tied together by the same ethos.`,
          `A second building was designed to accommodate guests, renters, and, most recently, act as a remote office. The brief for the modest studio also included durability and flexibility.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('understory/understory-14.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Space Theory adapted to the needs of this quick-change artist. The tailoring built into the system enabled one wall to do it all: kitchen, bar, utility, and office. With a hearth uniquely integrated at the center.`
        ]
      },
      media: [{orientation: 'square', src: link('understory/understory-15.jpg')}]
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('understory/understory-16.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `An integral part of the ecosystem of this home, its setting, and its inhabitants.`,
          `Some of our clients want a solution that is simpler and less expensive than Henrybuilt, but still a system, and still very high quality, so we founded Space Theory to provide that option. These are Space Theory kitchens.`,
          {render: () => (
            <a className='text-item-link' href='/' target='_blank'>
              SPACE THEORY: EXPLORE MORE
            </a>
          )}
        ]
      }
    },
  ]
};
