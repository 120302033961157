import {link} from './helpers';
import {gridCellGroupsFor} from './helpers';
import {React} from 'components';
import {Link} from 'react-router-dom';


// istanbul ignore file

export default {
  key: 'storage-components',
  title: 'Storage Components',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Space Theory offers a wide variety of base storage solutions. Easy access drawer storage, flexible storage with adjustable shelves, units designed to house appliances and sink units that include additional functional storage, are just a small selection  of the available options. Additionally, Space Theory provides a custom fit specific to your space with product sizing available to the ¼”.`
        ]
      },
      media: [{orientation: 'square', src: link('storage-and-panels/storage-and-panels-1.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Base Storage'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'basic-components', section: 'base-storage', groups: [
        {title: 'DRAWERS', cells: [
          {
            id: 881,
            title: '4 Drawer',
            key: '4-drawer',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 12, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
          {
            id: 880,
            title: '3 Drawer',
            key: '3-drawer',
            details: {
              media: [],
              notes: [`bottom drawer includes cross-rail dividers`],
              constraints: {
                width: {min: 12, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
          {
            id: 883,
            title: '2 Drawer',
            key: '2-drawer',
            details: {
              media: [],
              notes: [`drawers include cross-rail dividers`],
              constraints: {
                width: {min: 12, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
        ]},
        {title: 'LEAF DOORS', cells: [
          {
            id: 882,
            title: 'Single Leaf Door',
            key: 'single-leaf-door',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 12, max: 27, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [14.375, 18, 24.625]}
              }
            }
          },
          {
            id: 887,
            title: 'Single Leaf Door Internal Drawer',
            key: 'single-leaf-door-internal-drawer',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 15, max: 27, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
          {
            id: 888,
            title: 'Single Leaf Door Internal Drawers',
            key: 'single-leaf-door-internal-drawers',
            details: {
              media: [],
              notes: [`drawers include cross-rail dividers`],
              constraints: {
                width: {min: 15, max: 27, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
          {
            id: 21,
            title: 'Leaf Door 3 Interior Drawers',
            key: 'leaf-door-3-interior-drawers',
            details: {
              media: [],
              notes: [`bottom drawer includes cross-rail`],
              constraints: {
                width: {min: 15, max: 27, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          }
        ]},
        {title: 'DOUBLE LEAF DOOR', cells: [
          {
            id: 890,
            title: 'Double Leaf Door',
            key: 'double-leaf-door',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 27, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [14.375, 18, 24.625]}
              }
            }
          },
          {
            id: 894,
            title: 'Double Leaf Door Internal Drawer',
            key: 'double-leaf-door-internal-drawer',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 27, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
          {
            id: 888,
            title: 'Double Leaf Door Internal Drawers',
            key: 'double-leaf-door-internal-drawers',
            details: {
              media: [],
              notes: [`drawers include cross-rail dividers`],
              constraints: {
                width: {min: 27, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
          {
            id: 31,
            title: 'Leaf Door Double 3 Interior Drawers',
            key: 'leaf-door-double-3-interior-drawers',
            details: {
              media: [],
              notes: [`bottom drawer includes cross-rail dividers`],
              constraints: {
                width: {min: 27, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          }
        ]},
        {title: 'SPECIALTY STORAGE', cells: [
          {
            id: 901,
            title: 'Bookcase with adj. Shelves',
            key: 'bookcase-with-adj-shelves',
            details: {
              media: [],
              notes: [`center vertical partitionon units over 32” wide`, `veneer only`],
              constraints: {
                width: {min: 12, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [14.375, 18, 24.625]}
              }
            }
          },
          {
            id: 905,
            title: 'Corner with Pull Out Shelves',
            key: 'corner-with-pull-out-shelves',
            details: {
              media: [],
              notes: [`door width is either 18” for 33” wide unit, 21” for 36” wide unit`],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [33, 36]},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 691,
            title: 'Open Unit with Storage Tray',
            key: 'open-unit-with-storage-tray',
            details: {
              media: [],
              notes: [
                `includes 3 storage trays`,
                `veneer carcass only`,
                `trays available in standard powdercoat colors`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [14]},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          }
        ]},
        {title: 'SPECIALTY PREP', cells: [
          {
            id: 902,
            title: 'Oil Pull Out',
            key: 'oil-pull-out',
            details: {
              media: [],
              notes: [`left or right access available`],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [8, 12]},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 903,
            title: 'Tray with Leaf Door',
            key: 'tray-with-leaf-door',
            details: {
              media: [],
              notes: [`includes metal vertical tray partition`],
              constraints: {
                width: {min: 12, max: 15, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Base Clean'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'basic-components', section: 'base-clean', groups: [
        {title: 'SINK LEAF DOOR', cells: [
          {
            id: 45,
            title: 'Sink Unit With Leaf Door',
            key: 'sink-unit-with-leaf-door',
            details: {
              media: [],
              notes: [
                `max sink bowl = 4” less than unit width`,
                `interior drawer w. cross-rail dividers`
              ],
              constraints: {
                width: {min: 12, max: 27, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 46,
            title: 'Sink Unit With Double Leaf Door',
            key: 'sink-unit-with-double-leaf-door',
            details: {
              media: [],
              notes: [
                `max sink bowl = 4” less than unit width`,
                `interior drawer w. cross-rail dividers`
              ],
              constraints: {
                width: {min: 27, max: 48, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          }
        ]},
        {title: 'SINK LEAF DOOR with Internal Drawer', cells: [
          {
            id: 909,
            title: 'Sink Single Leaf with Internal Drawer',
            key: 'sink-single-leaf-with-internal-drawer',
            details: {
              media: [],
              notes: [
                `max sink bowl = 4” less than unit width`,
                `interior drawer w. cross-rail dividers`
              ],
              constraints: {
                width: {min: 12, max: 27, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 910,
            title: 'Sink Double Leaf with Internal Drawer',
            key: 'sink-double-leaf-with-internal-drawer',
            details: {
              media: [],
              notes: [
                `max sink bowl = 4” less than unit width`,
                `interior drawer w. cross-rail dividers`
              ],
              constraints: {
                width: {min: 27, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          }
        ]},
        {title: 'SINK FLIP DOWN', cells: [
          {
            id: 911,
            title: 'Sink Flip Down with Drawer',
            key: 'sink-flip-down-with-drawer',
            details: {
              media: [],
              notes: [
                `max sink bowl = 4” less than unit width`,
                `bottom drawer w. cross-rail dividers`
              ],
              constraints: {
                width: {min: 12, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 1171,
            title: 'Sink Farmhouse Style with drawer',
            key: 'sink-farmhouse-style-with-drawer',
            details: {
              media: [],
              notes: [
                `max sink bowl = 4” less than unit width`,
                `bottom drawer w. cross-rail dividers`,
                `top panel is fixed and to be cut-out onsite to accept sink`
              ],
              constraints: {
                width: {min: 15, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
        ]},
        {title: 'TRASH', cells: [
          {
            id: 701,
            title: 'Single Trash',
            key: 'single-trash',
            details: {
              media: [],
              notes: [
                `includes (1) 40L bin`,
                `$100 for additional internal storage drawer `
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [18]},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 702,
            title: 'Double Trash',
            key: 'double-trash',
            details: {
              media: [],
              notes: [
                `includes (2) 32L bins`,
                `$100 for additional internal storage drawer `
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [21]},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Base Cook'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'basic-components', section: 'base-cook', groups: [
        {title: 'COOKTOP', cells: [
          {
            id: 912,
            title: 'Cooktop Single Leaf With adj Shelves',
            key: 'cooktop-single-leaf-with-adj-shelves',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 18, max: 27, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 913,
            title: 'Cooktop Double Leaf With adj Shelves',
            key: 'cooktop-double-leaf-with-adj-shelves',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 27, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 915,
            title: 'Cooktop With External Drawers',
            key: 'cooktop-with-external-drawers',
            details: {
              media: [],
              notes: [`bottom drawer includes cross-rail dividers`],
              constraints: {
                width: {min: 18, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 918,
            title: 'Cooktop With Downdraft Range Hood',
            key: 'cooktop-with-downdraft-range-hood',
            details: {
              media: [],
              notes: [
                `to be used with a downdraft range hood / cooktop combination`,
                `shelves are not full depth`
              ],
              constraints: {
                width: {min: 32, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
        ]},
        {title: 'SMALL APPLIANCE', cells: [
          {
            id: 762,
            title: 'Microwave Universal',
            key: 'microwave-universal',
            details: {
              media: [],
              notes: [
                `top open unit veneer only`,
                `bottom drawer includes cross-rail dividers`
              ],
              constraints: {
                width: {min: 24, max: 42, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 755,
            title: 'Fitted Small Appliance With Drawer',
            key: 'fitted-small-appliance-with-drawer',
            details: {
              media: [],
              notes: [
                `to be used with specific models Miele, Bosch, and Wolf`,
                `bottom drawer includes cross-rail dividers`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [23.75, 27]},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
        ]},
        {title: 'OVEN', cells: [
          {
            id: 758,
            title: 'Fitted Oven',
            key: 'fitted-oven',
            details: {
              media: [],
              notes: [`to be used with specific models from Miele, Bosch, Gaggenau, and Wolf`],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [27, 30]},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 763,
            title: 'Generic Oven With Fixed Panel',
            key: 'generic-oven-with-fixed-panel',
            details: {
              media: [],
              notes: [`to be used with all models not on approved list`],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [27, 33, 39]},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 693,
            title: 'Cooktop Above With Appliance Below',
            key: 'cooktop-above-with-appliance-below',
            details: {
              media: [],
              notes: [`to be used with select combinations from Wolf`],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [27, 33, 39]},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
        ]}
      ]})
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Accessible storage comes in all sizes. Space Theory tall units allow you to maximize the vertical space in your kitchen. Tall storage options include interior drawer storage, adjustable shelves, accommodations for housing appliances, if needed, and finished open storage to name a few. The dimensional flexibility of the Space Theory system allows you to size your units to so you don’t waist even a half inch.`
        ]
      },
      media: [{orientation: 'square', src: link('storage-and-panels/storage-and-panels-2.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Tall Storage'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'basic-components', section: 'tall-storage', groups: [
        {title: 'TALL LEAF DOOR SINGLE', cells: [
          {
            id: 1170,
            title: 'Tall Pantry Pull Out',
            key: 'tall-pantry-pull-out',
            details: {
              media: [],
              notes: [
                `internal baskets are accessible from sides of door when open`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [12, 16, 18]},
                height: {min: 64.5, max: 92, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 954,
            title: 'Split Leaf Door With adj Shelves',
            key: 'split-leaf-door-with-adj-shelves',
            details: {
              media: [],
              notes: [`also available with full height door`],
              constraints: {
                width: {min: 12, max: 27, step: 0.25, fixed: []},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.375, 18, 24.625]}
              }
            }
          },
          {
            id: 957,
            title: 'Split Leaf Door 2 Tall Internal Drawers and Shelves',
            key: 'split-leaf-door-2-tall-internal-drawers-and-shelves',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 15, max: 27, step: 0.25, fixed: []},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
          {
            id: 1172,
            title: 'Tall Split Leaf Door 4 Internal Drawer',
            key: 'tall-split-leaf-door-4-internal-drawers',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 15, max: 27, step: 0.25, fixed: []},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
          {
            id: 958,
            title: 'Utility Single Leaf Door',
            key: 'utility-single-leaf-door',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 12, max: 27, step: 0.25, fixed: []},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.375, 18, 24.625]}
              }
            }
          },
        ]},
        {title: 'TALL LEAF DOOR DOUBLE', cells: [
          {
            id: 955,
            title: 'Double Split Leaf Door With adj Shelves',
            key: 'double-split-leaf-door-with-adj-shelves',
            details: {
              media: [],
              notes: [`also available with full height doors`],
              constraints: {
                width: {min: 27, max: 42, step: 0.25, fixed: []},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.375, 18, 24.625]}
              }
            }
          },
          {
            id: 956,
            title: 'Double Split Leaf Door 2 Tall Internal Drawers and Shelves',
            key: 'double-split-leaf-door-2-tall-internal-drawers-and-shelves',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 27, max: 42, step: 0.25, fixed: []},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
          {
            id: 1174,
            title: 'Tall Double Split Leaf Door 4 Internal Drawers',
            key: 'tall-double-split-leaf-door-4-internal-drawers',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 27, max: 42, step: 0.25, fixed: []},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
          {
            id: 959,
            title: 'Utility Double Leaf Door',
            key: 'utility-double-leaf-door',
            details: {
              media: [],
              notes: [],
              constraints: {
                width: {min: 27, max: 42, step: 0.25, fixed: []},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.375, 18, 24.625]}
              }
            }
          },
        ]},
        {title: 'OPEN UNITS', cells: [
          {
            id: 960,
            title: 'Tall Bookcase With adj Shelves',
            key: 'tall-bookcase-with-adj-shelves',
            details: {
              media: [],
              notes: [`veneer only`],
              constraints: {
                width: {min: 12, max: 32, step: 0.25, fixed: []},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.375, 18, 24.625]}
              }
            }
          },
          {
            id: 961,
            title: 'Tall Double Bookcase With adj Shelves',
            key: 'tall-double-bookcase-with-adj-shelves',
            details: {
              media: [],
              notes: [`veneer only`],
              constraints: {
                width: {min: 32, max: 42, step: 0.25, fixed: []},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.375, 18, 24.625]}
              }
            }
          },
          {
            id: 694,
            title: 'Tall Open Unit For Storage Tray',
            key: 'tall-open-unit-for-storage-tray',
            details: {
              media: [],
              notes: [
                `veneer only`,
                `includes 3 storage trays`,
                `veneer carcass only`,
                `trays available in standard powdercoat colors`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [14]},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
        ]},
        {title: 'TOP PANTRY', cells: [
          {
            id: 967,
            title: 'Top Pantry Single Leaf With adj Shelves',
            key: 'top-pantry-single-leaf-with-adj-shelves',
            details: {
              media: [],
              notes: [`can be stacked on any base unit`],
              constraints: {
                width: {min: 12, max: 27, step: 0.25, fixed: []},
                height: {min: 30, max: 65, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [12.875, 14.375, 18, 24.625]}
              }
            }
          },
          {
            id: 968,
            title: 'Top Pantry Double Leaf Door With adj Shelves',
            key: 'top-pantry-double-leaf-door-with-adj-shelves',
            details: {
              media: [],
              notes: [`can be stacked on any base unit`],
              constraints: {
                width: {min: 27, max: 42, step: 0.25, fixed: []},
                height: {min: 30, max: 65, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [12.875, 14.375, 18, 24.625]}
              }
            }
          },
        ]},
      ]})
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `When looking for storage over a work surface, we offer a variety of functional and aesthetic choices. All of these units are well suited for storing those items to which you need quick and easy access. These include units with doors and adjustable storage shelves, flip up doors, sliding glass, open shelving, floating shelves and more.`
        ]
      },
      media: [{orientation: 'square', src: link('storage-and-panels/storage-and-panels-3.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Wall Storage'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'basic-components', section: 'wall-storage', groups: [
        {title: 'LEAF DOOR', cells: [
          {
            id: 971,
            title: 'Leaf Door 1 Door With adj Shelves',
            key: 'leaf-door-1-door-with-adj-shelves',
            details: {
              media: [],
              notes: [
                `veneer carcass default`,
                `doors over 36” tall come with door stiffener`,
                `doors cannot be more than 5” wider than they are tall`
              ],
              constraints: {
                width: {min: 12, max: 30, step: 0.25, fixed: []},
                height: {min: 16, max: 42, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 1033,
            title: 'Leaf door 2 Door With adj Shelves',
            key: 'leaf-door-2-door-with-adj-shelves',
            details: {
              media: [],
              notes: [
                `veneer carcass default`,
                `doors over 36” tall come with door stiffener`,
                `doors cannot be more than 5” wider than they are tall`
              ],
              constraints: {
                width: {min: 24, max: 60, step: 0.25, fixed: []},
                height: {min: 16, max: 42, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 972,
            title: 'Leaf Door 3 Door With adj Shelves',
            key: 'leaf-door-3-door-with-adj-shelves',
            details: {
              media: [],
              notes: [
                `veneer carcass default`,
                `doors over 36” tall come with door stiffener`,
                `doors cannot be more than 5” wider than they are tall`
              ],
              constraints: {
                width: {min: 36, max: 90, step: 0.25, fixed: []},
                height: {min: 16, max: 42, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 973,
            title: 'Leaf Door 4 Door With adj Shelves',
            key: 'leaf-door-4-door-with-adj-shelves',
            details: {
              media: [],
              notes: [
                `veneer carcass default`,
                `doors over 36” tall come with door stiffener`,
                `doors cannot be more than 5” wider than they are tall`
              ],
              constraints: {
                width: {min: 48, max: 95, step: 0.25, fixed: []},
                height: {min: 16, max: 42, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          }
        ]},
        {title: 'FLIP-UP/LIFT-UP', cells: [
          {
            id: 975,
            title: 'Flip Up 1 Door With adj Shelves',
            key: 'flip-up-1-door-with-adj-shelves',
            details: {
              media: [],
              notes: [`veneer carcass default`],
              constraints: {
                width: {min: 15, max: 42, step: 0.25, fixed: []},
                height: {min: 16, max: 24, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 976,
            title: 'Flip Up 2 Door With adj Shelves',
            key: 'flip-up-2-door-with-adj-shelves',
            details: {
              media: [],
              notes: [`veneer carcass default`],
              constraints: {
                width: {min: 42, max: 84, step: 0.25, fixed: []},
                height: {min: 16, max: 24, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
        ]},
        {title: 'ABOVE COUNTER CORNER COUNTERTOP TRANSITION', cells: [
          {
            id: 991,
            title: 'Above Counter Corner Counter Transition Leaf Door',
            key: 'above-counter-corner-counter-transition-leaf-door',
            details: {
              media: [],
              notes: [
                `w = 17 1/4” (used when 18”deep units perpendicular)`,
                `w = 24” (used when 24 5/8” deep units perpendicular)`,
                `veneer carcass default, liner carcass optional`,
                `If liner carcass, depth is 24 5/8”`,
                `door cannot be more than 5” wider than it is tall`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [17.25, 24]},
                height: {min: 22, max: 65, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875, 18, 25.125]}
              }
            }
          },
          {
            id: 992,
            title: 'Above Counter Corner Counter Transition Open',
            key: 'above-counter-corner-counter-transition-open',
            details: {
              media: [],
              notes: [
                `w = 17 1/4” (used when 18”deep units perpendicular)`,
                `w = 24” (used when 24 5/8” deep units perpendicular)`,
                `veneer carcass only`,
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [17.25, 24]},
                height: {min: 22, max: 65, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875, 18, 25.125]}
              }
            }
          },
          {
            id: 990,
            title: 'Above Counter Corner Counter Transition Pocketing Flip',
            key: 'above-counter-corner-counter-transition-pocketing-flip',
            details: {
              media: [],
              notes: [
                `d = 18” (h: 13 1/2” - 20”)`,
                `d = 24 5/8” (h: 20” - 24”)`,
                `veneer carcass only`,
                `height range is relative unit depth`
              ],
              constraints: {
                width: {min: 20, max: 42, step: 0.25, fixed: []},
                height: {min: 13.5, max: 24, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [18, 24.625]}
              }
            }
          },
        ]},
        {title: 'SPECIALTY', cells: [
          {
            id: 985,
            title: 'Bookcase With adj Shelves',
            key: 'bookcase-with-adj-shelves',
            details: {
              media: [],
              notes: [`veneer carcass only`],
              constraints: {
                width: {min: 12, max: 32, step: 0.25, fixed: []},
                height: {min: 16, max: 42, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 986,
            title: 'Bookcase Double With adj Shelves',
            key: 'bookcase-double-with-adj-shelves',
            details: {
              media: [],
              notes: [`veneer carcass only`],
              constraints: {
                width: {min: 32, max: 64, step: 0.25, fixed: []},
                height: {min: 16, max: 42, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 989,
            title: 'Leaf Door Corner',
            key: 'leaf-door-corner',
            details: {
              media: [],
              notes: [
                `veneer carcass only`,
                `blind portion of unit is always 14”`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [12, 15, 18]},
                height: {min: 16, max: 42, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 977,
            title: 'Sliding Glass',
            key: 'sliding-glass',
            details: {
              media: [],
              notes: [
                `h = 16” (w: 36“ - 84”)`,
                `h = 22” (w: 60” - 84”)`,
                `veneer carcass only`,
                `aluminum slider pull only`,
                `diffused glass`
              ],
              constraints: {
                width: {min: 36, max: 84, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [16, 22]},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
        ]},
        {title: 'STORAGE TRAY', cells: [
          {
            id: 695,
            title: 'Vertical Single Leaf With Storage Tray',
            key: 'vertical-single-leaf-with-storage-tray',
            details: {
              media: [],
              notes: [
                `veneer carcass only`,
                `includes 1 storage tray`,
                `open bay is fixed in height at 13 1/4” high`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [24]},
                height: {min: 0, max: 0, step: 0, fixed: [30, 36, 42]},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 696,
            title: 'Vertical Double Leaf With Storage Tray',
            key: 'vertical-double-leaf-with-storage-tray',
            details: {
              media: [],
              notes: [
                `veneer carcass only`,
                `includes 2 storage tray`,
                `open bay is fixed in height at 13 1/4” high`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [48]},
                height: {min: 0, max: 0, step: 0, fixed: [30, 36, 42]},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 697,
            title: 'Horizontal Double Leaf With Storage Tray',
            key: 'horizontal-double-leaf-with-storage-tray',
            details: {
              media: [],
              notes: [
                `h = 16” (w: 48“ - 66”)`,
                `h = 22” (w: 48” - 78”)`,
                `h = 24” (w: 48” - 82”)`,
                `veneer carcass only`,
                `includes 2 storage tray`,
                `open bay is fixed in width at 24” wide`,
                `open bay can be on left or right`
              ],
              constraints: {
                width: {min: 48, max: 82, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [16, 22, 24]},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
        ]},
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Wall Cook'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'basic-components', section: 'wall-cook', groups: [
        {title: 'SPECIALITY', cells: [
          {
            id: 757,
            title: 'Hood Powerpack Leaf Door Double',
            key: 'hood-powerpack-leaf-door-double',
            details: {
              media: [],
              notes: [
                `aluminum liner applied to bottom of box and door fronts`,
                `installer to cut out for hood onsite`,
                `no touch latch available`,
                `22” min height for ducting through box back`,
                `only available for use with select hoods from Futuro and Zephyr`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [24, 32, 42]},
                height: {min: 22, max: 42, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 1178,
            title: 'Hood Slide out Leaf Door Double',
            key: 'hood-slideout-leaf-door-double',
            details: {
              media: [],
              notes: [
                `no bottom of box to accept hood unit`,
                `for use only with select Miele hoods`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [23.75, 30, 35.5]},
                height: {min: 17, max: 42, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 756,
            title: 'Hood Powerpack Lift',
            key: 'hood-powerpack-lift',
            details: {
              media: [],
              notes: [
                `aluminum liner applied to bottom of box and door fronts`,
                `installer to cut out for hood onsite`,
                `no touch latch available`,
                `22” min height for ducting through box back`,
                `only available for use with select hoods from Futuro and Zephyr`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [24, 32, 42]},
                height: {min: 18, max: 24, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 1177,
            title: 'Hood Slide out Flip Up',
            key: 'hood-slideout-flip-up',
            details: {
              media: [],
              notes: [
                `no bottom of box to accept hood unit`,
                `for use only with select Miele hoods`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [23.75, 30, 35.5]},
                height: {min: 17, max: 24, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
        ]},
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Wall Shelves'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'basic-components', section: 'panel-storage', groups: [
        {title: 'FLOATING SHELVES', cells: [
          {
            id: 980,
            title: 'Floating Shelf Wood',
            key: 'floating-shelf-wood',
            details: {
              media: [],
              notes: [
                `can be fit with lights`,
                `mounting hardware included`,
                `only available in veneer options`,
                `shelves must have continous 3” solid wood blocking in wall behind`
              ],
              constraints: {
                width: {min: 12, max: 95, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [1.5, 2.5]},
                depth: {min: 0, max: 0, step: 0, fixed: [5, 8, 12]}
              }
            }
          },
          {
            ids: [1015, 1016, 1014],
            title: 'Floating Shelf Steel',
            key: 'floating-shelf-steel',
            details: {
              media: [],
              notes: [
                `3 profile options exist: flat, turned down front edge, and angled front edge`,
                `exposed fasteners`,
                `shelves must have continous 3” solid wood blocking in wall behind`
              ],
              constraints: {
                width: {min: 12, max: 120, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [1.5, 2.5]},
                depth: {min: 0, max: 0, step: 0, fixed: [5, 8, 12]}
              }
            }
          },
        ]},
        {title: 'OPEN SHELVES WITH BACKER', cells: [
          {
            id: 678,
            title: 'Shelfbank Aluminum Slider',
            key: 'shelfbank-aluminum-slider',
            details: {
              media: [],
              notes: [
                `can have between 2 and 3 shelves`,
                `spacing is equal between shelves`
              ],
              constraints: {
                width: {min: 12, max: 95, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [10, 30]},
                depth: {min: 0, max: 0, step: 0, fixed: [8.5, 13.25, 14.875]}
              }
            }
          },
          {
            id: 978,
            title: 'Shelfbank Open',
            key: 'shelfbank-open',
            details: {
              media: [],
              notes: [
                `can have between 1 and 4 shelves`,
                `spacing is equal between shelves`,
                `max back panel size is 47 x 95 regardless of orientation`
              ],
              constraints: {
                width: {min: 12, max: 95, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [10, 95]},
                depth: {min: 0, max: 0, step: 0, fixed: [8.5, 13.25, 14.875]}
              }
            }
          },
        ]},
      ]})
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Maximize your storage options with our Opencase Wall Panel system. Based on a grid, it allows you to change functions in the moment. From wine storage, to pantry, to serving zone simply change the components as your needs change.`
        ]
      },
      media: [{orientation: 'square', src: link('storage-and-panels/storage-and-panels-4.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Do you want the clean lines of a totally integrated kitchen where your refrigerator is hidden? We work with numerous top appliance brands to provide size specific panels to cover integrated appliances. This includes tall refrigerators, under counter refrigerators, and dishwashers.`
        ]
      },
      media: [{orientation: 'square', src: link('storage-and-panels/storage-and-panels-5.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Tall Cook'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'basic-components', section: 'tall-cook', groups: [
        {title: 'APPLIANCE', cells: [
          {
            id: 759,
            title: 'Tall Fitted Appliance',
            key: 'tall-fitted-appliance',
            details: {
              media: [],
              notes: [
                `to be used with specific models from Miele, Bosch, Gaggenau, and Wolf`,
                `bottom drawers include cross-rail dividers`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [23.75, 27, 30]},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 764,
            title: 'Tall Universal Appliance',
            key: 'tall-universal-appliance',
            details: {
              media: [],
              notes: [
                `bottom drawer include cross-rail dividers`,
                `to be used with all models not on approved list`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [27, 33, 38]},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 760,
            title: 'Tall Fitted Double Appliance',
            key: 'tall-fitted-double-appliance',
            details: {
              media: [],
              notes: [
                `to be used with specific models from Miele, Bosch, Gaggenau, and Wolf`,
                `bottom drawer includes cross-rail dividers`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [23.75, 27, 30]},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 765,
            title: 'Tall Universal Double Appliance',
            key: 'tall-universal-double-appliance',
            details: {
              media: [],
              notes: [
                `bottom drawer includes cross-rail dividers`,
                `to be used with all models not on approved list`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [27, 33, 39]},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
        ]},
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Panel Appliance'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'basic-components', section: 'panel-appliance', groups: [
        {title: 'BASE PANELS', cells: [
          {
            id: 754,
            title: 'Dishwasher Overlay Panel',
            key: 'dishwasher-overlay-panel',
            details: {
              media: [],
              notes: [
                `veneer or laminate unless otherwise noted`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `veneer grain is always vertical`,
                `only available for select dishwasher models from Fisher & Paykel, Bosch, and Miele`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [23.5]},
                height: {min: 0, max: 0, step: 0, fixed: [30.375]},
                depth: {min: 0, max: 0, step: 0, fixed: [0.75]}
              }
            }
          },
          {
            id: 753,
            title: 'Undercounter Appliance Panel',
            key: 'undercounter-appliance-panel',
            details: {
              media: [],
              notes: [
                `veneer or laminate unless otherwise noted`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `veneer grain is always vertical`,
                `only available for select freezer/refrigerator models from Sub-Zero`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [24, 27, 30, 36]},
                height: {min: 0, max: 0, step: 0, fixed: [30.375]},
                depth: {min: 0, max: 0, step: 0, fixed: [0.75]}
              }
            }
          },
          {
            id: 752,
            title: 'Undercounter Refrigerator Drawer Panels',
            key: 'undercounter-refrigerator-drawer-panels',
            details: {
              media: [],
              notes: [
                `veneer or laminate unless otherwise noted`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `veneer grain is always vertical`,
                `only available for select undercounter wine/beverage centers from U-Line refrigerators`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [23.5]},
                height: {min: 0, max: 0, step: 0, fixed: [30.375]},
                depth: {min: 0, max: 0, step: 0, fixed: [0.75]}
              }
            }
          },
        ]},
        {title: 'TALL PANELS', cells: [
          {
            id: 751,
            title: 'Tall Column Integrated Refrigerator Panel',
            key: 'tall-column-integrated-refrigerator-panel',
            details: {
              media: [],
              notes: [
                `veneer or laminate unless otherwise noted`,
                `veneer grain is vertical`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `to be used as door panel for appliances that accept a custom panel from Fisher & Paykel, Sub-Zero, Bosch, and Miele`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [24, 30, 36]},
                height: {min: 0, max: 0, step: 0, fixed: [80]},
                depth: {min: 0, max: 0, step: 0, fixed: [0.75]}
              }
            }
          },
          {
            ids: [749, 787, 750],
            title: 'Tall Integrated Refrigerator With Drawer Panels',
            key: 'tall-integrated-refrigerator-with-drawer-panels',
            details: {
              media: [],
              notes: [
                `veneer or laminate unless otherwise noted`,
                `veneer grain is vertical`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `banded/finished all edges`,
                `only available for select refrigerators from Fisher & Paykel, and Sub-Zero`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [30, 36]},
                height: {min: 0, max: 0, step: 0, fixed: [80]},
                depth: {min: 0, max: 0, step: 0, fixed: [0.75]}
              }
            }
          },
          {
            id: 698,
            title: 'Tall Refrigerator Panel Wrap',
            key: 'tall-refrigerator-panel-wrap',
            details: {
              media: [],
              notes: [
                `veneer or laminate unless otherwise noted`,
                `veneer grain is vertical`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `veneer only`,
                `banded/finished and oversized to be precise fitted onsite`,
                `to be used to surround lined units and refrigerators for a finished look`
              ],
              constraints: {
                width: {min: 24, max: 38, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [80]},
                depth: {min: 0, max: 0, step: 0, fixed: [0.75]}
              }
            }
          },
        ]},
      ]})
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Finish off your base or tall run with our finish panels. These help to capture our product to create a tailored, finished look to your kitchen. We provide these in multiple thicknesses depending on the aesthetic you would like to create. The panels can be used in conditions such as end panels, cap panels, island back panels, appliance wraps and more.`
        ]
      },
      media: [{orientation: 'square', src: link('storage-and-panels/storage-and-panels-6.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Panel Finish'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'basic-components', section: 'panel-finish', groups: [
        {title: 'BASE PANELS', cells: [
          {
            ids: [791, 792, 790],
            title: 'Base End Panel',
            key: 'base-end-panel',
            details: {
              media: [],
              notes: [
                `veneer, solid surface or laminate as noted`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `veneer grain is always vertical`,
                `veneer available in 1/2” or 3/4”`,
                `solid surface is only available in 1/2”`,
                `banded /finished on two edges - top and front`,
                `to be used as a finished panel at end of run to be capture base units`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [15, 20, 27]},
                height: {min: 0, max: 0, step: 0, fixed: [36.5]},
                depth: {min: 0, max: 0, step: 0, fixed: [0.5, 0.75]}
              }
            }
          },
          {
            ids: [793, 794, 795],
            title: 'Base Island End Panel',
            key: 'base-island-end-panel',
            details: {
              media: [],
              notes: [
                `veneer, solid surface or laminate as noted`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `veneer grain is always vertical`,
                `veneer available in 1/2” or 3/4”`,
                `veneer available in 1/2” or 3/4”`,
                `solid surface is only available in 1/2”`,
                `banded/finished on three edges - top and sides`,
                `to be used for panels at end of islands`
              ],
              constraints: {
                width: {min: 30, max: 47, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [36.5]},
                depth: {min: 0, max: 0, step: 0, fixed: [0.5, 0.75]}
              }
            }
          },
          {
            id: 728,
            title: 'Base Island Back Panel',
            key: 'base-island-back-panel',
            details: {
              media: [],
              notes: [
                `veneer, solid surface or laminate as noted`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `veneer grain is always vertical`,
                `veneer or laminate only`,
                `banded and/or finished on three edges - top and sides`,
                `to be used at back of islands`
              ],
              constraints: {
                width: {min: 30, max: 47, step: 0.25, fixed: []},
                height: {min: 0, max: 0, step: 0, fixed: [30.375]},
                depth: {min: 0, max: 0, step: 0, fixed: [0.75]}
              }
            }
          },
        ]},
        {title: 'TALL PANELS', cells: [
          {
            id: 1092,
            title: 'Tall End Panel',
            key: 'tall-end-panel',
            details: {
              media: [],
              notes: [
                `veneer or laminate unless otherwise noted`,
                `veneer grain is vertical`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `veneer available in 1/2” or 3/4”`,
                `solid surface is only available in 1/2”`,
                `banded and finished on two edges - top and front`,
                `to be used as a finish panel at end of run to capture tall`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [15, 20, 27]},
                height: {min: 0, max: 0, step: 0, fixed: [74, 82, 86, 95.5]},
                depth: {min: 0, max: 0, step: 0, fixed: [0.5, 0.75]}
              }
            }
          },
          {
            id: 731,
            title: 'Cap Panel Veneer',
            key: 'cap-panel-veneer',
            details: {
              media: [],
              notes: [
                `veneer or laminate unless otherwise noted`,
                `veneer grain is vertical`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `banded/finished all exposed edges`,
                `to be used to capture top of exposed units / openings`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [15, 20, 27]},
                height: {min: 20, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [0.5, 0.75, 2.25]}
              }
            }
          },
          {
            id: 1093,
            title: 'Cap Panel Solid Surface',
            key: 'cap-panel-solid-surface',
            details: {
              media: [],
              notes: [
                `veneer or laminate unless otherwise noted`,
                `veneer grain is vertical`,
                `panels are oversized where panels meet with architecture for precise fitting`,
                `banded/finished all exposed edges`,
                `to be used to capture top of exposed units / openings`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [15, 20, 27]},
                height: {min: 20, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [0.5]}
              }
            }
          },
        ]},
      ]})
    },
    // {
    //   layout: 'text',
    //   heightAuto: true,
    //   text: {
    //     items: [
    //       {render: () => (
    //         <Link className='text-item-link next-page' to={'/page/flexible-organization'} style={{position: 'absolute', bottom:'10em'}}>
    //           Flexible Organization ⟶
    //         </Link>
    //       )}
    //     ]
    //   }
    // }
  ]
}
