import {React} from 'components';
import {link} from './helpers';
import {gridCellGroupsFor} from './helpers';

// istanbul ignore file

export default {
  // doNotIndex: true,
  key: 'new-drawer-components',
  title: 'New Drawer Components',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'heading', text: `New Drawer Components`},
          {className: 'smaller-text-space-after', text: `Space Theory is dedicated to bringing a new experience of flexibility and adaptability to the kitchen. That calls for innovation.`},
          {className: 'smaller-text-space-after', text: `Our new line of modular drawer components is interchangeable and designed to integrate into the Space Theory system. Components lift easily from drawer to table or counter and can be suspended on our flexible wall storage system, Opencase, so you can put tools and food items right where you want them. It's about maximizing ease and minimizing clutter. The components are flexible, stackable, and handcrafted using domestically sourced hardwoods and durable, dishwasher-safe stainless steel.`}
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('coming-soon-oct-2023/1.jpg')
      }]
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('coming-soon-oct-2023/2.mp4#t=1')}]
    },
    // {
    //   layout: 'media',
    //   media: [{
    //     orientation: 'horizontal',
    //     src: link('coming-soon-oct-2023/2.jpg')
    //   }]
    // },
    // {
    //   layout: 'media',
    //   media: [{
    //     orientation: 'horizontal',
    //     src: link('coming-soon-oct-2023/3.jpg')
    //   }]
    // },
    // {
    //   layout: 'textLeftGridRight',
    //   textStyle: 'standard',
    //   heightAuto: true,
    //   gridProps: {cellSizeMode: 'small'},
    //   text: {items: [
    //     {className: 'smaller-text serif', text: `A new selection of stained walnut finishes provide an opportunity for greater aesthetic range and adaptation to your design objectives.`},
    //     {className: 'smaller-text serif', text: `More options. Better fit. Optimal appearance.`},
    //   ]},
    //   cellGroups: gridCellGroupsFor({page: 'coming-soon-oct-2023', section: 'materials', groups: [
    //     {cells: [
    //       {title: 'Platinum Walnut', key: 'platinum-walnut', details: {}},
    //       {title: 'Pebble Gray Walnut', key: 'pebble-gray-walnut', details: {}},
    //       {title: 'Shadow Gray Walnut', key: 'shadow-gray-walnut', details: {}},
    //       {title: 'Obsidian Green Walnut', key: 'obsidian-green-walnut', details: {}},
    //       {title: 'Storm Blue Walnut', key: 'storm-blue-walnut', details: {}},
    //       {title: 'Ocean Blue Walnut', key: 'ocean-blue-walnut', details: {}},
    //       {title: 'Midnight Blue Walnut', key: 'midnight-blue-walnut', details: {}},
    //       {title: 'Ochre Red Walnut', key: 'ochre-red-walnut', details: {}}
    //     ]}
    //   ]})
    // },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text-space-after', text: `In the year to come, Space Theory will continue innovating to deliver a high-quality, interrelated system of products, a transformative experience in the home, and a simple process in achieving it.`},
          {render: ({showInquiryForm}) => (
            <div>
              <span className='text-item-smaller-text-space-after' style={{color: 'rgb(119, 152, 173)', cursor: 'pointer'}} onClick={() => showInquiryForm({contactReason: 'project'})}>Contact us to find out more about Space Theory.</span>
            </div>
          )}
        ]
      }
    },
  ]
};
