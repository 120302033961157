//import {React} from 'components';
import {link/*, colors*/} from './helpers';

// istanbul ignore file

export default {
  key: 'premium-quality',
  title: 'Premium Quality',
  sections: [
    {
      layout: 'textLeftMediaRight',
      media: [{src: link('quality/quality-1.jpg')}],
      text: {
        items: [
          {className: 'sub-heading', text: 'Premium Quality'},
          `The longer things last, the better. Designing and building a ‘lifelong’ product is our goal, which is good for your investment, and mindful of the planet.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      media: [{src: link('quality/quality-2.jpg')}],
      text: {
        items: [
          `Our product line consists of a combination of materials and components that includes cabinets, worksurfaces, interior drawer and other interior components, and a system of hardware and accessories for the Space Theory Opencase system, which are made from a variety of materials. We manufacture to the same high-quality standard across all of them.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      media: [{src: link('quality/quality-3.jpg')}],
      text: {
        items: [
          {className: 'sub-heading', text: `Starting with the cabinets`},
          `Cabinets are made from ¾” veneer core domestic plywood, constructed by hand, doweled, glued and screwed. The result is a structural cabinet that is very strong, and easy to install. Even the backs are ¾” plywood. Base and tall units come with levelers installed and connector bolt fittings for joining them together, which helps assure a quality installation.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      media: [{src: link('quality/quality-4.jpg')}],
      text: {
        items: [
          `All drawers use the highest quality soft close hardware, with powdercoated steel sides and 5/8” thick lined plywood bottoms – which makes them strong and durable.`,
          `All cabinets are delivered completely assembled, with drawers in, ready to install.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      media: [{src: link('quality/quality-5.jpg')}],
      text: {
        items: [
          {className: 'sub-heading', text: `Veneer`},
          `The veneer we use is carefully sourced from domestic forests. It is carefully selected by batch by experts within our production team. It is then laid up according to specific guidelines designed to yield a unique, natural result and maximum use of the tree. Veneer on a plywood core is much more stable than solid wood when creating a flat panel and is a much more sustainable than solid wood.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      media: [{src: link('quality/quality-6.jpg')}],
      text: {
        items: [
          {className: 'sub-heading', text: `Wood Finish`},
          `Our finish is 2k Polyurethane, which is a finish primarily used in Europe and in other industries like aviation. It is extremely heat and chemical resistant, while also allowing a natural feel – without filling the grain of the wood.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      media: [{src: link('quality/quality-7.jpg')}],
      text: {
        items: [
          {className: 'sub-heading', text: `Opencase`},
          `The wall panels used in the Opencase system are made from the same ¾” veneer core plywood as our cabinets. The threaded fittings are machined from solid Aluminum to a high tolerance, to provide strength and longterm wear. Accessories are made from a variety of materials including Aluminum, solid wood and acrylic. Our design approach is based on doing only what is possible with high quality materials, so they wear well with hard use. Metal fittings that use color are powdercoated, which is a very strong electromagnetic, heat cured coating that is very durable.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      media: [{src: link('quality/quality-8.jpg')}],
      text: {
        items: [
          {className: 'sub-heading', text: `Interior Components`},
          `Interior components are made from a combination of stainless steel and solid wood. The stainless steel can be placed in the dishwasher and should, for all practical purposes, last forever. The wood pieces need to be oiled occasionally to stay fresh but also are made to last a very long time.`
        ]
      }
    },
    {
      layout: 'text',
      text: {
        items: [
          {className: 'sub-heading', text: 'Relationships'},
          `If things go wrong, or change, we’ll be here. The company that launched us, Henrybuilt, has been around for almost 20 years, with many repeat clients and longstanding relationships, and we’re proud to have that DNA.`
        ]
      }
    }
  ]
};
