import {React} from 'components';
import {link, colors} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'warranty-care',
  title: 'Warranty & Care',
  sections: [
    {
      layout: 'accordionWithMedia',
      heightAuto: true,
      defaultMedia: [{orientation: 'square', src: link('warranty-care/warranty-care-1.jpg')}],
      accordionTitle: 'Warranty & Care',
      accordionSections: [
        {
          title: 'Warranty',
          text: {
            items: [
              {render: () => (
                <div>
                  <b>Do you have a warranty?</b><br/>
                  Space Theory has a 10-year warranty against defects in materials and construction.
                </div>)
              },
            ]
          }
        },
        {
          title: 'Care',
          text: {
            items: [
              {render: () => (
                <div>
                  <b>Are your products easy to clean?</b><br/>
                  Yes. Our high performance finish is easily cleanable with either water or standard mild all-purpose cleaners.
                  <br/><br/>
                  For more specific information for care for the Space Theory product, please use our guide linked below.
                  <br/><br/>
                  <a className='accordion-text-item-link blue' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-care-and-cleaning.pdf' target='_blank'>Space Theory Care and Cleaning Guide</a>
                </div>)
              }
            ]
          }
        },
      ]
    }
  ]
}
