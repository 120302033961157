import {_, React} from 'components';
import {Radio as SemanticRadio} from 'semantic-ui-react';
import {InputWithOptions} from 'lib/form/inputs/input/input.js';
import Group from '../../group/group.js';

import './radio-input.scss';

class RadioInput extends InputWithOptions {
  render() {
    return (
      <Group className={`form-input radio-input${this.props.className ? ` ${this.props.className}` : ''}`}>
        {this.props.label ? <label className='label'>{this.props.label}</label> : null}
        {_.map(this.props.options, ({value, title, color}) => (
          <SemanticRadio
            label={title}
            key={value}
            value={value}
            style={{backgroundColor: color && value === this.props.value ? color : ''}}
            {..._.pick(this.props, ['name', 'disabled'])}
            checked={value === this.props.value}
            onChange={() => this.handleChange({value})}/>
        ))}
      </Group>
    );
  }
}

export default RadioInput;
