import {React} from 'components';
import {link, colors} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'appliances-fixtures',
  title: 'Appliances & Fixtures',
  sections: [
    {
      layout: 'accordionWithMedia',
      heightAuto: true,
      defaultMedia: [{orientation: 'square', src: link('appliances-fixtures/appliances-fixtures-1.jpg')}],
      accordionTitle: 'Appliances & Fixtures',
      accordionSections: [
         {
          title: 'Appliances',
          text: {
            items: [
              {render: () => (
                <div>
                  <b>Do you sell appliances?</b><br/>
                  We do not sell appliances at this time however, we do provide appliance recommendations and check <a className='accordion-text-item-link blue' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-appliances-that-integrate.pdf' target='_blank'>appliance compatability</a> to achieve a beautifully integrated kitchen.
                </div>)
              },
              {render: () => (
                <div>
                  <b>Do you provide panels for integrated appliances?</b><br/>
                  Yes. Integrated appliances can be paneled in the Space Theory front material options. <Link className='accordion-text-item-link blue' to={'/page/materials'}>Here is a link</Link> to our materials.
                </div>)
              },
              {render: () => (
                <div>
                  <b>Do you have a list of appliances that you work with?</b><br/>
                  Here is a list of <a className='accordion-text-item-link blue' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-appliances-that-integrate.pdf' target='_blank'>integrated appliances</a> that are designed to fit seamlessly with our product. If your appliance choice is not on the list, we are most likely still able to work with it. As we refine your design together, our design engineers will work with you on the best solution.
                </div>)
              },
            ]
          }
         },
         {
          title: 'Sink & Faucets',
          text: {
            items: [
              {render: () => (
                <div>
                  <b>Do you provide sinks or faucets?</b><br/>
                  Yes. We offer extremely high quality, Prochef single basin stainless steel undermount sinks by Julien in depths of 8" or 10". Sink cutouts and integrated drainboards can be provided with our solid surface countertops.
                </div>)
              },
            ]
          }
         },
         {
          title: 'Lighting',
          text: {
            items: [
              {render: () => (
                <div>
                  <b>What are your under-cabinet lighting options and are they pre-installed?</b><br/>
                  Under-cabinet lighting is an option for all wall units including our wood floating shelves. You have the choice of <a className='accordion-text-item-link blue' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-undercabinet-lighting.pdf' target='_blank'>recessed LED linear or puck (round) lighting.</a> It comes fully installed.
                </div>)
              },
            ]
          },
         },
         {
          title: 'Electrical',
          text: {
            items: [
              {render: () => (
                <div>
                  <b>What is the best way to deal with electrical outlets?</b><br/>
                  Your electrician is the best resource for placement and installation of electrical outlets. They will assure that codes are met in your particular location. Our product is designed for your electrician to place, cut and install outlets on site.                </div>)
              },
            ]
          },
         }
       ],
      //  nextPage: 'process',
      //  nextPageTitle: 'Process',
    }
  ]
}
