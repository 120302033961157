import {_, Component, React, className} from 'components'; //eslint-disable-line
import './slideshow.scss';
import PropTypes from 'prop-types';

// @FB
// slideshow component creates slideshow template
// Related: Grid

class Slideshow extends Component {
  static propTypes = {
    media: PropTypes.array,
  }

  static defaultProps = {
    media: [],
    allowFullscreen: true,
    enableKeyboardControls: true,
    controlsPlacement: 'left',
    showControlsBackground: true,
    touchStartPosition: null,
    context: 'default'
  }

  state = {
    index: 0,
    isFullscreen: false
  };

  constructor(props) {
    super(props);

    this.bind(['toggleFullscreen', 'prev', 'next', 'handleDotClick', 'handleKeyDown', 'handleTouchStart', 'handleTouchEnd']);

    this.lastTouchEventRef = React.createRef();

    if (this.props.enableKeyboardControls) this.addDomEventListener({to: document, on: 'keydown', handler: this.handleKeyDown});
  }

  toggleFullscreen() {
    this.setState({isFullscreen: !this.state.isFullscreen});
  }

  prev() {
    var {media} = this.props, {index} = this.state;

    this.setState({index: index === 0 ? media.length - 1 : index - 1});
  }

  next() {
    var {media} = this.props, {index} = this.state;

    this.setState({index: index === media.length - 1 ? 0 : index + 1});
  }

  handleDotClick(event) {
    this.setState({index: parseInt(event.target.dataset.index)});
  }

  handleTouchStart(event) {
    this.setState({touchStartPosition: event.changedTouches[0]});
  }

  handleTouchEnd(event) {
    var {touchStartPosition} = this.state;

    if (touchStartPosition) {
      var xDelta = event.changedTouches[0].pageX - touchStartPosition.pageX;
      var yDelta = event.changedTouches[0].pageY - touchStartPosition.pageY;

      if (Math.abs(xDelta) > Math.abs(yDelta) * 2) {
        if (xDelta < 0) { //right to left swipe
          this.next();
        }
        else { //left to right swipe
          this.prev();
        }
      }

      this.setState({touchStartPosition: null});
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 38 || event.keyCode === 37) { //event.keyCode === 38) {
      this.prev();

      event.preventDefault();
    }
    else if (event.keyCode === 40|| event.keyCode === 39) {
      this.next();

      event.preventDefault();
    }
    else if (event.keyCode === 27 && this.state.isFullscreen) {
      this.setState({isFullscreen: false});
    }
  }

  controls() {
    return (
      <div {...className(['slideshow-controls', `position-${this.props.controlsPlacement}`, this.state.showControlsBackground && 'show-background'])}>
        <div className='slideshow-prev slideshow-control-button' onClick={this.prev}>
          <div className='arrow'></div>
        </div>
        <div className='slideshow-dots'>
          {_.map(this.props.media, (item, itemIndex) => (
            <div key={itemIndex} {...className(['slideshow-dot-container', this.state.index === itemIndex && 'active'])} data-index={itemIndex} onClick={this.handleDotClick}>
              <div key={itemIndex} {...className(['slideshow-dot', this.state.index === itemIndex && 'active'])} data-index={itemIndex} onClick={this.handleDotClick}></div>
            </div>
          ))}
        </div>
        <div className='slideshow-next slideshow-control-button' onClick={this.next}>
          <div className='arrow'></div>
        </div>
        {this.props.allowFullscreen && (
          <div className='slideshow-toggle-fullscreen slideshow-control-button' onClick={this.toggleFullscreen}>
            <img src={`https://s3.amazonaws.com/spacetheory-public/website/icons/white/${this.state.isFullscreen ? 'exit' : 'enter'}-fullscreen.png`} alt='Toggle fullscreen'/>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div
        {...className(['slideshow', this.state.isFullscreen && 'is-fullscreen'])}
        onKeyDown={ this.props.enableKeyboardControls ? this.handleKeyDown : null }
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
      >
        {this.props.context === 'default' && (
          <div className='slideshow-content'>
            <div className='slideshow-media'>
              {_.map(this.props.media, (medium, m) => (
                <div
                  key={m}
                  {...className(['slideshow-medium', `size-mode-${medium.sizeMode || "cover"}`, this.state.index === m && 'active'])}
                >
                  <img src={medium.src} alt={medium.title} loading='lazy'/>
                </div>
              ))}
            </div>
            {this.controls()}
          </div>
        )}
        {this.props.context === 'landing-page' && (
          <div className='slideshow-content'>
              <div className='slideshow-slides'>
              {_.map(this.props.media, (slide, s) => (
                <div
                  key={s}
                  {...className(['slideshow-slide', this.state.index === s && 'active'])}
                >
                  {slide.text.render()}
                  <div className='media-container'>
                    <div className='image-container'>
                      {slide.medium.render()}
                    </div>
                    {this.controls()}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Slideshow;
