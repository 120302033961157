import {React} from 'components';
import {link, colors} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'careers',
  title: 'Careers',
  sections: [
    {
      layout: 'accordionWithMedia',
      heightAuto: true,
      defaultMedia: [{orientation: 'square', src: link('careers/careers-1.jpg')}],
      defaultExpandedSectionIndex: 0,
      accordionTitle: 'CAREERS',
      accordionDescription: {render: () => (
        <div>
          Space Theory  is a multi-disciplinary, design-driven company. We are looking for ambitious, talented and dedicated people to join us - people who want to work together to do amazing things for our clients. <br/><br/>
          If you have proven experience related to the positions below and are interested in finding out more, submit your resume, portfolio (for design positions), and cover letter to the email in the job description below. Or if you have a general inquiry you can email <a className='accordion-text-item-link blue' href='mailto:careers@spacetheory.com' target='_blank'>careers@spacetheory.com.</a>
        </div>)
      },
      accordionSections: [
         {
            title: 'CURRENT OPENINGS',
            text: {
              items: [
                // {render: () => (
                //   <>
                //     <a className='accordion-text-item-link dark-gray' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-studio-manager-bay-area.pdf' target='_blank'>Studio Manager, Bay Area</a>
                //     <p>Space Theory, a cutting-edge kitchen design company and a part of the Henrybuilt family, is looking for a dynamic Studio Manager based in the Bay Area. This role involves spearheading the representation of our brand at our new Mill Valley studio, ensuring a premium client experience, and actively engaging with the design community. Join us in shaping the future of kitchen design.</p>
                //   </>
                // )},
                `We do not currently have any openings. Check back soon!`
              ]
            },
            media: [{orientation: 'square', src: link('careers/careers-1.jpg')}]
         },
       ]
    }
  ]
}
