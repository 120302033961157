import {React} from 'components';
import {link, colors} from './helpers';

// istanbul ignore file

export default {
  key: 'who-we-are',
  title: 'Who we are',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Space Theory was founded by the creators of Henrybuilt, the company behind the first American kitchen system. We believe in creating a new level of relaxed order, quality and pleasure while cooking, socializing and just...living. And we want to make it easier to achieve in your own home. To give you this experience, we’ve designed a unique – and uniquely functional - set of products that gives you a complete system, functional innovation, and premium quality.`
        ]
      },
      media: [{orientation: 'square', src: link('why/why-1.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `In 2019, Henrybuilt launched Space Theory as a separate company to offer a simplified kitchen system that is extremely high quality, but more affordable.  Space Theory is built on top of Henrybuilt’s deep experience. This includes the design and production of thousands of kitchens for discerning clients in almost every state in the U.S and 7 countries.`
        ]
      },
      media: [{orientation: 'square', src: link('why/why-2.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `As part of the research and development undertaken prior to the launch of Space Theory, we established a software team to develop what we call the Design Engine, a sophisticated design tool based on a combination of Henrybuilt design standards, along with new standards and new products developed by Space Theory. We use this tool collaboratively with every client to make the process as simple, transparent and error free as possible.`
        ]
      },
      media: [{orientation: 'square', src: link('why/why-3.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Our main goal is making a big positive impact on how you live in your home, by offering a uniquely functional and high quality product. And also by making it as simple and straightforward as possible for you to achieve that.`
        ]
      },
      media: [{orientation: 'square', src: link('why/why-4.jpg')}]
    }
  ]
};
