import {_, React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'project-stories',
  title: 'Project Stories',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'Project Stories'},
          `Real People. Real Projects. From Coast to Coast.`
        ]
      }
    },
    {
      layout: 'custom',
      heightAuto: true,
      render: () => {
        var stories = [
          {
            title: 'Fit for a Restaurateur',
            key: 'fit-for-a-restaurateur'
          },
          {
            title: 'Bi-Coastal',
            key: 'bi-coastal'
          },
          {
            title: 'Modern History',
            key: 'modern-history'
          },
          {
            title: 'True to Form',
            key: 'true-to-form'
          },
          {
            title: 'The Nonconformist',
            key: 'the-nonconformist'
          },
          {
            title: 'Kindred Spirits',
            key: 'kindred-spirits'
          },
          {
            title: 'Creative Assets',
            key: 'creative-assets'
          },
          {
            title: 'Mise en Scène',
            key: 'mise-en-scene'
          },
          {
            title: 'Stripped Down Suburban',
            key: 'stripped-down-suburban'
          },
          {
            title: 'Kitchen Chameleon',
            key: 'kitchen-chameleon'
          },
          {
            title: 'Finding Goldilocks',
            key: 'finding-goldilocks'
          },
          {
            title: 'The Understory',
            key: 'understory'
          },
          {
            title: 'A Modern Interpretation',
            key: 'a-modern-interpretation'
          },
          {
            title: 'Addition by Subtraction',
            key: 'addition-by-subtraction'
          },
        ];

        return (
          <div className='project-stories'>
            <div className='tiles-container'>
              {_.map(stories, (story, s) => {
                var imageLink = link(`project-stories/${story.key}.jpg`);

                return (
                  <Link className='tile' to={`/page/${story.key}`} key={s}>
                    <div className='tile-title'>{story.title}</div>
                    <div className='tile-image-container'>
                      <div className='tile-image' style={{backgroundImage: `url(${imageLink})`}}></div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        );
      }
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          'Start your own project story',
          {render: ({showInquiryForm}) => (
            <a className='text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get in Touch with an expert
            </a>
          )},
        ]
      }
    },
  ]
};
