import _ from 'lodash';

const pageMediaLink = (suffix) => `https://s3.amazonaws.com/spacetheory-public/website/pages/${suffix}`;

const colors = {
  blue: 'rgb(119, 152, 173)'
};

const gridCellGroupsFor = ({page, section, groups}) => {
  return _.map(groups, group => {
    return {...group, cells: _.map(group.cells, cell => {
      return {...cell, thumbnailSrc: pageMediaLink(`${page}/${section}/${cell.key}/${cell.key}-thumbnail.jpg`)};
    })};
  });
};

export {pageMediaLink as link, colors, gridCellGroupsFor};
