import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'addition-by-subtraction',
  title: 'Addition by Subtraction',
  sections: [
    {
      layout: 'mediaTopTextBottom',
      media: [{
        orientation: 'horizontal',
        src: link('addition-by-subtraction/addition-by-subtraction-1.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>Addition by Subtraction</div>
            </div>
          )
        }
      }],
      text: {
        items: [
          `Architecture by Walker Smith-Williams and Billy Erhard`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `A couple walk into their new Brooklyn apartment. They simply want to move the kitchen from its tight spot in the back to the open, light-filled area at the front. They're architects. They know simple is hard.`
        ]
      },
      media: [{orientation: 'square', src: link('addition-by-subtraction/addition-by-subtraction-2.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `To maintain the openness, they design the kitchen to feel like it floats in the space. Minimal objects that become part of their furniture collection.`
        ]
      },
      media: [{orientation: 'square', src: link('addition-by-subtraction/addition-by-subtraction-3.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The three kitchen 'objects' need to hold their own. In material quality. In functional precision. In conversation with the architecture.`
        ]
      },
      media: [{orientation: 'square', src: link('addition-by-subtraction/addition-by-subtraction-4.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `They realize the design with Space Theory, the simplified made-to-order kitchen system from Henrybuilt.

          A simplified form only possible through the distillation of years of experience and knowledge – and the flexible system approach.`
        ]
      },
      media: [{orientation: 'square', src: link('addition-by-subtraction/addition-by-subtraction-5.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Natural light is in short supply. Leaving its source - the windows - unencumbered means no wall units. Pantries flanking the refrigerator offer plentiful storage.`
        ]
      },
      media: [{orientation: 'square', src: link('addition-by-subtraction/addition-by-subtraction-6.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The architects’ engineering minds value the efficiency. A place for everything.`
        ]
      },
      media: [{orientation: 'square', src: link('addition-by-subtraction/addition-by-subtraction-7.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The choice of warm wood introduces natural materials to the white envelope.

          Symmetry maintains the calm.`
        ]
      },
      media: [{orientation: 'square', src: link('addition-by-subtraction/addition-by-subtraction-8.jpg')}]
    },
    {
      layout: 'text',
      text: {
        items: [
          `A couple walk into their renovated Brooklyn apartment. It's simple. And easy.`,
          {render: () => (
            <div className='long-paragraph'>
              We work with homeowners who don't have an architect. <b>We love that</b>. We work with architect/homeowner/designer teams. <b>We love that</b>. And quite often, we work with architects or interior designers who are the homeowner. <b>We love that, too</b>.
            </div>
          )},
          {render: () => (
            <a className='text-item-link' href='https://opendoor.henrybuilt.com/' target='_blank'>
              ARCHITECTS & DESIGNERS: Learn how to put Henrybuilt's three brands to work for you in flexible ways
            </a>
          )}
        ]
      }
    }
  ]
};
