import {gridCellGroupsFor} from './helpers';
import {React} from 'components';
import {Link} from 'react-router-dom';

export default {
  key: 'pulls',
  title: 'Pulls',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'Pulls'},
          `Space Theory pulls are included with every project and ship installed. You can choose a variety of styles for most finish options in anodized clear, black aluminum, and brass.`,
        ]
      }
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'medium'},
      text: {items: [
        {className: 'heading', text: 'Curved Knob'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'materials', section: 'pulls', groups: [
        {title: 'pulls', cells: [
          {title: 'Clear Aluminum', key: 'curved-knob-clear', details: {}},
          {title: 'Black Aluminum', key: 'curved-knob-black', details: {}},
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'medium'},
      text: {items: [
        {className: 'heading', text: 'Knurled Knob'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'materials', section: 'pulls', groups: [
        {title: 'pulls', cells: [
          {title: 'Clear Aluminum', key: 'knurled-knob-clear', details: {}},
          {title: 'Black Aluminum', key: 'knurled-knob-black', details: {}},
          {title: 'Brass', key: 'knurled-knob-brass', details: {}},
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'medium'},
      text: {items: [
        {className: 'heading', text: 'Notched Knob'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'materials', section: 'pulls', groups: [
        {title: 'pulls', cells: [
          {title: 'Clear Aluminum', key: 'notched-knob-clear', details: {}},
          {title: 'Black Aluminum', key: 'notched-knob-black', details: {}},
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'medium'},
      text: {items: [
        {className: 'heading', text: 'Radius Flat Tab'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'materials', section: 'pulls', groups: [
        {title: 'pulls', cells: [
          {title: 'Clear Aluminum', key: 'radius-flat-tab-clear', details: {}},
          {title: 'Black Aluminum', key: 'radius-flat-tab-black', details: {}},
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'medium'},
      text: {items: [
        {className: 'heading', text: 'Radius Staple'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'materials', section: 'pulls', groups: [
        {title: 'pulls', cells: [
          {title: 'Clear Aluminum', key: 'radius-staple-clear', details: {}},
          {title: 'Black Aluminum', key: 'radius-staple-black', details: {}},
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'medium'},
      text: {items: [
        {className: 'heading', text: 'Wire Staple'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'materials', section: 'pulls', groups: [
        {title: 'pulls', cells: [
          {title: 'Brass', key: 'wire-staple-brass', details: {}},
        ]}
      ]})
    }
  ]
};
