import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'a-modern-interpretation',
  title: 'A Modern Interpretation',
  sections: [
    {
      layout: 'mediaTopTextBottom',
      media: [{
        orientation: 'horizontal',
        src: link('a-modern-interpretation/a-modern-interpretation-1.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>A Modern Interpretation</div>
            </div>
          )
        }
      }],
      text: {
        items: [
          `Architecture by Jody Beck and Ross-Alan Tisdale, Traction Architecture. Photography by Seamus Payne.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `It was love at first sight.`
        ]
      },
      media: [{orientation: 'square', src: link('a-modern-interpretation/a-modern-interpretation-2.jpg')}]
    },
    {
      layout: 'text',
      text: {
        items: [
          `A historic home, the first to be built in its Tampa, Florida neighborhood. An aspirational icon meant to inspire development of the surrounding land.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `A home with strong structure, incredible architectural detail and old-world craftsmanship hidden behind peeling walls, leaky cast iron pipes, old wiring and rodent nests.`
        ]
      },
      media: [{orientation: 'square', src: link('a-modern-interpretation/a-modern-interpretation-3.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `For Jody Beck and Ross-Alan Tisdale, of Traction Architecture it was a compelling challenge to transform Dundee House into their home and architecture office.`
        ]
      },
      media: [{orientation: 'square', src: link('a-modern-interpretation/a-modern-interpretation-4.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `“We took a surgical approach to the renovation, pairing down spaces to their essentials while emphasizing the original materials and details.”`
        ]
      },
      media: [{orientation: 'square', src: link('a-modern-interpretation/a-modern-interpretation-5.jpg')}]
    },
    {
      layout: 'media',
      media: [{orientation: 'square', src: link('a-modern-interpretation/a-modern-interpretation-6.jpg')}]
    },
    {
      layout: 'text',
      text: {
        items: [
          `The design celebrates the original architecture while inserting modern interventions in a colorful, minimal way. “It was important that the pieces we added were historically different. We did not want to try and mimic the original architecture but rather, compliment it aesthetically in a non-competitive way”.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Jody knew immediately that she wanted to use Space Theory for the kitchen. “Space Theory captured the spirit of something well crafted, minimalist and beautiful that didn’t overwhelm the architecture of the house”.`
        ]
      },
      media: [{orientation: 'square', src: link('a-modern-interpretation/a-modern-interpretation-7.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `“The kitchen has changed the way we live. It’s our private space, away from the office where we spend the most time. We cook more and we can all do different things at the same time”.`
        ]
      },
      media: [{orientation: 'square', src: link('a-modern-interpretation/a-modern-interpretation-8.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `“Space Theory is just so smooth and functional. The storage is well thought out with a place for everything. It makes the chores of life easier and more fun.”`
        ]
      },
      media: [{orientation: 'square', src: link('a-modern-interpretation/a-modern-interpretation-9.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `An historic home.`,
          `A modern interpretation.`
        ]
      },
      media: [{orientation: 'square', src: link('a-modern-interpretation/a-modern-interpretation-10.jpg')}]
    },
    {
      layout: 'text',
      text: {
        items: [
          `Space Theory provides a flexible vocabulary you can use to design a kitchen that is unique to you.`,
          {render: () => (
            <div className='long-paragraph'>
              But it doesn’t stop there. You can also reach into Henrybuilt’s Primary Objects line of stand-alone pieces which includes kitchen islands, bath vanities, and many other storage pieces. This Henrybuilt ‘a la carte’ option gives you the ability to thoughtfully combine the deep craft and palette of Henrybuilt with the simplicity of Space Theory.
            </div>
          )},
          {render: () => (
            <a className='text-item-link' href='https://objects.henrybuilt.com/objects' target='_blank'>
              EXPLORE PRIMARY OBJECTS
            </a>
          )},
          // {render: () => (
          //   <a className='text-item-link' href='' target='_blank'>
          //     DISCUSS A PROJECT
          //   </a>
          // )}
        ]
      }
    }
  ]
};
