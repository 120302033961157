import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'subscribe',
  title: 'Subscribe',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Subscribe to get the latest news and updates from Space Theory.`,
          {render: () => (
            <div className='subscribe-form-container'>
              <iframe src='/subscribe-form' title='subscribe-form'></iframe>
            </div>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('subscribe/1.jpg')}],
    }
  ]
};
