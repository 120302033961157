import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'true-to-form',
  title: 'True to Form',
  sections: [
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('true-to-form/1.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>True to Form</div>
            </div>
          )
        }
      }],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'larger-text-space-after', text: `“It's the not thinking about things that is so wonderful. Everything in the kitchen is so smooth and seamless that working in it feels intuitive and just easy.”`},
          {className: 'smaller-text-space-after', text: `Tucked in a wooded enclave in the heart of Seattle, a modernist Pacific Northwest home designed in 1958 for a legendary Seattle artist cried out for a transformation to be in sync with a young family’s modern life. The primary focus was the kitchen, where Space Theory’s system was used to bring it to the present without losing the plot of the original design.`},
          {className: 'smaller-text-space-after', text: `It was an exercise in looking to the past to bring it to the present. Ueda Design Studio approached the project with a reverence towards the history of the home, its prior inhabitants, and the story of the city itself. One that has been transformed by technology but that has a deep relationship with lumber and timber; one that has retained a sense of humility; one that values nature. Space Theory was selected for its ability to satisfy this seeming contradiction of functional innovation and a high level craft quality, with a simplicity in form and execution in keeping with the home’s modernist values. It’s about creating level of relaxed order, quality and pleasure while cooking, socializing and just... living.`},
          {render: () => (
            <div className='page-section-text-item text-item-sub-heading is-visible'>
              <div>Seattle, Washington</div>
              <div>Architecture and Design by <a style={{textDecoration: 'none', color: 'rgb(119, 152, 173)'}} href='https://uedads.com/' target='_blank'>Ueda Design Studio</a></div>
              <div>Builder: <a style={{textDecoration: 'none', color: 'rgb(119, 152, 173)'}} href='https://www.mercerbuilders.com/' target='_blank'>Mercer Builders</a></div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('true-to-form/3.jpg')}],
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('true-to-form/4.jpg')}],
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('true-to-form/2.jpg')},
        {orientation: 'square', src: link('true-to-form/5.jpg')}
      ],
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('true-to-form/6.jpg')}],
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('true-to-form/7.jpg')},
        {orientation: 'square', src: link('true-to-form/8.jpg')}
      ],
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('true-to-form/9.jpg')}],
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('true-to-form/12.jpg')},
        {orientation: 'square', src: link('true-to-form/11.jpg')}
      ],
    },
    {
      layout: 'textLeftMediaRight',
      media: [{orientation: 'square', src: link('true-to-form/10.jpg')}],
      text: {
        items: [
          {text: 'The home was designed and built for Seattle Artist Alden Mason. He may have moved on, but he left his signature in the form of a sculptural wall installation in the living room.'},
          {render: ({showInquiryForm}) => (
            <a className='text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get started on your own Space Theory Kitchen
            </a>
          )}
        ]
      }
    },
  ]
};
