import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'natures-touch',
  title: `Nature's Touch`,
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'heading', text: `Nature's Touch`},
          {className: 'sub-heading', text: `San Francisco, California`},
          {className: 'smaller-text', render: () => (
            <div>
              <div>Interior Design: Vis a Vis Studio & Le Studio Inne</div>
              <div>Architecture: Rex McLean</div>
              <div>Contractor: GBI construction & Vonnegut Thoreau Construction</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('natures-touch/1.jpg')
      }],
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('natures-touch/2.jpg')
      }],
    },
    {
      layout: 'textLeftMediaRight',
      media: [{
        orientation: 'square',
        src: link('natures-touch/3.jpg')
      }],
      text: {
        items: []
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('natures-touch/4.jpg')
      }],
      text: {
        items: []
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('natures-touch/5.jpg')
      }],
      text: {
        items: []
      }
    },
    {
      layout: 'doubleMedia',
      media: [
        {
          orientation: 'square',
          src: link('natures-touch/6.jpg')
        },
        {
          orientation: 'square',
          src: link('natures-touch/7.jpg')
        }
      ],
    },
    {
      layout: 'doubleMedia',
      media: [
        {
          orientation: 'square',
          src: link('natures-touch/8.jpg')
        },
        {
          orientation: 'square',
          src: link('natures-touch/9.jpg')
        }
      ],
    }
  ]
};
