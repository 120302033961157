import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Component, {asComponent, withEvents} from './component/component.js';

import './styles/styles.scss';

var className = (names) => {
  return {className: _.join(_.filter(names, name => !!name), ' ')};
};

export {
  React, PropTypes, _,
  Component, asComponent, withEvents,
  className
};
