import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'white-out',
  title: 'White Out',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [

          {className: 'heading', text:  `White Out`},
          {className: 'sub-heading', text:  `Simple Sophistication from Space Theory`},
          {className: 'smaller-text-space-after', text: `When executed well, simple white kitchens can be the ultimate in sophistication. Crisp. Clean. Quiet. And bright. Strong enough to blend in with the architecture or stand alone.`},
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('white-out/1.jpg')
      }]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text-space-after', text: `Simple is complicated...`},
          {className: 'smaller-text-space-after', text: `We know simple is complicated and that's why the makers of Henrybuilt started Space Theory. We've distilled over twenty years of experience and knowledge to create a made-to-order kitchen system that delivers superior results, with less effort, at an accessible price.`}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('white-out/2.jpg')}],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          `Simple doesn't mean basic...`,
          `Space Theory's premium-quality performance kitchens offer a more custom solution.`,
          `Behind the exterior is a full system of flexible organization components that bring a relaxed order to cooking.`
        ]
      },
      media: [
        {orientation: 'square', src: link('white-out/3.jpg')}
      ]
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('white-out/4.jpg')}],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `White can be rich in personality. Space Theory kitchens can take a serious and minimal stance, or a more casual and playful pose. Or something in between.`
        ]
      },
      media: [{orientation: 'square', src: link('white-out/5.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Simple should not mean substandard...`,
          `Space Theory leverages the material quality that Henrybuilt is known for, bringing a level of excellence typically reserved for the top end of the luxury market. From the finish on the outside to the core elements on the inside that perform better, last longer, and feel better to use.`,
          `The wood we use to create our kitchen systems is incredibly durable and supports our finishes.`
        ]
      },
      media: [{orientation: 'square', src: link('white-out/6.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `We have never used paint on our product, as it does not meet our standard of quality. Because of this high standard, we have not been able to offer a fully white kitchen. That's changed. We recently invested in technology that allows us to do just that-creating seamless color matched edges.`
        ]
      },
      media: [{orientation: 'square', src: link('white-out/7.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {render: () => (
            <div>Read our story <a href='/page/mise-en-scene' target='_blank'>Mise en Scene</a> for a full tour of this Space Theory kitchen.</div>
          )}

        ]
      },
      media: [{orientation: 'square', src: link('white-out/8.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {render: ({showInquiryForm}) => (
            <a className='text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get in touch
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('white-out/9.jpg')}]
    },
  ]
};
