import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'bi-coastal',
  title: 'Bi-Coastal',
  sections: [
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('bi-coastal/1.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>Bi-Coastal</div>
            </div>
          )
        }
      }],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text-space-after', text: `With the right design, a small space can feel expansive. A minimal design can feel elegant. A well-crafted system of functional, interrelated parts can make moving around the kitchen feel like dancing…well. From coast to coast, Space Theory provides the expertise to achieve these objectives, and, with our state-of-the-art design software, it doesn’t have to break the bank.`},
          {className: 'smaller-text-space-after', text: `Take one: California modern, dressed in black. Space Theory worked with Verner Architects to transform a dimly lit, closed-off space into an open, sophisticated oasis for cooking and entertaining. To optimize budget, the clients focused on rethinking key spaces of the living room and kitchen to create the most impact, connectivity, and volume throughout their home.`},
          {render: () => (
            <div className='page-section-text-item text-item-sub-heading is-visible'>
              <div>Alameda, California</div>
              <div>Architect: Verner Architects</div>
              <div>Builder: Buestad Construction</div>
              <div>Photographer: Paul Dyer</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('bi-coastal/2.jpg')}],
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'vertical', src: link('bi-coastal/3a-vertical.jpg')},
        {orientation: 'vertical', src: link('bi-coastal/3b-vertical.jpg')}
      ],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text-space-after', text: `Take two: New York minimalist, make it warm. Space Theory collaborated with architect Justin Blejer to make a personalized, expansive space out of a small New York apartment with limited natural light. Keeping long-term value in mind, the client chose Space Theory for its lasting quality, custom fit, and furniture-like feel. The result: an unconfined, perfectly tailored space—timeless and built to last.`},
          {render: () => (
            <div className='page-section-text-item text-item-sub-heading is-visible'>
              <div>New York, New York</div>
              <div>Architect: Blejer Architecture</div>
              <div>Builder: Modern Construction</div>
              <div>Photographer: Max Burkhalter</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('bi-coastal/4.jpg')}],
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'vertical', src: link('bi-coastal/5a-vertical.jpg')},
        {orientation: 'vertical', src: link('bi-coastal/5b-vertical.jpg')}
      ],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {render: ({showInquiryForm}) => (
            <a className='larger-text text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get started on your own Space Theory Kitchen
            </a>
          )}
        ]
      }
    },
  ]
};
