import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'fit-for-a-restaurateur',
  title: 'Fit for a Restaurateur',
  sections: [
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('fit-for-a-restaurateur/1.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>Fit for a Restaurateur</div>
            </div>
          )
        }
      }],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text-space-after', text: `Whoever said you can’t turn a once-painting-studio, once-church into a restaurateur’s home with a modern pink kitchen didn’t know the first thing about SHED Architecture or Quynh-Vy Pham. Having been neighbors for 20 years, the architect and homeowner had a certain “synergy.” They agreed: it was time to turn this historic building into a lively home that honored the building’s serious past while subtly transforming it into something a bit more whimsical…and a bit more pink.`},
          {className: 'smaller-text-space-after', text: `Also down the street? Quynh-Vy Pham’s James Beard–nominated restaurant, The Boat—created in the early ‘80s by her parents and now run by Quynh-Vy and her sister. A neighborhood staple, deemed “Restaurant of the Year” by Seattle Met magazine, it occupies its place on the street confidently, a deep shade of pink.`},
          {render: () => (
            <div className='page-section-text-item text-item-sub-heading is-visible'>
              <div>Seattle, Washington</div>
              <div>Architect: SHED Architecture & Design</div>
              <div>Contractor: Ambrose Construction</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('fit-for-a-restaurateur/2.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text', text: `With a long, personal history in the restaurant world, it was important to ensure Quynh-Vy’s kitchen honored the creativity and culinary prowess with which her family had navigated Seattle for many years. Cooking and gathering were top priorities for her—with a large family and a love for hosting—which meant functionality and openness were top-of-mind in the process of designing the kitchen. From flexible stainless-steel interior components, easily moveable from drawer to counter for cooking and serving, to a 12-foot island with built-in storage, it was ready for action. Aesthetically, along with SHED, Quynh-Vy drew a line between “cool and modern” and “comforting and inviting.”`}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('fit-for-a-restaurateur/3.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text', text: `“You can be expressive of your personality and still timeless and refined,” Quynh-Vy says.`}
        ]
      }
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('fit-for-a-restaurateur/4a.jpg')},
        {orientation: 'square', src: link('fit-for-a-restaurateur/4b.jpg')}
      ],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text', text: `Prentis Hale, a principal at SHED, described the kitchen as the “anchor” of the house, situated on one end of the building adjacent to the south yard—a favorite space for gathering. He said the expertise behind Space Theory’s holistic approach allowed his team to allot time and resources elsewhere throughout the renovation, trusting the kitchen system’s functionality and details to provide the right tools and personalization.`}
        ]
      }
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('fit-for-a-restaurateur/5a.jpg')},
        {orientation: 'square', src: link('fit-for-a-restaurateur/5b.jpg')}
      ],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text', text: `A kitchen with the strength of a system, the spirit of a chef, and the heart of a welcoming host. There is power in pink.`}
        ]
      }
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('fit-for-a-restaurateur/6a.jpg')},
        {orientation: 'square', src: link('fit-for-a-restaurateur/6b.jpg')}
      ],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {render: ({showInquiryForm}) => (
            <a className='larger-text text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get started on your own Space Theory Kitchen
            </a>
          )}
        ]
      }
    },
  ]
};
