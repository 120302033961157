import {_, Component, React, PropTypes, className} from 'components'; //eslint-disable-line
import './section.scss';
import Medium from './medium/medium';
import Text from './text/text';
import Grid from './grid/grid';
import Logo from './logo';
import Accordion from './accordion/accordion';

var textItemPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    text: PropTypes.string.isRequired,
    className: PropTypes.className
  })
]);

var textPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    items: PropTypes.arrayOf(textItemPropType),
    alignX: PropTypes.string,
    alignY: PropTypes.string
  }),
  PropTypes.any
]);

var mediumPropType = PropTypes.shape({
  src: PropTypes.string.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical', 'square']),
  type: PropTypes.oneOf(['image', 'video'])
});

export default class Section extends Component {
  static propTypes = {
    section: PropTypes.shape({
      layout: PropTypes.oneOf([
        'text',
        'media',
        'textOverMedia',
        'textLeft',
        'textLeftMediaRight',
        'textLeftGridRight',
        'mediaTopTextBottom',
        'accordionWithMedia',
        'doubleMedia',
        'custom'
      ]),
      text: textPropType,
      media: PropTypes.arrayOf(mediumPropType)
    }).isRequired,
    page: PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  }

  static defaultProps = {
    page: {title: ''}
  }

  state = {isVisible: false, accordionIndex: null}

  componentDidMount() {
    this.setState({isVisible: true});

    var {layout, text, gridProps, media=[]} = this.props.section;

    var mediaProps = media;
    var textProps = {...text, ..._.pick(this.props, ['showInquiryForm'])};

    if (typeof(text) === 'string') textProps = {items: [text]};

    var Text0 = (props) => <Text {...props} {...textProps}/>;
    var Medium0 = (props) => <Medium {...props} {...mediaProps[0]}/>;
    var Medium1 = (props) => <Medium {...props} {...mediaProps[1]}/>;

    var Logo0 = () => <Logo page={this.props.page} pageSectionsRef={this.props.pageSectionsRef}/>;

    this.LayoutContent = {
      text: () => <><Text0 alignY='center'/><Logo0 /></>,
      media: () => <Medium0/>,
      custom: () => this.props.section.render({..._.pick(this.props, ['showInquiryForm'])}),
      mediaTopTextBottom: () => (
        <div className='horizontal-flex-split'>
          <Medium0/>
          <Text0 alignX='left'/>
        </div>
      ),
      textLeftMediaRight: () => {
        var alignX = this.textStyle === 'details' ? 'left' : 'center';

        return (
          <div {...className([`vertical-split`, media.length === 2 && 'double-media'])}>
            <Text0 alignY={'center'} alignX={alignX}/>
            <Logo0 />
            <div className='media-container'>
              {media.length >= 1 && <Medium0/>}
              {media.length >= 2 && <Medium1/>}
            </div>
          </div>
        );
      },
      textLeftGridRight: ({textProps={}} = {}) => {
        var alignX = _.includes(['details', 'standard'], this.textStyle) ? 'left' : 'center';

        return (
          <div {...className([`vertical-split`])}>
            <Text0 alignY={'center'} alignX={alignX} {...textProps}/>
            <Grid cellGroups={this.props.section.cellGroups} {...gridProps}/>
            <Logo0 />
          </div>
        );
      },
      accordionWithMedia: () => {
        var alignX = this.textStyle === 'details' ? 'left' : 'center';

        return (
          <div {...className([`vertical-split`])}>
            <div className='accordion-container'>
              <Accordion
                accordionSections={this.props.section.accordionSections}
                accordionTitle={this.props.section.accordionTitle}
                accordionDescription={this.props.section.accordionDescription}
                onChange={({index}) => this.setState({accordionIndex: index})}
                showInquiryForm={this.props.showInquiryForm}
                nextPage={this.props.section.nextPage}
                nextPageTitle={this.props.section.nextPageTitle}
                defaultExpandedSectionIndex={this.props.section.defaultExpandedSectionIndex}
              />
            </div>
            <Logo0 />
            <div className='media-container'>
              <Medium {...this.accordionMediumProps}/>
            </div>
          </div>
        );
      },
      doubleMedia: () => {
        return (
          <div {...className(['vertical-split', 'double-media'])}>
            <div {...className(['media-container', 'left'])}>
              <Medium0/>
            </div>
            <div {...className(['media-container', 'right'])}>
              <Medium1/>
            </div>
          </div>
        );
      }
    }[layout];
  }

  componentWillUnmount() {
    this.setState({isVisible: false});
  }

  render() {
    var {layout, maxHeight, heightAuto, hideLogo, id} = this.props.section;

    return this.LayoutContent ? (
      <div
        id={id && `section-${id}`}
        {...className([
          'page-section',
          `layout-${_.kebabCase(layout)}`,
          `text-style-${this.textStyle}`,
          heightAuto && 'heightAuto',
          hideLogo && 'hideLogo'
        ])}
        style={{opacity: this.state.isVisible ? 1 : 0, ...(maxHeight && {maxHeight})}}
      >
        <this.LayoutContent />
      </div>
    ) : <></>;
  }

  get textStyle() {
    var defaultStyle = _.includes(['mediaTopTextBottom', 'text'], this.props.section.layout) ? 'details' : 'branding';
    var style = this.props.section.textStyle || defaultStyle;

    return style;
  }

  get accordionMediumProps() {
    var mediumProps = this.props.section.defaultMedia[0];

    if (this.state.accordionIndex !== null) {
      mediumProps = _.get(this.props.section.accordionSections[this.state.accordionIndex], 'media[0]', mediumProps);
    }

    return mediumProps;
  }
}
