import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'mise-en-scene',
  title: 'Mise en Scène',
  sections: [
    {
      layout: 'mediaTopTextBottom',
      media: [{
        orientation: 'horizontal',
        src: link('mise-en-scene/mise-en-scene-1.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>Mise en Scène</div>
              <div className='project-story-location'>San Francisco, CA</div>
            </div>
          )
        }
      }],
      text: {
        items: [
          {render: () => (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              <div style={{marginRight: '2em', marginBottom: '0.25em'}}>Architecture and Interior Design: Rachel Lehn</div>
              <div>Contractor: Barney Keaney</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Setting the stage. That’s exactly what Architect Rachel Lehn did, creating a backdrop for family living – specifically her own (kids and puppy included).`,
          `An artform with purpose.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('mise-en-scene/mise-en-scene-1.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Lehn set about reimagining the long, narrow, and dark San Francisco home as a white cube with character and ease. Light and bright. Minimal and warm.`,
          `She designed openings – playful geometry in their own right - to pull light into the core of the home.`
        ]
      },
      media: [{orientation: 'square', src: link('mise-en-scene/mise-en-scene-3.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `“The architecture is really about minimal intervention such that it recedes and makes way for the activity inside,” says Lehn. “Its intention is to highlight the life and objects in the space.”`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('mise-en-scene/mise-en-scene-4.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `At the center of it all is the family kitchen.`,
          `“With the open concept, we didn't want it to feel too much like a kitchen,” says Lehn.” We wanted it to simultaneously recede into the architecture and feel like furniture in the space. But it had to be functional to support all that happens there.”`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('mise-en-scene/mise-en-scene-5.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `“We had bookmarked Henrybuilt as a company we wanted to work with,” said Lehn. “So, when it came time to design my own kitchen, I turned to their Space Theory kitchen system. I wanted what their approach offers - an easy process, thoughtful details and functionality, and the trust that comes with that -  but with enough customization to make it our own.”`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Underneath it all, function abounds. The cooktop vent is tucked out of sight. The minimal floating oak shelves arrived with integrated LED fixtures to shed light on the workspace below.`
        ]
      },
      media: [{orientation: 'square', src: link('mise-en-scene/mise-en-scene-6.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Everything AND the kitchen sink. Lehn leveraged Space Theory’s full kitchen solution with an integrated Julien-for-Space-Theory sink and seamless solid surface acrylic countertop and backsplash.`
        ]
      },
      media: [{orientation: 'square', src: link('mise-en-scene/mise-en-scene-7.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The beveled countertop edge gives a thinner appearance, while still providing the strength and functionality of a thicker counter.`,
          `An integrated induction cooktop was selected to blend sleek looks (no bulky burners or the need for a heat shield on the wall behind), deliver practical performance (heat control and efficiency), and provide safety for a young family.`
        ]
      },
      media: [{orientation: 'square', src: link('mise-en-scene/mise-en-scene-8.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Flexible Organization'},
          `The tools you need to create the organization you want. Space Theory’s modular wood and stainless steel drawer inserts keep your tools neat in a flexible way, enabling you to change the layout to suit your needs and taste.`,
          {render: () => (
            <a className='text-item-link blue' href='/page/flexible-organization' target='_blank'>
              Learn more
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('mise-en-scene/mise-en-scene-9.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Lehn designed an enclave within the kitchen that houses the pantry and a discreet coffee/drinks station.  She wanted it to feel separate, but not closed off, from the kitchen. The move from white to black delineates the function and creates a shadow box of sorts.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('mise-en-scene/mise-en-scene-10.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `It also provided an opportunity to introduce a natural material - black stained oak - to add texture and warmth. Crisp white technical laminate fronts, warm white oak shelves, natural marble slabs, and the black oak-clad niche creates a winning combination.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('mise-en-scene/mise-en-scene-11.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `A perfect balance of light and dark. Simple form and high function. A backdrop to support and spotlight this family’s life.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('mise-en-scene/mise-en-scene-13.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'heading', text: 'This is a Space Theory kitchen.'},
          {render: () => (
            <div className='long-paragraph' style={{width: '100%'}}>
              We work with homeowners who don't have an architect. <b>We love that</b>. We work with architect, homeowner, designer teams. <b>We love that</b>. And quite often, we work with architects or interior designers who are the homeowner. <b>We love that, too</b>.
            </div>
          )},
          {render: ({showInquiryForm}) => (
            <a className='text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get started on your own Space Theory Kitchen
            </a>
          )},
        ]
      },
      media: [{orientation: 'square', src: link('mise-en-scene/mise-en-scene-14.jpg')}]
    },
  ]
};
