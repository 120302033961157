import {_, React} from 'components';
import {Form as SemanticForm} from 'semantic-ui-react';
import {InputWithOptions} from 'lib/form/inputs/input/input.js';
import './dropdown-input.scss';

class DropdownInput extends InputWithOptions {
  constructor(props) {
    super(props);

    this.handleBlur = this.handleBlur.bind(this);
  }

  handleBlur(event) {
    this.trigger('blur', {event});
  }

  handleChange(event, {value}) {
    super.handleChange({event, value});
  }

  render() {
    var {options} = this;

    //WARNING key is required to properly implement changing options
    return (
      <div className={`form-input dropdown-input${this.props.className ? ` ${this.props.className}` : ''}`}>
        {this.props.name && <input type='hidden' name={this.props.name} value={this.props.value}/>}
        <SemanticForm.Select
          search={this.props.isSearchable || (this.props.isSearchable !== false && this.props.options.length > 5)}
          options={options}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          key={this.props.optionsKey}
          {..._.pick(this.props, ['name', 'label', 'placeholder', 'value', 'multiple', 'fluid', 'disabled'])}
        />
      </div>
    );
  }

  get options() {
    return _.map(this.props.options, ({key, value, title}) => ({key: key || value, value, text: title}));
  }
}

export default DropdownInput;
