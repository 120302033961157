import {_, Component, React, className} from 'components'; //eslint-disable-line
import './constraints.scss';
import PropTypes from 'prop-types';

// istanbul ignore next
var lib = {number: {
  toFraction(number) {
    var string = '';
    var remainder = Math.round((number - Math.floor(number)) * 100000) / 100000;

    //Strip integer off the front
    if (number >= 1 || remainder === 0) {
      string += Math.floor(number);
    }

    if (remainder > 0) {
      //Identify the fraction parts
      var gcd = function(a, b) {
        if (!b) return a;
        a = parseInt(a);
        b = parseInt(b);
        return gcd(b, a % b);
      };

      var len = remainder.toString().length - 2;

      var denominator = Math.pow(10, len);
      var numerator = remainder * denominator;
      var divisor = gcd(numerator, denominator);
      numerator /= divisor;
      denominator /= divisor;

      //Add the fraction to the string
      if (number >= 1) {
        string += ' ';
      }

      string += `${numerator}/${denominator}`;
    }

    return string;
  }
}};

class Constraints extends Component {
  static propTypes = {
    constraints: PropTypes.shape({}).isRequired,
  }

  render() {
    return (
      <div className='constraints'>
        {_.map(['width', 'height', 'depth'], (sizeKey, s) => {
          var {fixed, min, max, step} = this.props.constraints[sizeKey];
          var useFixed = fixed.length > 0;

          return (
            <div className='constraint' key={s}>
              <div className='constraint-title'>{sizeKey[0].toUpperCase()}</div>
              <div className='constraint-values'>
              <div className='constraint-value'>
                {useFixed ? (
                  _.map(fixed, (value, v) => (
                    <>
                      <div key={v} className='constraint-value constraint-value-fixed'>{lib.number.toFraction(value)}</div>
                      <div className='constraint-value constraint-value-antecedent fixed'></div>
                    </>
                  ))
                ) : (
                  <>
                    <div className='constraint-value constraint-value-min'>{lib.number.toFraction(min)}</div>
                    <div className='constraint-value constraint-value-line'></div>
                    <div className='constraint-value constraint-value-antecedent'>to</div>
                    <div className='constraint-value constraint-value-line'></div>
                    <div className='constraint-value constraint-value-max'>{lib.number.toFraction(max)}</div>
                    <div className='constraint-value constraint-value-step'>{`${lib.number.toFraction(step)}" increments`}</div>
                  </>
                )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Constraints;
