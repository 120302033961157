import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'finding-goldilocks',
  title: 'Finding Goldilocks',
  sections: [
    {
      layout: 'mediaTopTextBottom',
      media: [{
        orientation: 'horizontal',
        src: link('finding-goldilocks/finding-goldilocks-1.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>Finding Goldilocks</div>
              <div className='project-story-location'>Vashon Island, Washington</div>
            </div>
          )
        }
      }],
      text: {
        items: [
          `Architecture by Doug Drape Architect. Contractor: Suny Remodeling`
        ]
      }
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `An architect with a self-professed orientation that “he could do everything” realized that when it came to remodeling his own kitchen, he couldn’t. He started exploring options from pre-designed flat pack kitchens to crafted millwork.`,
          `Nothing felt quite right.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('finding-goldilocks/finding-goldilocks-1.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `The house, their way of living, and their design ethos craved simplicity and comfort.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `While the clients were longtime admirers of Henrybuilt, the project didn’t demand their specialized handcrafted componentry and personal design service. But, it did deserve the craft quality and high function that is at the core of every Henrybuilt company.`,
          `They turned to Space Theory, a distillation of Henrybuilt’s  twenty years of experience and knowledge into a made-to-order kitchen system that delivers superior results, with less effort, at a more accessible price.`
        ]
      },
      media: [{orientation: 'square', src: link('finding-goldilocks/finding-goldilocks-2.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `“Making things look calm and feel comfortable is always a challenge. It is about finding the right fit,” said architect client Doug Drape. “We explored several options and kept coming back to Space Theory. The system brings together the complicated details simply.”`,
          `It was important to Doug and his wife that the kitchen felt like their own – not an off-the-shelf model – while still having the assurance that it would work impeccably.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('finding-goldilocks/finding-goldilocks-3.jpg')}],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The flexibility built into the Space Theory kitchen system gave them comfort in achieving both a unique design and superior functionality. “We got just what we wanted, how we wanted. There was nothing that couldn't be accommodated,” said Drape.`,
          {render: () => (
            <a className='text-item-link blue' href='/page/a-complete-system' target='_blank'>
              LEARN MORE ABOUT SPACE THEORY'S COMPLETE SYSTEM
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('finding-goldilocks/finding-goldilocks-4.jpg')}],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `What had been a closed utility and storage room became an extension of the kitchen. The Space Theory Kitchen system product is flexible enough to be used in adjacent spaces. It turned what could be a mundane hallway into a functional and elegant spot - a quiet and sophisticated transition zone.`
        ]
      },
      media: [{orientation: 'square', src: link('finding-goldilocks/finding-goldilocks-5.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `That same flexibility and the broad range of system details lends a sophistication and coherence, whether creating an integrated paneled spot for the freestanding refrigerator or finishing the top of the functional backsplash system with a solid wood detail.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('finding-goldilocks/finding-goldilocks-6.jpg')}],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The beauty of the product also spoke to them – the visual presence of the walnut and the low sheen finish.`,
          `“It’s nice because the wood looks like what it is, and it’s not trying to be something else,” said Drape.`,
          {render: () => (
            <a className='text-item-link blue' href='/page/materials' target='_blank'>
              EXPLORE SPACE THEORY MATERIALS
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('finding-goldilocks/finding-goldilocks-7.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Space Theory’s system is all about creating a precise, refined space that covers every detail – like putting your usually inert backsplash to work. The shelves do double duty as serving trays. The stainless rod likes to hold paper towels or kitchen linens. And, it can be reconfigured as needs change (wine storage anyone?).`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('finding-goldilocks/finding-goldilocks-8.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `It’s also about providing a whole kitchen solution to make the process easier and your experience living in it even better.`,
          `“We were planning to pursue other options for the counters and backsplash, but the lovely thing about Space Theory is you can get the whole kitchen. It’s ‘the works’. It makes it simple.”`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('finding-goldilocks/finding-goldilocks-9.jpg')}],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The stainless steel organizing inserts (made from offcuts in the manufacturing of sinks) transfer from drawer to dishwasher and back again. The configuration is as personal as each client.`,
          {render: () => (
            <a className='text-item-link blue' href='/page/functional-innovation' target='_blank'>
              LEARN MORE ABOUT SPACE THEORY'S FUNCTIONAL INNOVATION
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('finding-goldilocks/finding-goldilocks-10.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `“From the viewpoint of functionality and quality - everything is settled. We don't need to worry about what is going to happen in the kitchen anymore. It truly will outlive us. It is a multigenerational home.”`,
          `That just-right feeling. Goldilocks could live here.`,
          {render: () => (
            <a className='text-item-link blue' href='/page/quality' target='_blank'>
              LEARN MORE ABOUT SPACE THEORY QUALITY
            </a>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('finding-goldilocks/finding-goldilocks-11.jpg')}],
    },
    {
      layout: 'text',
      text: {
        items: [
          `This is a Space Theory kitchen.`,
          {render: () => (
            <div className='long-paragraph'>
              We work with homeowners who don't have an architect. <b>We love that</b>. We work with architect/homeowner/designer teams. <b>We love that</b>. And quite often, we work with architects or interior designers who are the homeowner. <b>We love that, too</b>.
            </div>
          )},
          {render: () => (
            <a className='text-item-link blue' href='/page/get-started' target='_blank'>
              GET STARTED ON YOUR OWN SPACE THEORY KITCHEN
            </a>
          )}
        ]
      }
    },
  ]
};
