import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'creative-assets',
  title: 'Creative Assets',
  sections: [
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('creative-assets/creative-assets-2.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>Creative Assets</div>
              <div className='project-story-location'>Springs, New York</div>
            </div>
          )
        }
      }],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `A couple with livelihoods rooted in television and photo production are experienced in bringing creative projects to life. They applied these same skills to the renovation of a cabin nestled in the wooded enclave of Springs, in East Hampton, New York. There was no shortage of vision.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('creative-assets/creative-assets-2.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `It began as an exercise in gathering creative assets. They had a lot to work with.`,
          `At the core was the home - an untouched 1970s A-frame kit house. The couple immersed themselves into the architecture, living in it for five years to understand how it felt before manipulating the space. In the end, they knew they wanted to retain its purity. Their focus was to modernize the functional areas – primarily the kitchen – with the least amount of intervention.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('creative-assets/creative-assets-3.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Then there was the setting - Springs, New York, a low-key community tucked in a dense forest in East Hampton. Known as a mid-century artist enclave, some call Springs the center of the American abstract expressionist movement. Jackson Pollock, his wife, the artist Lee Krasner, and painter Willem de Kooning produced much of their work there.`,
          `The home and its place combined to set the mood and the creative direction of the renovation.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `“We focus on the things that work the hardest for us - and are always there - like the kitchen,” said the client. “We put our resources toward the materials we touch and feel every day. The lighting. The hardware. The kitchen. What is really going to spark joy.”`
        ]
      },
      media: [{orientation: 'square', src: link('creative-assets/creative-assets-4.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Producers are good at selecting the best partners to get from where they are to where they want to be. For the kitchen, this duo turned to Space Theory.`,
          `Hailing from Europe and the UK, they were no strangers to the system approach. They value the functionality inherent with systems. But, because the kitchen is such a focal point of the space, they wanted the refinement of detail and built-in unique functionality that they experienced with their Henrybuilt kitchen in Manhattan. Yet, this cabin demanded something a bit simpler – and priced accordingly – that didn’t sacrifice the craft quality and high function that is at the core of Henrybuilt. That’s what Space Theory is about.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('creative-assets/creative-assets-5.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `“A key was that Space Theory has an established process for creative decision making in the work,” says the client. “It helped navigate us in making choices for creating a kitchen we want to live in.”`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('creative-assets/creative-assets-6.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `The story boarding of the space was about adhering to an eclectic and connected feeling. And, the playout did just that. In the mix of materials and the massing. What was built-in and what stood on its own.`,
          `The clients wanted a kitchen that, while new, felt true to the house. The materials harken to those from the age of the architecture. Grey laminate cabinet fronts. An anodized aluminum backsplash that extends to the top of the kitchen boundary. A black enamel refrigerator. And, touches of wood that dialogue with the structure. Mixing tones keeps it from feeling too modern. “Oddly using wood for the kitchen felt too contemporary,” said the creative director of the couple.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('creative-assets/creative-assets-7.jpg')}],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The clients selected Space Theory’s functional Opencase wall panels that serve as a backsplash and offer up storage spots that can move and change along with needs or whims. The anondized alumimum surface has a softness and lightness that pulls the sunshine into the kitchen.`
        ]
      },
      media: [{orientation: 'square', src: link('creative-assets/creative-assets-8.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {type: 'sub-heading', text: `Flexible Organization`},
          `Walls that work. Space Theory’s kitchens integrate with the Opencase system that turns your wall or backsplash into a beautiful, flexible, and functional surface.`,
          {render: () => (
            <a className='text-item-link blue' href='/page/flexible-organization' target='_blank'>
              Learn more
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('creative-assets/creative-assets-9.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Paperstone counters were chosen for their natural patina and the organic feeling that the client found warmer than stone.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('creative-assets/creative-assets-10.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `It was a renovation rooted in not fighting the house that was already there.`
        ]
      },
      media: [{orientation: 'square', src: link('creative-assets/creative-assets-11.jpg')}]
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('creative-assets/creative-assets-12.jpg')}]
    },
    {
      layout: 'text',
      text: {
        items: [
          {className: 'sub-heading', text: 'This is a Space Theory kitchen.'},
          {render: () => (
            <div className='long-paragraph' style={{width: '100%'}}>
              We work with homeowners who don't have an architect. <b>We love that</b>. We work with architect, homeowner, designer teams. <b>We love that</b>. And quite often, we work with architects or interior designers who are the homeowner. <b>We love that, too</b>.
            </div>
          )},
          {render: ({showInquiryForm}) => (
            <a className='text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get started on your own Space Theory Kitchen
            </a>
          )},
        ]
      }
    },
  ]
};
