import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'pretty-in-pink',
  title: 'Pretty in Pink',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'heading', text: `Pretty in Pink`},
          {className: 'sub-heading', text: `Seattle, Washington`},
          {className: 'smaller-text', render: () => (
            <div>
              <div>Architecture: SHED Architecture & Design</div>
              <div>Contractor: Ambrose Construction</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('pretty-in-pink/1.jpg')
      }],
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('pretty-in-pink/2.jpg')
      }],
    },
    {
      layout: 'textLeftMediaRight',
      media: [{
        orientation: 'square',
        src: link('pretty-in-pink/3.jpg')
      }],
      text: {
        items: []
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('pretty-in-pink/4.jpg')
      }],
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('pretty-in-pink/5.jpg')
      }],
    },
    {
      layout: 'doubleMedia',
      media: [
        {
          orientation: 'square',
          src: link('pretty-in-pink/6.jpg')
        },
        {
          orientation: 'square',
          src: link('pretty-in-pink/7.jpg')
        }
      ],
    },
    {
      layout: 'textLeftMediaRight',
      media: [
        {
          orientation: 'horizontal',
          src: link('pretty-in-pink/8.jpg')
        }
      ],
      text: {
        items: []
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('pretty-in-pink/9.jpg')
      }],
    },
    {
      layout: 'textLeftMediaRight',
      media: [
        {
          orientation: 'horizontal',
          src: link('pretty-in-pink/10.jpg')
        }
      ],
      text: {
        items: []
      }
    }
  ]
};
