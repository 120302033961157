import {link} from './helpers';
import {gridCellGroupsFor} from './helpers';
import {React} from 'components';
import {Link} from 'react-router-dom';


// istanbul ignore file

export default {
  key: 'flexible-organization',
  title: 'Flexible Organization',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Interior Components'},
          `The tools you need to create the organization you want are designed into the system.  Our wood and stainless steel drawer organization is designed to be modular to keep your utensils neat in a flexible way. Designed on a grid, you can change the layout to your own taste. They are also usable outside the drawers for serving or storage. Cleaning is a cinch. Simply lift out the silicone liner and throw the stainless bins and mats in the dishwasher. The bins are made from the offcuts of our sinks making them super durable and while reducing waste.`
        ]
      },
      media: [{orientation: 'square', src: link('uniquely-functional/uniquely-functional-3.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'medium'},
      text: {items: [
        {className: 'heading', text: 'Interior Components'}
      ]},
      cellGroups: gridCellGroupsFor({page: 'uniquely-functional', section: 'drawer-storage', groups: [
        {title: 'Stainless steel components', showGroupTitle: true, cells: [
          {title: 'Insert Bin 5 Stainless', key: 'insert-bin-5-stainless', details: {}},
          {title: 'Insert Bin 10 Stainless', key: 'insert-bin-10-stainless', details: {}},
          {title: 'Insert Bin 20 Stainless', key: 'insert-bin-20-stainless', details: {}},
        ]},
        {title: 'Wood components (available in oak or walnut)', showGroupTitle: true, cells: [
          {title: 'Cutting Board', key: 'cutting-board', details: {}},
          {title: 'Spice Rack', key: 'spice-rack', details: {}},
          {title: 'Knife Block', key: 'knife-block', details: {}},
          {title: 'Cutlery Divider', key: 'cutlery-divider', details: {}},
        ]},
      ]})
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Opencase'},
          `Our system approach fuels innovation. For instance, we developed our grid based panel system, Opencase,  to add unique function and flexibility to areas such as a pantry, drop zone, bar area or backsplash. The panels are custom fit to your space and you choose the activation points. The accessories such as shelves, wine racks and rods can be easily moved or switched out as your needs change.`
        ]
      },
      media: [{orientation: 'square', src: link('uniquely-functional/uniquely-functional-1.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Opencase Panel'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'basic-components', section: 'panel-opencase', groups: [
        {title: 'OPENCASE PANELS', cells: [
          {
            id: 1058,
            title: 'Opencase Vertical',
            key: 'opencase-vertical',
            details: {
              media: [],
              notes: [
                `max panel size is 47” x 95“ regardless of orientation`,
                `if side of panel is seen, cap material fixed to create finished edge`,
                `finished/banded on all sides`,
                `available grid is 6” H x 7.5” W unless noted otherwise`,
                `opencase grain direction does not flow over 95” length`
              ],
              constraints: {
                width: {min: 21, max: 47, step: 0.25, fixed: []},
                height: {min: 18, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [1.25]}
              }
            }
          },
          {
            id: 1070,
            title: 'Opencase Horizontal',
            key: 'opencase-horizontal',
            details: {
              media: [],
              notes: [
                `max panel size is 47” x 95“ regardless of orientation`,
                `if side of panel is seen, cap material fixed to create finished edge`,
                `finished/banded on all sides`,
                `available grid is 6” H x 7.5” W unless noted otherwise`,
                `opencase grain direction does not flow over 95” length`
              ],              constraints: {
                width: {min: 21, max: 95, step: 0.25, fixed: []},
                height: {min: 18, max: 47, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [1.25]}
              }
            }
          },
          {
            id: 1090,
            title: 'Opencase Blank',
            key: 'opencase-blank',
            details: {
              media: [],
              notes: [
                `max panel size is 47” x 95“ regardless of orientation`,
                `if side of panel is seen, cap material fixed to create finished edge`,
                `finished/banded on all sides`,
                `available grid is 6” H x 7.5” W unless noted otherwise`,
                `opencase grain direction does not flow over 95” length`
              ],              constraints: {
                width: {min: 8, max: 95, step: 0.25, fixed: []},
                height: {min: 8, max: 47, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [1.25]}
              }
            }
          },
        ]},
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [
        {className: 'heading', text: 'Opencase Accessories'},
      ]},
      cellGroups: gridCellGroupsFor({page: 'uniquely-functional', section: 'opencase-accessories', groups: [
        {cells: [
          {
            id: 683,
            title: 'Metal Shelf 5" short',
            key: 'metal-shelf-5-short',
            details: {
              media: [],
              notes: [
                `min panel width of 21” for 20 1/2” wide accessories`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [20.5]},
                height: {min: 0, max: 0, step: 0, fixed: [0.75]},
                depth: {min: 0, max: 0, step: 0, fixed: [5.625]}
              }
            }
          },
          {
            id: 684,
            title: 'Metal Shelf 5" long',
            key: 'metal-shelf-5-long',
            details: {
              media: [],
              notes: [
                `min panel width of 36” needed for 35 1/2” wide accessories`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [35.5]},
                height: {min: 0, max: 0, step: 0, fixed: [0.75]},
                depth: {min: 0, max: 0, step: 0, fixed: [5.625]}
              }
            }
          },
          {
            id: 679,
            title: 'Metal Shelf 12" short',
            key: 'metal-shelf-12-short',
            details: {
              media: [],
              notes: [
                `min panel width of 21” for 20 1/2” wide accessories`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [20.5]},
                height: {min: 0, max: 0, step: 0, fixed: [0.75]},
                depth: {min: 0, max: 0, step: 0, fixed: [12.625]}
              }
            }
          },
          {
            id: 681,
            title: 'Metal Shelf 12" Long',
            key: 'metal-shelf-12-long',
            details: {
              media: [],
              notes: [
                `min panel width of 36” needed for 35 1/2” wide accessories`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [35.5]},
                height: {min: 0, max: 0, step: 0, fixed: [0.75]},
                depth: {min: 0, max: 0, step: 0, fixed: [12.625]}
              }
            }
          },
        ]},
        {cells: [
          {
            id: 687,
            title: 'wood shelf 5" short',
            key: 'wood-shelf-5-short',
            details: {
              media: [],
              notes: [
                `min panel width of 21” for 20 1/2” wide accessories`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [35.5]},
                height: {min: 0, max: 0, step: 0, fixed: [0.75]},
                depth: {min: 0, max: 0, step: 0, fixed: [5.625]}
              }
            }
          },
          {
            id: 686,
            title: 'wood shelf 5" long',
            key: 'wood-shelf-5-long',
            details: {
              media: [],
              notes: [
                `min panel width of 36” needed for 35 1/2” wide accessories`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [35.5]},
                height: {min: 0, max: 0, step: 0, fixed: [0.75]},
                depth: {min: 0, max: 0, step: 0, fixed: [5.625]}
              }
            }
          },
          {
            id: 685,
            title: 'wood shelf 12" short',
            key: 'wood-shelf-12-short',
            details: {
              media: [],
              notes: [
                `min panel width of 21” for 20 1/2” wide accessories`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [20.5]},
                height: {min: 0, max: 0, step: 0, fixed: [1.25]},
                depth: {min: 0, max: 0, step: 0, fixed: [12.625]}
              }
            }
          },
          {
            id: 688,
            title: 'wood shelf 12" long',
            key: 'wood-shelf-12-long',
            details: {
              media: [],
              notes: [
                `min panel width of 36” needed for 35 1/2” wide accessories`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [35.5]},
                height: {min: 0, max: 0, step: 0, fixed: [1.25]},
                depth: {min: 0, max: 0, step: 0, fixed: [12.625]}
              }
            }
          },
        ]},
        {cells: [
          {
            id: 848,
            title: 'Wine Rack',
            key: 'wine-rack',
            details: {
              media: [],
              notes: [
                `min panel width of 36” needed for 35 1/2” wide accessories`,
                `holds up to 8 wine bottles`],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [35.5]},
                height: {min: 0, max: 0, step: 0, fixed: [0.75]},
                depth: {min: 0, max: 0, step: 0, fixed: [12.625]}
              }
            }
          },
          {
            id: 703,
            title: 'Rod 5"',
            key: 'rod-5',
            details: {
              media: [],
              notes: [
                `min panel width of 36” needed for 35 1/2” wide accessories, 21” for 20 1/2” wide accessories`,
                `can be used to hold 5” shelves, cutting boards, towels, jackets, etc`],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [1]},
                height: {min: 0, max: 0, step: 0, fixed: [1]},
                depth: {min: 0, max: 0, step: 0, fixed: [5.625]}
              }
            }
          },
          {
            id: 704,
            title: 'Rod 12"',
            key: 'rod-12',
            details: {
              media: [],
              notes: [
                `min panel width of 36” needed for 35 1/2” wide accessories, 21” for 20 1/2” wide accessories`,
                `can be used to hold 12” shelves, paper towels, etc.`],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [1]},
                height: {min: 0, max: 0, step: 0, fixed: [1]},
                depth: {min: 0, max: 0, step: 0, fixed: [12.625]}
              }
            }
          },
          {
            id: 705,
            title: 'Deco Cap',
            key: 'deco-cap',
            details: {
              media: [],
              notes: [
                `min panel width of 36” needed for 35 1/2” wide accessories, 21” for 20 1/2” wide accessories`,
                `can be used to cover threaded panel holes, hold storage bins, wash cloths.`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [1]},
                height: {min: 0, max: 0, step: 0, fixed: [1]},
                depth: {min: 0, max: 0, step: 0, fixed: [0.25]}
              }
            }
          },
        ]}
      ]})
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Storage Tray'},
          `The unique functionality of our storage tray creates an intuitive, free flowing experience in the kitchen. It can be stored in a base or tall unit, easily hook onto your Opencase backsplash and later removed and used for serving. Designed with organization in mind, it keeps all of your most used items within easy reach while keeping your countertop free.`,
          `For example, use it to store your coffee supplies in a dedicated base unit. When coffee calls, simply take it out and hang it on the Opencase backsplash. Everything is right where you need it without taking up valuable counter space.`
        ]
      },
      media: [{orientation: 'square', src: link('uniquely-functional/uniquely-functional-2.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'auto'},
      text: {items: [{className: 'heading', text: 'Storage Tray'}]},
      cellGroups: gridCellGroupsFor({page: 'uniquely-functional', section: 'storage-tray', groups: [
        {cells: [
          {
            id: 691,
            title: 'Open Unit with Storage Tray',
            key: 'open-unit-with-storage-tray',
            details: {
              media: [],
              notes: [
                `includes 3 storage trays`,
                `veneer carcass only`,
                `trays available in standard powdercoat colors`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [14]},
                height: {min: 0, max: 0, step: 0, fixed: [30]},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 694,
            title: 'Tall Open Unit For Storage Tray',
            key: 'tall-open-unit-for-storage-tray',
            details: {
              media: [],
              notes: [
                `veneer only`,
                `includes 3 storage trays`,
                `veneer carcass only`,
                `trays available in standard powdercoat colors`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [14]},
                height: {min: 68, max: 95, step: 0.25, fixed: []},
                depth: {min: 0, max: 0, step: 0, fixed: [24.625]}
              }
            }
          },
          {
            id: 695,
            title: 'Vertical Single Leaf With Storage Tray',
            key: 'vertical-single-leaf-with-storage-tray',
            details: {
              media: [],
              notes: [
                `veneer carcass only`,
                `includes 1 storage tray`,
                `open bay is fixed in height at 13 1/4” high`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [24]},
                height: {min: 0, max: 0, step: 0, fixed: [30, 36, 42]},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
          {
            id: 696,
            title: 'Vertical Double Leaf With Storage Tray',
            key: 'vertical-double-leaf-with-storage-tray',
            details: {
              media: [],
              notes: [
                `veneer carcass only`,
                `includes 2 storage tray`,
                `open bay is fixed in height at 13 1/4” high`
              ],
              constraints: {
                width: {min: 0, max: 0, step: 0, fixed: [48]},
                height: {min: 0, max: 0, step: 0, fixed: [30, 36, 42]},
                depth: {min: 0, max: 0, step: 0, fixed: [14.875]}
              }
            }
          },
        ]},
        {cells: [
          {title: 'Storage Tray', key: 'storage-tray', details: {}},
          {title: 'Storage Tray Hanging on Opencase', key: 'storage-tray-1', details: {}}
        ]}
      ]})
    }
  ]
};
