import {React} from 'components';
import './popup.scss';

export default function Popup({children, position, type='pane', showCloseButton=true, onClose}) {
  var src = 'https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/images/icons/x.png';

  return (
    <div className={`popup type-${type} popup-wrapper position-${position}`}  onClick={event => event.target.classList.contains('popup-wrapper') && onClose(event)}>
      <div className='popup-window'>
        {showCloseButton && <img src={src} className='popup-close-button' onClick={onClose} alt='close popup'/>}
        <div className='popup-content'> {children} </div>
       </div>
    </div>
  );
}
