import {_, React} from 'components';
import Text from 'components/app/page/section/text/text.js';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'press',
  title: 'Press',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'Press'},
          `The latest scoop on Space Theory—and a look inside the fast-growing number of homes that have integrated our system approach.`
        ]
      }
    },
    {
      layout: 'custom',
      heightAuto: true,
      render: () => {
        var quotes = [
          {
            body: `The New York Real Estate Journal covers the move of Space Theory and its sister company Henrybuilt from their respective SoHo locations to Dumbo’s historic “Stable Building” in 2025. “This will be the first time in New York that the sister companies share a retail space, allowing visitors to engage with and compare both systems. Henrybuilt, founded in Seattle in 2001, pioneered the first luxury-level American kitchen system and has expanded to cover nearly every space in the home, including wardrobes, baths, and offices. Known for its system-based approach, Henrybuilt integrates all kitchen components—cabinets, counters, lighting—into a cohesive design, distinguishing it from the traditional American ‘parts’ method.”`,
            author: 'New York Real Estate Journal',
            link: 'https://nyrej.com/two-trees-management-leases-showroom-to-luxury-design-company-henrybuilt'
          },
          {
            body: `In an article titled, “Dumbo Design District Welcomes New York’s Creative Leaders,” Designboom includes Space Theory and Henrybuilt’s 2025 move to the “iconic Stable Building at 16 Main Street.” “This historic structure, originally built in 1906 and renovated in 2015, houses Henrybuilt on the second floor and Space Theory on the ground level, marking the companies’ first shared New York retail space after two decades in SoHo.”`,
            author: 'Designboom',
            link: 'https://www.designboom.com/architecture/dumbo-new-york-design-district-creative-firms-11-01-2024/'
          },
          {
            body: `A pink Space Theory kitchen system appears in New York Magazine’s Curbed in a story about a repurposed water tower apartment in the Bronx—home to former Guggenheim retail chief Gigi Loizzo and her artist-turned-remodeler husband, Angel Molina. Design editor Wendy Goodman writes, “The couple admired the Henrybuilt kitchens they saw in magazines and decided on a pink Space Theory system.”`,
            author: 'New York Magazine',
            link: 'https://www.curbed.com/article/penthouse-with-yankee-stadium-view-tour.html'
          },
          {
            body: `A custom Space Theory kitchen system is featured in a Dwell “Houses We Love” story about the remodel of a storm-resistant cabin located on the Fowl River in Theodore, Alabama. “The kitchen, dining, and living spaces spill into each other and bleed into the outdoor gathering spaces to allow for easy exterior-to-interior gathering and entertaining,” said the architect.`,
            author: 'Dwell',
            link: 'https://www.dwell.com/article/fowl-river-cabin-tall-architects-black-siding-corrugated-metal-557ed13c'
          },
          {
            body: `A Dwell “Budget Breakdown” article features a Space Theory kitchen in a newly remodeled Seattle home, originally built for artist Alden Mason in 1958. “We said, ‘We don’t want to make it too busy.’ That’s why we added some walnut, but also white laminate—it’s not too much, and the white brightens the area,” homeowner Julie Shin said of the kitchen. “We definitely wanted to focus on natural materials and warmth and show total respect for this architectural legacy of the Northwest.”`,
            author: 'Dwell',
            link: 'https://www.dwell.com/article/budget-breakdown-alden-mason-house-renovation-ueda-design-studio-seattle-b22a5bc9'
          },
          {
            body: `Dwell features a Space Theory kitchen in an off-grid, completely solar-powered home in the San Juan Islands, along Washington State’s northernmost coast. The kitchen was part of a greater effort to renovate the “neglected getaway” into a sustainable oasis. “The house needed its next steward,” said architect Jason F. McLennan. “It’s the ultimate recycling project when you can reuse a structure instead of tearing it down.”`,
            author: 'Dwell',
            link: 'https://www.dwell.com/article/camp-everhappy-prefab-pod-houses-mclennan-design-san-juan-islands-dc9ab347'
          },
          {
            body: `California Homes Magazine features a Space Theory kitchen in its Essential Guide to Kitchen Design: “The home—for two creatives, their family, and the ebb and flow of friends—was designed for the unpredictability of life…The kitchen needed to maximize every inch of the space and stand out in its practical job performance. Space Theory’s crafted system packs in function and flexibility.”`,
            author: 'California Homes Magazine',
            link: 'https://issuu.com/californiahomesmagazine/docs/ch23eg_0df2726b62ac5e'
          },
          {
            body: `In a Redfin article titled “19 Essential Questions to Ask a Residential Architect Before Hiring Them,” Space Theory underscores the value of prioritizing “functional design expertise” when selecting an architect. Along with expertise in appliance integration, functional design expertise ensures the architect is “taking into account how you use your kitchen and how you live into your design objective,” Space Theory says.`,
            author: 'Redfin',
            link: 'https://www.redfin.com/blog/questions-to-ask-an-architect/'
          },
          {
            body: `Domino features interior designer Hillary Rielly's functional, “black-on-black” Space Theory kitchen that is “ideal for a couple that loves to cook and entertain.” The magazine recognized Space Theory for its “flexibility with what went behind the cupboard doors and drawers.” Rielly said, “'There's all this stuff that's hidden, and that's really sealed the deal for me... You open a drawer and it's so satisfying.'” She continued, “Every day I use this kitchen, I say a mini prayer of gratitude.”`,
            author: 'Domino',
            link: 'https://www.domino.com/renovation/hillary-rielly-inform-kitchen-before-and-after/'
          },
          {
            body: `In an article titled, “What You Should Know Before Choosing Kitchen Cabinets,” The New York Times interviews Scott Hudson, the founder of Henrybuilt and Space Theory, about the advantages of a well-designed kitchen system: “'A good system automatically helps you stay organized because you don't even have to think about it,' Mr. Hudson said.” The Times featured images of a Space Theory kitchen system to exemplify its organizational benefits.`,
            author: 'The New York Times',
            link: `https://www.nytimes.com/2023/02/28/realestate/kitchen-cabinets.html`
          },
          {
            body: `“The kitchen was designed to feel true to the house,” says Lisa Day of Space Theory. “The materials hearken to those from the age of the architecture: laminate, aluminum, and touches of wood that dialogue with the structure.” The results manage to look tailored and timelessly modern—and at home.`,
            author: 'Remodelista',
            link: `https://www.remodelista.com/posts/space-theory-kitchen-system-in-1970s-a-frame/`
          },
          {
            body: `We tapped the experts at Space Theory, the sister company of Henrybuilt, to see what's cooking in the kitchen, and its team shared it's all about utilizing next-level planning software.`,
            author: 'Domino',
            link: `https://www.domino.com/renovation/renovation-trends-for-2023/`
          },
          {
            body: `Dwell features a Space Theory kitchen system in an article titled, “Jens Risom’s Legacy Lives On at His Son’s Block Island Prefab.” Midcentury furniture designer Jens Risom’s son, Tom, and his wife, Pamela Austen, built off of the late designer’s expertise, while incorporating “60 years of technology” into the design and making of the home. The Risoms selected a Space Theory kitchen system for the house, which Tom and carpenter Chris Mascola installed themselves.`,
            author: 'Dwell',
            link: `https://www.dwell.com/article/the-risom-home-prefab-go-logic-c33169f7`
          },
          {
            body: `Open kitchen shelving has been done before, but what about an open island? Domino writes, “While most kitchen islands these days tend to look like monolithic blocks, Space Theory's first stand-alone piece, dubbed Daylight, is breezy.”`,
            author: 'Domino Magazine',
            link: `https://www.domino.com/content/open-shelving-kitchen-island-space-theory/`
          },
          {
            body: `Space Theory's Daylight Island is at the forefront of flexible and innovative design. SURFACE writes, "With Henrybuilt sister company Space Theory's new Daylight Island, the open-concept kitchen has reached its zenith...Daylight uses a relatively simple base—a 24-inch grid of welded steel beams—and delivers a multitude of customizable storage options that are both flexible and beautiful.”`,
            author: 'SURFACE Magazine',
            link: `https://www.surfacemag.com/articles/the-list-new-notable-february-22-202/`
          },
          {
            body: `Space Theory's user-friendly technology, the Design Engine, is at the forefront of Henrybuilt's innovative approach to design. LUXE writes, "Just imagine if designing a custom kitchen was as simple as a drag-and-drop website. Space Theory, by the founders of Seattle-based Henrybuilt, has a solution that's as close as one can get."`,
            author: 'LUXE Magazine',
            link: `https://luxesource.com/space-theory-seattle-create-kitchen-drag-drop`
          },
          {
            body: `The Vancouver Sun talks to Space Theory about finding the right kitchen fit. "Performance kitchens are all about being comfortable to work and socialize in" - Scott Hudson, founder and CEO,  Space Theory.`,
            author: 'Vancouver Sun',
            link: `https://vancouversun.com/homes/decorating/the-home-front-getting-the-right-kitchen-fit`
          },
          {
            body: `Business of Home takes a look at Space Theory's planning software, the Design Engine. “Our motivation... was to gain efficiency, eliminate mistakes, and put the power in the user’s hand,” says Scott Hudson…`,
            author: 'Business of Home',
            link: `https://businessofhome.com/boh/article/could-a-digital-design-tool-change-the-way-we-buy-kitchens`
          },
          {
            body: `Architects Jody Beck and Ross-Alan Tisdale renovate historic Dundee House as a Work/Home hybrid with the help of Space Theory. “I love the combination of the minimalist cabinets with the historic window casings and moldings".`,
            author: 'Architectural Digest Clever',
            link: `https://www.architecturaldigest.com/story/dundee-house-tampa-florida`
          },
          {
            body: `An upper west side apartment is transformed for art enthusiasts by architecture firm General Assembly and Space Theory... seen here with a Gray Oak Space Theory kitchen system.`,
            author: 'Interior Design Magazine',
            link: `http://m.interiordesign.net/projects/17978-general-assembly-designs-upper-west-side-apartment-for-art-enthusiasts/`
          },
          {
            body: `A Brooklyn Bed-Stuy is transformed into a family-friendly home by Architect Hormuz Batliboi with the help of Space Theory. The kitchen's central location creates a gathering place in the home anchored by a natural walnut, monolithic 12' island.`,
            author: 'Interior Design Magazine',
            link: `https://www.interiordesign.net/projects/17192-architect-hormuz-batliboi-transforms-an-empty-bed-stuy-brownstone-into-a-family-friendly-triplex/`
          },
          {
            body: `Apartment Therapy covers the launch of Space Theory, a new, more affordable kitchen system with the craft and quality of Henrybuilt. What a Space Theory kitchen gives you is "the benefits of the system, such as the inter-relationships between different components that can be moved around the kitchen."`,
            author: 'Apartment Therapy',
            link: `https://www.apartmenttherapy.com/semi-custom-kitchen-remodel-space-theory-36641087`
          },
          {
            body: `The Hollywood Reporter Roundup features Space Theory's Opencase Backsplash System to customize and revamp the kitchen.`,
            author: 'Hollywood Reporter',
            link: `https://www.hollywoodreporter.com/news/8-pieces-revamp-any-hollywood-kitchen-1219134`
          },
          {
            body: `Henrybuilt launches a new, more affordable kitchen system, Space Theory, supported by proprietary design software that gives control and transparency to the user. "Most importantly, Space Theory invites people to customize their own systems by using proprietary software".`,
            author: 'Gray Magazine',
            link: `https://spacetheory-public.s3.amazonaws.com/website/files/press/Gray_June_2019.pdf`
          },
          {
            body: `Space Theory, Henrybuilt's sister company, is spotlighted in Interior Design for its new Seattle showroom within the Henrybuilt headquarters. The reporter Carlene Olsen writes, "The showroom, located in Seattle's Georgetown neighborhood, has a minimalist aesthetic that spotlights functionality, enabling users to touch and move pieces as if at home." The Design Engine, the company's internally developed software, is credited for Space Theory's 30% reduction in cost compared to Henrybuilt product.`,
            author: 'Interior Design Magazine',
            link: 'https://interiordesign.net/designwire/henrybuilt-sister-company-space-theory-opens-first-showroom-in-seattle/'
          },
          {
            body: `"People are craving more control over how kitchens are designed and how they can create more order in their homes. We can probably thank Marie Kondo for that," notes Henrybuilt's Chief Marketing Officer Tim Fry in a Hollywood Reporter story that charts kitchen trends among the Hollywood set. Fry is on hand to introduce readers to Space Theory, Henrybuilt's sister company that celebrates relaxed order in the kitchen.`,
            author: 'Hollywood Reporter',
            link: 'https://www.hollywoodreporter.com/movies/movie-news/hollywood-home-designers-talk-kitchen-trends-new-upgrades-1220648/'
          },
          {
            body: `Henrybuilt's launch of sister company Space Theory earns coverage on the California Home + Design website, where the publication addresses everything from the creation of the Design Engine software behind it, to the variability of the kitchen systems on offer, to the hours of the Seattle showroom where Space Theory is first being promoted.
            `,
            author: 'California Home + Design',
            link: 'https://www.californiahomedesign.com/trending/2019/05/08/henrybuilt-launches-space-theory/#:~:text=Innovative%20kitchen%20company%20Henrybuilt%20known,place%20within%20the%20kitchen%20system.'
          },
          {
            body: `Business of Home announces the launch of Henrybuilt's sister company, Space Theory. In the article, Henrybuilt founder Scott Hudson shares his ambition to reach a broader audience with the new company, which thanks to internally developed Design Engine software, offers clients performance kitchens at a reduced cost.`,
            author: 'Business of Home',
            link: 'https://businessofhome.com/articles/the-founder-of-henrybuilt-wants-to-democratize-custom-kitchen-design#:~:text=The%20founder%20of%20Henrybuilt%20wants%20to%20democratize%20custom%20kitchen%20design,-on%20the%20beat&text=If%20IKEA%20is%20at%20one,Historically%2C%20not%20much.'
          }
        ];

        return (
          <div className='press'>
            {/* <div className='press-page-title'>Press</div> */}
            <div className='press-quotes'>
              {_.map(quotes, (quote, q) => <Text key={q} quote={quote}/>)}
            </div>
            <div className='press-page-footer'>
              If you are a member of the press and interested in writing about Space Theory, please contact us at <a className='email' target="_blank" rel="noopener noreferrer" href='mailto:press@spacetheory.com'>press@spacetheory.com</a>
            </div>
          </div>
        );
      }
    },
  ]
};
