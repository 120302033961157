import {_, React, Component} from 'components';
import {Link, Redirect} from 'react-router-dom';
import Cookies from 'js-cookie';
import './register-employee.scss';

export default class RegisterEmployee extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!Cookies.get('isEmployee')) {
      Cookies.set('isEmployee', 'true');
    }

    setTimeout(() => {
      this.shouldRedirect = true;
      this.forceUpdate();
    }, 5000);
  }

  render() {
    var shouldRedirect = this.shouldRedirect && !this.didRedirect;

    if (shouldRedirect) this.didRedirect = true;

    return shouldRedirect ? (
      <Redirect push to='/' />
    ) : (
      <div className='register-employee'>
        <div className='register-employee-content-container'>
          <div className='register-employee-message'>You have been registered as an employee</div>
          <div className='redirect-message'>
            If you are not redirected in 10 seconds, please <Link className='click-here-link' to={'/'}>click here</Link>
          </div>
        </div>
      </div>
    );
  }
}
