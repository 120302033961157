import {React} from 'components';
import {link, colors} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'a-complete-system',
  title: 'A Complete System',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'A Complete System'},
          `The concept of a “kitchen system” is not widely understood in North America. The status quo is a “parts” approach that requires putting together components (cabinets, fronts, pulls, counters, lighting, etc.) from various sources and working hard to ensure they come together well, which rarely happens. A system approach is the new way. It provides a whole solution, with the refinement of detail and unique, built-in functionality that only results from a holistic design approach.`
        ]
      },
      media: [{orientation: 'square', src: link('simple/simple-1.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'System does not mean modular'},
          `Each Space Theory kitchen is designed and built for a custom fit. Units are custom made to order to the ¼ inch. System does mean: a holistic approach to design—and in our case, that the inside is just as good and well considered as the outside.`
        ]
      },
      media: [{orientation: 'square', src: link('simple/simple-2.jpg')}]
    },
    {
      layout: 'media',
      media: [{orientation: 'square', src: link('simple/simple-3.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Completeness'},
          `Our focus is on delivering a complete package that assures quality, refinement, and high function. Our system is set up to give you that. The product range includes cabinets, interchangeable functional accessories, pulls, countertops, backsplash—and even the kitchen sink. But you also have the option to purchase only what you need. You can provide your own countertop in any material, for example. The system is not a limitation.`
        ]
      },
      media: [{orientation: 'square', src: link('simple/simple-4.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Unique to you'},
          `Our system approach fuels innovation in terms of function and flexibility. No matter how good a kitchen looks—and we think they should look great—what matters more is how you feel working and socializing in it. Today, order, relaxed movement, and flexibility in our homes means more than ever. Space Theory gives you that with, for example, our interior components and flexible Opencase wall panel systems.`
        ]
      },
      media: [{orientation: 'square', src: link('simple/simple-5.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {render: () => (
            <div>
              A Space Theory kitchen provides a completely custom fit, in ¼ inch increments. It also gives you a way to achieve a refined integration of appliances, which is one of the key factors—and one of the trickiest—in creating a quality design outcome. It provides an architecturally integrated and resolved outcome that will increase the value of your home in a lasting way.
            </div>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('simple/simple-6.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Our dream for you is to realize that achieving a high-quality, refined, and super-functional kitchen is no longer such a daunting task. And, when you get it, you’re amazed at how good it feels to use your kitchen.`
        ]
      },
      media: [{orientation: 'square', src: link('simple/simple-7.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Straightforward to install'},
          {render: () => (
            <div>
              At the final stage of the process of creating a new kitchen is installation. Construction is always challenging, and there may be surprises. However, built on the experience of thousands of kitchens completed by our sister company <a className='no-underline blue' href='https://www.henrybuilt.com/' target='_blank'>Henrybuilt</a>, we have made Space Theory straightforward to install. To learn more about our process, <Link className='no-underline blue' to={'/page/our-process'}>click here</Link>.
            </div>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('simple/simple-8.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `All Henrybuilt companies, including Space Theory, are involved in a project we call “Self-Installing Product.” We aren’t sending robots to your home (yet), but we have integrated instruction into the product itself, and we’ve designed interlocking and indexing features that make installation particularly straightforward.`
          // {render: () => (
          //   <Link className='text-item-link next-page' to={'/page/functional-innovation'} style={{position: 'absolute', bottom:'10em'}}>
          //     Functional Innovation ⟶
          //   </Link>
          // )},
        ]
      }
    },
  ]
};
