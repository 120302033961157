import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'kitchen-chameleon',
  title: 'Kitchen Chameleon',
  sections: [
    {
      layout: 'mediaTopTextBottom',
      media: [{
        orientation: 'horizontal',
        src: link('kitchen-chameleon/kitchen-chameleon.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>Kitchen Chameleon</div>
              <div className='project-story-location'>Aesthetic adaptations for a workshop that's all yours.</div>
            </div>
          )
        }
      }],
      text: {
        items: [
          {render: () => (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              <div>Image ©Yoshihiro Makino</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `From practical workshop to personal atelier.`, 
          `Space Theory kitchens are finely tuned workshops and ideal socializing spaces designed to help you concentrate your time and focus on what you enjoy most. Responsive to changing expectations, adaptive to their surroundings, and accommodating to your unique expression.`,
          `Aesthetic adaptability means you can dress your kitchen up or down, give it a serious and minimal stance, a more casual and playful pose, or something in between. At the same time, functional innovation - like tools that have resting spots reserved both on the backsplash and in the drawer - creates an intuitive, free flowing everyday experience to meet our changing needs. Personal from inside out.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          {className: 'sub-heading', text: `Tampa, Florida`},
          `Architecture by Jody Beck and Ross-Alan Tisdale, Traction Architecture`,
          `Photography: Seamus Payne`,
          {render: () => (
            <Link className='text-item-link' to={'/page/a-modern-interpretation'}>
              Read the project story
            </Link>
          )}
        ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/1.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          {className: 'sub-heading', text: `San Jose, California`},
          `Builder: Mayberry Workshop`,
          `Photography: Jean Bai `
        ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/2.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          {className: 'sub-heading', text: `Normandy Park, Washington`},
          `Builder: David Dunican`
        ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/3.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          {className: 'sub-heading', text: `Venice, California`},
          `Architecture & Design: Design, Bitches`,
          `Builder: Oliver Garrett Construction`,
          `Image: ©Yoshihiro Makino`
        ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/7.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          `Our grid based panel system, Opencase, adds unique function and flexibility to areas such as a pantry, drop zone, bar area or backsplash.`
        ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/5.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          {className: 'sub-heading', text: `Brooklyn, New York`},
          `Architecture: Solk Architecture`,
          `Builder: Jim Valouch`,
          `Photographer: Rachael Stollar`
        ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/6.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
            {className: 'sub-heading', text: `Orcas Island, Washington`},
            `Architecture: Heliotrope Architects`,
            `Contractor: Tye Contracting`,
            {render: () => (
              <Link className='text-item-link' to={'/page/understory'}>
                Read the project story
              </Link>
            )}        
          ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/4.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          `Space Theory's flexible wood and stainless drawer organization tools are designed on a grid so you can change the layout to your own taste.`
        ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/8.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          {className: 'sub-heading', text: `Williamstown, Massachusetts`},
          `Architecture: Group AU`,
          `Contractor: Dan Barenski`,
          `Photography: Jamie Goldenberg`,
          {render: () => (
            <Link className='text-item-link' to={'/page/stripped-down-suburban'}>
              Read the project story
            </Link>
          )}  
        ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/9.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          {className: 'sub-heading', text: `Brooklyn, New York`},
          `Architecture by Walker Smith-Williams and Billy Erhard`,
          {render: () => (
            <Link className='text-item-link' to={'/page/addition-by-subtraction'}>
              Read the project story
            </Link>
          )} 
        ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/10.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          `Growing material options include a soft-touch laminate finish with a color-matched edge that gives the look of paint with far greater durability for an all-white kitchen that lasts.`
        ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/11.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          {className: 'sub-heading', text: `Springs, New York`},
          {render: () => (
            <Link className='text-item-link' to={'/page/creative-assets'}>
              Read the project story
            </Link>
          )} 
        ]
      },
      media: [
        {orientation: 'square', src: link('kitchen-chameleon/12.jpg')}
      ]
    },
    {
      layout: 'text',
      text: {
        items: [
          {className: 'sub-heading', text: 'These are Space Theory kitchens.'},
          {render: () => (
            <div className='long-paragraph' style={{width: '100%'}}>
              We work with homeowners who don't have an architect. <b>We love that</b>. We work with architect, homeowner, designer teams. <b>We love that</b>. And quite often, we work with architects or interior designers who are the homeowner. <b>We love that, too</b>.
            </div>
          )},
          {render: ({showInquiryForm}) => (
            <a className='text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get started on your own Space Theory Kitchen
            </a>
          )},
        ]
      }
    }
  ]
};
