import {_, React, Component} from 'components';
import Form from 'lib/form/form';
import Cookies from 'js-cookie';
import './subscribe-form.scss';

export default class SubscribeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      showThankYouMessage: false
    };
  }

  render() {
    return (
      <div className='subscribe-form theme-light'>
        {this.state.showThankYouMessage ? (
          <div className='thank-you-message'>Thank you.</div>
        ) : (
          <Form action='https://www.weflowapp.com/w2e/post' method='post'>
            <Form.TextInput name={5995} id={5995} placeholder={'email'} value={this.state.email} isRequired={true}/>
            <Form.SubmitButton submitText='→'/>
            <Form.HiddenInput name={6328} id={6328} value={3}/> {/* Subscribed To: ST */}
            <Form.HiddenInput name={10150} id={10150} value={3}/> {/* Source: st.com */}
            <Form.HiddenInput name={12864} id={12864} value={5}/> {/* Subscription Type: Projects */}
            <Form.HiddenInput name='w2e_id' id='w2e_id' value={137}/>
            <Form.HiddenInput name='section_id' id='section_id' value={646}/>
            <Form.HiddenInput name='grp_id' id='grp_id' value={1}/>
          </Form>
        )}
      </div>
    );
  }
}
