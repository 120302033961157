import {_, Component, React, className} from 'components'; //eslint-disable-line
import './accordion.scss';
import {Link} from 'react-router-dom';


class Accordion extends Component {
  static propTypes = {

  }

  constructor(props) {
    super(props);

    this.state = {expandedSectionIndex: null};

    var {defaultExpandedSectionIndex} = this.props;

    if (defaultExpandedSectionIndex <= 0) this.state.expandedSectionIndex = defaultExpandedSectionIndex;
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  handleTitleClick({index}) {
    if (index === this.state.expandedSectionIndex) index = null;

    this.setState({expandedSectionIndex: index});

    this.props.onChange({index});
  }

  render() {
    var description = _.get(this.props, 'accordionDescription');
    var nextPage = _.get(this.props, 'nextPage');

    return (
      <div className='accordion'>
        {this.props.accordionTitle && (
          <div className='accordion-title'>{this.props.accordionTitle}</div>
        )}
        {description && (
          <div className='accordion-description'>
            {description && description.render ? description.render() : description}
          </div>
        )}
        {_.map(this.props.accordionSections, (section, index) => {
            return (
              <div {...className ([
                'accordion-section',
                index === this.state.expandedSectionIndex && 'is-expanded',
              ])}
              key={index}>
                <div className='accordion-section-title' onClick={() => this.handleTitleClick({index})}>
                  {section.title}
                </div>
                <div className='accordion-section-content'>
                  {_.map(section.text.items, (item, index) => {
                    var content;

                    if (item.render) {
                      content = item.render({showInquiryForm: this.props.showInquiryForm});
                    }
                    else {
                      content = _.trim(_.get(item, 'text', item));
                    }

                    return (
                      <div className='accordion-text-item' key={index}>{content}</div>
                    );
                  })}
                </div>
              </div>
            );
        })}
        {nextPage && (
          <div className='next-page-container'>
            <Link className='accordion-text-item-link next-page' to={`/page/${nextPage}`} >
              {this.props.nextPageTitle}⟶
            </Link>
          </div>
        )}
      </div>

    );
  }
}

export default Accordion;
