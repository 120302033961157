import {React} from 'components';
import {link, colors} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'our-process',
  title: 'Our Process',
  sections: [
    {
      layout: 'accordionWithMedia',
      heightAuto: true,
      defaultMedia: [{orientation: 'square', src: link('our-process/our-process-1.jpg')}],
      accordionTitle: 'Our Process',
      accordionDescription: 'We’ve created a streamlined approach to make it easier to achieve a high quality, refined and super functional kitchen just for you. Our experts guide you through each step from refining your specific layout to coordinating with your installer...and we move as quickly as you need us to.',
      accordionSections: [
         {
            title: 'PLAN',
            text: {
              items: [
                {render: ({showInquiryForm}) => (
                  <div>
                    <span className='accordion-text-item-link blue' onClick={() => showInquiryForm({contactReason: 'project'})}>Schedule a call</span> with an expert to walk through your drawings, answer questions and get a quote.
                  </div>)
                },
                {render: () => (
                  <div>
                    Want to get a sense of budget on your own? Our pricing is available <a className='accordion-text-item-link blue' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-pricelist.pdf' target='_blank'>here</a>. You can also see pre-designed layouts with pricing <Link className='accordion-text-item-link blue' to={'/page/pricing-with-examples'}>here</Link>.
                  </div>)
                },
                `Once you determine Space Theory is a fit for your project, secure your spot by placing your deposit and proceed to the design phase.`
              ]
            },
            media: [{orientation: 'square', src: link('our-process/our-process-2.jpg')}]
         },
         {
           title: 'REFINE',
           text: {
             items: [
               'Our design experts will help guide you through the key decisions to create a kitchen especially for you. ',
               {render: () => (
                 <div>
                   <b>Session 1:</b> Refine and customize your specific layout and products. Decide appliance and material direction.
                 </div>)
               },
               {render: () => (
                 <div>
                   <b>Session 2:</b> Finalize materials and appliance models. Confirm key dimensions. Make any adjustments to your layout and product choices.
                 </div>)
               },
               {render: () => (
                 <div>
                   <b>Session 3:</b> Once every detail is correct, approve your design to start production.
                 </div>)
               },
             ]
           },
           media: [{orientation: 'square', src: link('our-process/our-process-3.jpg')}]
         },
         {
           title: 'ORDER',
           text: {
             items: [
               'Once you have approved your design, your project is built to order in our production facility in Seattle.',
               'Once complete, our logistics team gets the product safely to you, where it will be uncrated and carried piece by piece into your home. The crate parts will be returned to us and reused.'
             ]
           },
           media: [{orientation: 'square', src: link('our-process/our-process-4.jpg')}]
         },
         {
           title: 'INSTALL',
           text: {
             items: [
               'Components ship fully assembled. We provide straightforward installation drawings for your installer to clarify layout. Any information required to install a specific product is on the product itself. We provide pre-drilled holes with connectors, indexing jigs, and online and telephone support if you need it.',
               'We also provide information on preparing your construction site for installation.'
             ]
           },
           media: [{orientation: 'square', src: link('our-process/our-process-5.jpg')}]
         }
       ],
      //  nextPage: 'design-engine',
      //  nextPageTitle: 'Design Engine'
    }
  ]
}
