import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'new-york-announcement',

  title: 'New York Showroom',

  sections: [

    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'heading', text:  `SPACE THEORY NEW YORK`},
          {className: 'smaller-text-space-after', text:  `Introducing Space Theory New York. Now you can experience Space Theory in three locations from West (Seattle and Los Angeles) to East. Located on West Broadway in Soho, it’s a place to experience the functional innovations of Space Theory kitchens. The showroom celebrates the ease and pleasure of cooking, socializing, and living with Space Theory’s integrated kitchen system. `},
          {className: 'smaller-text-space-after', text:  `From the founders of Henrybuilt, Space Theory offers a more custom solution than other systems, using proprietary design software and creative manufacturing to develop innovations that go beyond ‘cabinets’. A crafted kitchen system that's been distilled to its minimal essence, while being customizable to any space. `},

          {className: 'smaller-text', text:  `355 West Broadway | newyork@spacetheory.com | 646.454.9696`},
          {className: 'smaller-text', text:  `Monday - Friday by appointment`}
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('new-york-announcement/1.jpg')
      }]
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('new-york-announcement/2.jpg')
      }]
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('new-york-announcement/4.jpg')
      }]
    },    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('new-york-announcement/triptic1.jpg')
      }]
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('new-york-announcement/3.jpg')
      }]
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('new-york-announcement/triptic2.jpg')
      }]
    },
        {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('new-york-announcement/7.jpg')
      }]
    },
  ]
};
