import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'the-nonconformist',
  title: 'The Nonconformist',
  sections: [
    {
      layout: 'mediaTopTextBottom',
      media: [{
        orientation: 'horizontal',
        src: link('the-nonconformist/1.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>The Nonconformist</div>
            </div>
          )
        }
      }],
      text: {
        items: [
          {render: () => (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text-space-after', text: `Other than replacing the precipitous brick foundation, little about this beach bungalow project was conventional. The home -  for two creatives, their family, and the ebb and flow of friends – was designed for the unpredictability of life. Flexibility was the keynote. Architect Catherine Johnson created a space adaptable to working from home, accommodating rotating guests, and shifting zones from communal to private. Not just for the present, but for the longevity of living in the home. `},
          {className: 'smaller-text-space-after', text: `The principles of flexibility and adaptability were also at play in the choice to integrate a Space Theory kitchen system into the project. “A lot is happening in the wall of the kitchen, architecturally and practically,” says Johnson. “It’s the backdrop to life.” It was vital that the kitchen maximize every inch of the space and stand out in its practical job performance. Space Theory’s crafted system that packs-in function and flexibility gave the team what it was after.`},
          {className: 'sub-heading', text:
          `Venice, California
          Architecture & Design by Design, Bitches
          Builder: Oliver Garrett Construction
          Photography: Yoshihiro Makino`}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('the-nonconformist/6.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text', text: `The interiors are a kaleidoscope of color and texture. Subtle shifts in tones reflect the seasonal change in Southern California light. Pervasive blues and greens, interlaced with natural earthen materials like brick and hemlock, are rooted by the dark line of the kitchen wall, a mix of deep blue and black (what used to be a fashion ”no-no”). The appliances are allowed to be seen, not hidden. Stainless steel, Space Theory’s dark technical materials, wood siding, and the character-rich copper hood and backsplash are an intentional patchwork of materials that convey a symbiotic relationship.
          `},
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('the-nonconformist/2.jpg')}],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          `A dynamic and open area, the kitchen and the living room merge and perfectly coexist, creating a sense of ease and increased space. Call it a ‘living kitchen’ for working, surfing, playing, eating, conversing, and, above all, sharing. `
        ]
      },
      media: [
        {orientation: 'square', src: link('the-nonconformist/3.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {type: 'sub-heading', text: `Flexible Organization`},
          `The tools you need to create the organization you want. Space Theory's modular wood and stainless steel drawer inserts keep your tools at hand, while enabling you to change the layout to suit your needs and taste.`,
          {render: () => (
            <a className='text-item-link blue' href='/page/flexible-organization' target='_blank'>
              Learn more
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('the-nonconformist/4.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          `The result is void of ostentation and full of personality. It’s a kitchen rooted in the extremely practical, and yet at the same time feels slightly irreverent and exceedingly stylish.`,
          `“Space Theory was attentive to every detail and making the most of what we had to work with,” says Johnson.`
        ]
      },
      media: [
        {orientation: 'horizontal', src: link('the-nonconformist/5.jpg')}
      ]
    },

    {
      layout: 'text',
      text: {
        items: [
          {className: 'sub-heading', text: 'These are Space Theory kitchens.'},
          {render: () => (
            <div className='long-paragraph' style={{width: '100%'}}>
              We work with homeowners who don't have an architect. <b>We love that</b>. We work with architect, homeowner, designer teams. <b>We love that</b>. And quite often, we work with architects or interior designers who are the homeowner. <b>We love that, too</b>.
            </div>
          )},
          {render: ({showInquiryForm}) => (
            <a className='text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get started on your own Space Theory Kitchen
            </a>
          )},
        ]
      }
    }
  ]
};
