import {link} from './helpers';
import {React} from 'components';

// istanbul ignore file

export default {
  key: 'inc-5000-award',
  title: 'Inc. 5000 Award',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'heading', text:  `Inc. 5000 Award`},
          {className: 'smaller-text-space-after', text: `Space Theory ranked among the top 500 and in the top 4% of Inc. magazine's fastest-growing private companies in the United States. Space Theory was one of the top two manufacturing companies in the U.S. to receive the award, which is ranked according to percentage revenue growth from 2019 to 2022. This year, Space Theory joins the ranks of Facebook, Microsoft, Patagonia, and others, having grown by more than 2,500% between 2019 and 2022.`},
          {className: 'smaller-text-space-after', text: `“The world is changing—becoming less predictable—and to succeed we all need to build resilience and shift the forces around us to the positive. That's what we've done at Space Theory: we've delivered on unique outcomes, prioritizing quality, simplicity, and adaptability, not only for our clients but within the company despite an influx of challenges. The integrity of the product depends on the integrity of the company, and we've positioned ourselves to support both in the long and short term.”`},
          {className: 'smaller-text-space-after', text: `Founded in 2019 by Scott Hudson, the CEO and Creative Director of Henrybuilt, Space Theory was created to deliver the same high-quality kitchen systems at a more accessible price. Space Theory shares Henrybuilt's core values but carefully streamlines its product offerings, dials in on kitchens instead of the whole home, and utilizes original, state-of-the-art software to support a smooth flow of planning and production in the design and manufacturing processes. As a result, Space Theory is able to design and configure highly functional, architecturally integrated kitchen systems efficiently, without mistakes or hold-ups. The result is a fast-growing company that brings the quality, flexibility, and refinement of Henrybuilt's European-inspired kitchen systems to a wider audience.`}
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('inc-5000-award/1.jpg')
      }]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text-space-after', text: `Subscribe to get the latest news and updates from Space Theory.`},
          {render: () => (
            <div className='subscribe-form-container'>
              <iframe src='/subscribe-form' title='subscribe-form'></iframe>
            </div>
          )}
        ]
      }
    }
  ]
};
