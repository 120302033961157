import {link} from './helpers';
import _ from 'lodash';

// istanbul ignore file

export default {
  key: 'portfolio',
  title: 'Portfolio',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'Portfolio'},
          `Space Theory's minimalist kitchen system provides a foundation of quality and flexibility that can support any design objective.`
        ]
      }
    },
    ..._.times(32, (index) => {
      return {
        layout: 'media',
        maxHeight: '80%',
        media: [{orientation: 'horizontal', src: link(`portfolio/${index + 1}.jpg`)}]
      };
    })
  ]
};
