import {_, Component, React, className, axios} from 'components'; //eslint-disable-line
import {useState, useCallback} from 'react';
import './grid.scss';
import Constraints from '../../../constraints/constraints';
import Slideshow from '../../../slideshow/slideshow';
import PropTypes from 'prop-types';

var CellThumbnail = ({cell, cellSizeMode, hasBorder, ...props}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleLoad = useCallback((event) => {
    if (cellSizeMode === 'auto') {
      event.target.width *= 0.4;
    }

    setIsLoaded(true);

    props.handleLoad();
  }, [cellSizeMode, props]);

  const handleError = useCallback(() => {
    setHasError(true);

    props.handleLoad();
  }, [props]);

  return (
    <div {...className(['cell-thumbnail', isLoaded && 'is-loaded', hasError && 'has-error', hasBorder && 'has-border'])}>
      <img src={cell.thumbnailSrc} alt={cell.title} onLoad={handleLoad} onError={handleError}/>
    </div>
  );
};

class Grid extends Component {
  static propTypes = {
    cellGroups: PropTypes.array.isRequired,
    cellSizeMode: PropTypes.oneOf(['small', 'medium', 'large', 'auto']).isRequired
  }

  state = {activeCell: null, loadedCellCount: 0};

  constructor(props) {
    super(props);

    this.bind(['handleCellClick', 'handleDetailsClick', 'hideDetails', 'handleCellThumbnailLoad']);
  }

  hideDetails() {
    this.setState({detailsAreVisible: false});

    //istanbul ignore next
    setTimeout(() => this.setState({activeCell: null}), 500);
  }

  async handleCellClick({cell}) {
    if (process.env.NODE_ENV !== 'test') {
      var response = await axios.post('https://api.henrybuilt.com/resources', {
        resources: {get: {
          media: {where: {productId: cell.ids || cell.id, public: 1}}
        }}
      });

      if (response.status === 200 && response.data.success) {
        var resources = {};
        var {media} = response.data.data.resources.get;

        if (media) {
          resources.media = _.map(media, medium => {
            var sizeModeMap = {
              'image': 'cover',
              'vimage': 'contain',
              'video': 'cover'
            };

            return {
              sizeMode: sizeModeMap[medium.type],
              src: medium.url,
              ...medium
            };
          });
        }

        cell.details = {...cell.details, ...resources};
      }
    }

    this.setState({activeCell: cell, detailsAreVisible: true});
  }

  handleDetailsClick(event) {
    if (event.target.className === 'details-background') {
      this.hideDetails();
    }
  }

  handleCellThumbnailLoad() {
    this.setState(state => ({loadedCellCount: state.loadedCellCount + 1}));
  }

  render() {
    var cell = this.state.activeCell;
    var allCellsAreLoaded = this.cellCount === this.state.loadedCellCount;
    var cellIndexInGrid = 0;

    return (
      <div {...className([
        'page-section-grid',
        `cell-size-mode-${_.kebabCase(this.props.cellSizeMode)}`,
        allCellsAreLoaded && 'all-cells-are-loaded'
      ])}>
        {cell && (
          <div {...className(['grid-cell-details', this.state.detailsAreVisible && 'is-visible'])}>
            <div className='details-background' onClick={this.handleDetailsClick}>
              <div className='details-pane'>
                <div className='details-header'>
                  <div className='cell-title'>{cell.title}</div>
                  <div className='cell-close-details' onClick={this.hideDetails}></div>
                </div>
                <div className='details-content'>
                  {cell.details.constraints && (
                    <div className='constraints-container'>
                      <Constraints constraints={cell.details.constraints}/>
                    </div>
                  )}
                  {cell.details.notes && (
                    <ul className='details-notes'>
                      {_.map(cell.details.notes, (note, n) => (
                        <li className='details-note' key={n}>{note}</li>
                      ))}
                    </ul>
                  )}
                </div>
                {cell.details.media && cell.details.media.length > 0 && (
                  <div className='details-slideshow'>
                    <Slideshow media={cell.details.media}/>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className='cell-groups'>
          {_.map(this.props.cellGroups, (cellGroup, cg) => (
            <div className='cell-group' key={cg}>
              {(cellGroup.title && cellGroup.showGroupTitle) && (
                <div
                  className='cell-group-title'
                  style={{transition: `opacity 0.5s 0.1s`}}
                >
                  {cellGroup.title}
                </div>
              )}
              <div className='cell-group-cells'>
                {_.map(cellGroup.cells, (cell, c) => {
                  var hasDetails = cell.details && (cell.details.media !== undefined || cell.details.constraints !== undefined);
                  var props = {};
                  // var borderFor = ['Chalk White', 'Precision White', 'Smoke White', 'Ash Gray', 'Porcelain', 'Ivory', 'Alpine White'];
                  // var hasBorder = _.includes(borderFor, cell.title);
                  var hasBorder = true;

                  if (hasDetails) props.onClick = () => this.handleCellClick({cell});

                  cellIndexInGrid += 1;

                  return (
                    <div
                      {...className(['grid-cell', hasDetails && 'has-details'])}
                      key={c}
                      style={{transition: `opacity 0.5s ${(cellIndexInGrid - 1)*0.1}s`}}
                      {...props}
                    >
                      <CellThumbnail cell={cell} cellSizeMode={this.props.cellSizeMode} hasBorder={hasBorder} handleLoad={this.handleCellThumbnailLoad}/>
                      <div className='cell-title'>{cell.title}</div>
                      {cell.description && (<div className='cell-description'>{cell.description}</div>)}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  get cellCount() {
    return _.sum(_.map(this.props.cellGroups, group => group.cells.length));
  }
}

export default Grid;
