import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'an-architects-island-office',
  title: `An Architect's Island Office`,
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'heading', text: `An Architect's Island Office`},
          {className: 'sub-heading', text: `Bainbridge Island, Washington`},
          {className: 'smaller-text', render: () => (
            <div>
              <div>Architecture: McLennan Design/Perkins & Will</div>
              <div>Contractor: Brant Moore Construction</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('an-architects-island-office/1.jpg')
      }],
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('an-architects-island-office/2.jpg')
      }],
    },
    {
      layout: 'doubleMedia',
      media: [
        {
          orientation: 'square',
          src: link('an-architects-island-office/3.jpg')
        },
        {
          orientation: 'square',
          src: link('an-architects-island-office/4.jpg')
        }
      ]
    },
    {
      layout: 'doubleMedia',
      media: [
        {
          orientation: 'square',
          src: link('an-architects-island-office/5.jpg')
        },
        {
          orientation: 'square',
          src: link('an-architects-island-office/6.jpg')
        }
      ]
    }
  ]
};
