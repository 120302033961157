import {_, React, Component, className} from 'components';
import {Link} from 'react-router-dom';

import './landing-page.scss';

export default class TextContainer extends Component {
  constructor(props) {
    super(props);

    this.textContainerRef = React.createRef();
    this.state = {scrollTop: 0, windowHeight: window.innerHeight, windowWidth: window.innerWidth};

    this.bind(['handleResize']);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({windowHeight: window.innerHeight, windowWidth: window.innerWidth});
  }

  render() {
    var {windowHeight, windowWidth} = this.state;
    var opacity = 1;

    if (this.textContainerRef.current) {
      var offsetTop = this.textContainerRef.current.getBoundingClientRect().top;

      if (offsetTop > 0 && offsetTop < windowHeight) { //HINT only calculate opacity when text is visible
        if (windowWidth >= 801) { //wide screen
          opacity = 1 - (offsetTop / (windowHeight / 2));
        }
        else { //narrow screen
          opacity = (windowHeight/ offsetTop) - 1;
        }

        if (opacity >= 1) opacity = 1;
      }
    }

    return (
      <div className='text-container' ref={this.textContainerRef} style={{opacity}}>
        {_.map(this.props.items, (item, i) => (
          <div key={i} {...className(['text-item', item.className || ''])}>
            {item.render && (item.render())}
            {item.type === 'link' && (<Link to={item.linkTo}>{item.text}</Link>)}
            {(item.type !== 'link' && !item.render) && (<>{item.text}</>)}
          </div>
        ))}
      </div>
    );
  }
}
