import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'modern-history',
  title: 'Modern History',
  sections: [
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('modern-history/hero.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>Modern History</div>
            </div>
          )
        }
      }]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'larger-text-space-after', text: `“To ensure a high level of quality craftmanship and functionality, we selected Space Theory as the foundation for the kitchen and layered on top of it. The versatility of their system enables the project to reflect the mood of the space and the personality of the clients.” - Rachel Robinson`},
          {className: 'smaller-text-space-after', text: `Architects are authors of sorts, crafting stories of place. Rachel Robinson and Michael Dunham, Founders of DURODECO, looked to create a modern narrative within the setting of the iconic El Dorado landmark overlooking Central Park. This story juggled goals seemingly at odds: enlarging the kitchen without increasing the footprint, AND embracing innovation while honoring the past.`},
          {className: 'smaller-text-space-after', text: `Square footage was captured by carving out a nook in the original foyer for a pantry and serving zone that sits adjacent to the kitchen, while finishes were selected to converse with the architecture. Space Theory’s functional innovation – including drawer interiors designed as carefully as the rest of the system - maximizes efficiency. The tools needed to create and adapt the space are designed into the system to create an intuitive, free flowing everyday experience.`},
          {render: () => (
            <div className='page-section-text-item text-item-sub-heading is-visible'>
              <div>New York City</div>
              <div>Architecture and Design by <a style={{textDecoration: 'none', color: 'rgb(119, 152, 173)'}} href='https://www.durodeco.com/' target='_blank'>DURODECO</a></div>
              <div>Photos by Nicholas Venezia</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('modern-history/1a.jpg')},
        {orientation: 'square', src: link('modern-history/1b.jpg')}
      ],
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('modern-history/2.jpg')}],
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('modern-history/3a.jpg')},
        {orientation: 'square', src: link('modern-history/3b.jpg')}
      ],
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('modern-history/4a.jpg')},
        {orientation: 'square', src: link('modern-history/4b.jpg')}
      ],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {render: ({showInquiryForm}) => (
            <a className='larger-text text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get started on your own Space Theory Kitchen
            </a>
          )}
        ]
      }
    },
  ]
};
