import {React} from 'components';
import {link, colors} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'commonly-asked-questions',
  title: 'Commonly Asked Questions',
  sections: [
    {
      layout: 'accordionWithMedia',
      heightAuto: true,
      defaultMedia: [{orientation: 'square', src: link('products-details/products-details-1.jpg')}],
      defaultExpandedSectionIndex: 0,
      accordionTitle: 'Commonly Asked Questions',
      accordionSections: [
         {
            title: 'Storage Components',
            text: {
              items: [
                {render: () => (
                  <div>
                    <b>Do you have product for other spaces in the home?</b><br/>
                    You can use our products throughout the home in areas such as your dining room, living room, laundry or entry way. A select line of coordinating vanities are available for clients with a kitchen project. Your Design Engineer will help guide you on how to use Space Theory for other areas of your home.
                  </div>)
                },
                {render: () => (
                  <div>
                    <b>Can you provide floating shelves?</b><br/>
                    Floating shelves are available in our wood veneer options in 4", 8" and 12" depths. Integrated lighting is available for 8" and 12" deep units in linear or puck LED versions.
                  </div>)
                },
                {render: () => (
                  <div>
                    <b>Can we use our own end panels and back panels for islands?</b><br/>
                    The Space Theory system flexibility makes it simple for you to use your own materials for end panels, including waterfall details or back panels.
                  </div>)
                },
                {render: () => (
                  <div>
                    <b>Can I mix Henrybuilt and Space Theory products in the same kitchen?</b><br/>
                    <a className='accordion-text-item-link blue' href='https://objects.henrybuilt.com/' target='_blank'>Henrybuilt's Primary Objects</a> line of stand-alone furniture pieces is the perfect way to combine Henrybuilt and Space Theory.  Henrybuilt options are not available on Space Theory products.
                  </div>)
                },
              ]
            }
         },
         {
          title: 'Pulls',
          text: {
            items: [
              {render: () => (
                <div>
                  <b>Can I use my own pulls?</b><br/>
                  You are free to use your own pulls. The cabinets will be sent without pulls and your contractor can install them on site.
                </div>)
              },
            ]
          }
         },
         {
           title: 'Countertops',
           text: {
             items: [
               {render: () => (
                 <div>
                   <b>Can we provide our own countertops?</b><br/>
                   Yes. Our system is designed so that you have the option to use our countertops or provide your own.
                 </div>)
               },
               {render: () => (
                 <div>
                   <b>Can you provide countertops? If so what materials?</b><br/>
                   Space Theory countertops are available in solid surface acrylic and Paperstone at 1/2" thickness. Options include sink cutouts and drainfields. <Link className='accordion-text-item-link blue' to={'/page/materials'}>Here is a link</Link> to our materials.
                 </div>)
               }
             ]
           }
         },
         {
           title: 'Accessories',
           text: {
             items: [
               {render: () => (
                 <div>
                   <b>Can I purchase your drawer accessories separately?</b><br/>
                   Our proprietary internal drawer components and storage options are only available with the purchase of a kitchen system. If you have a Space Theory Kitchen you can purchase additional components at any time.
                 </div>)
               },
               {render: () => (
                 <div>
                   <b>How do I determine the layout or number of inserts for my drawers?</b><br/>
                   Our drawer inserts are all modular and can be easily reconfigured onsite. We will help walk through the available options and provide recommendations to help maximize your storage. See our <a className='accordion-text-item-link blue' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-drawer-component-guide.pdf' target='_blank'>drawer component guide.</a> for additional information.
                 </div>
               )},
             ]
           }
         },
         {
          title: 'Design',
          text: {
            items: [
              {render: () => (
                <div>
                  <b>How long does the review phase take?</b><br/>
                  Refining and customizing your layout takes on average 3-4 weeks.
                </div>)
              },
              {render: () => (
                <div>
                  <b>What type of drawings do you provide?</b><br/>
                  We customize and refine your layout with you using our proprietary Design Engine software. We provide you dimensioned and production ready floor plans and elevations which are supported by real life reference images and material samples.
                </div>)
              },
              {render: () => (
                <div>
                  <b>Will the design drawings include dimensions?</b><br/>
                  All of our drawings include detailed dimensions of the specific products used in the project. Our product is specifiable to the 1/4" and is designed and produced for a customized fit to your space.
                </div>)
              },
              {render: ({showInquiryForm}) => (
                <div>
                  <b>How do I order material samples?</b><br/>
                  <span className='accordion-text-item-link blue' onClick={() => showInquiryForm()}>Get in Touch</span> to order samples specific to your project.
                </div>)
              }
            ]
          }
        },
        {
          title: 'Installation',
          text: {
            items: [
              {render: () => (
                <div>
                  <b>Do you install product? </b><br/>
                  Space Theory kitchens ship fully assembled and ready to install by any trained trim carpenter. Installation instructions are provided in advance and all product arrives with installation instructions on each unit.  Our installation support team is easily reached via phone or email.
                </div>)
              },
              {render: () => (
                <div>
                  <b>Do you have contractors or installers you recommend?</b><br/>
                  Your design engineer can provide a list of installers who have experience installing Space Theory kitchens.
                </div>)
              },
              {render: () => (
                <div>
                  <b>Can I get help with measurements of my space?</b><br/>
                  Our system has built in flexibility which means we only need a few <a className='accordion-text-item-link blue' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-planning-guide.pdf' target='_blank'>key measurements</a> to ensure a beautifully fit kitchen. Your Project Engineer will identify your key measurements and will coordinate with you or your contractor to verify them prior to production.
                </div>)
              }
            ]
          }
        },
       ],
    }
  ]
};
