import {_, Component, React, PropTypes, className} from 'components'; //eslint-disable-line
import './text.scss';

export class TextItem extends Component {
  state = {isVisible: false}

  static defaultProps = {
    fadeInDelay: 100,
    fadeInFrequency: 400
  }

  componentDidMount() {
    this.setTimeout(() => {
      this.setState({isVisible: true});
    }, this.props.fadeInDelay + this.props.index * this.props.fadeInFrequency);
  }

  componentWillUnmount() {
    this.setState({isVisible: false});

    super.componentWillUnmount();
  }

  render() {
    var {item, index} = this.props;
    var classSuffix = item.className || (item.isLink ? 'link' : 'plain');
    var content;

    if (item.render) {
      content = item.render({showInquiryForm: this.props.showInquiryForm});
    }
    else {
      content = _.trim(item.text);
    }

    return (
      <div key={index} {...className([
        `page-section-text-item text-item-${classSuffix}`,
        this.state.isVisible && 'is-visible'
      ])}>
        {item.isLink ? <a key={index} href={item.to}>{content}</a> : content}
      </div>
    );
  }
}

export default class Text extends Component {
  static propTypes = {
    alignX: PropTypes.string,
    alignY: PropTypes.string,
    quote: PropTypes.shape({
      body: PropTypes.string,
      author: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
  }

  static defaultProps = {
    alignX: 'left',
    alignY: 'bottom'
  }

  render() {
    var {items, quote, award, alignX, alignY} = this.props;

    items = _.map(items, item => typeof(item) === 'string' ? {text: item} : item);

    return (
      <div className='page-section-text'>
        <div className={`text-container align-y-${alignY} align-x-${alignX}`}>
          {award && (
            <div {...className(['award-content', award.link && 'has-link'])}>
              {award.link ? (
                <div>
                  <a href={award.link} target='_blank'>
                    <div className='award-media'><img src={award.media[0].src} /></div>
                    <div className='award-title-container'>
                      <div className='award-title'>{award.award}</div>
                      <div className='award-date'>{award.date}</div>
                    </div>
                  </a>
                  <div className='award-body'>{award.body.render ? award.body.render() : award.body}</div>
                </div>
              ) : (
                <>
                  <div className='award-media'><img src={award.media[0].src} /></div>
                  <div className='award-title-container'>
                    <div className='award-title'>{award.award}</div>
                    <div className='award-date'>{award.date}</div>
                  </div>
                  <div className='award-body'>{award.body.render ? award.body.render() : award.body}</div>
                </>
              )}
            </div>
          )}
          {quote && (
            <div {...className(['quote-content', quote.link && 'has-link'])}>
              {quote.link ? (
                <a href={quote.link} target='_blank'>
                  <div className='quote-body'>{quote.body}</div>
                  <div className='quote-author'>- {quote.author}</div>
                </a>
              ) : (
                <div>
                  <div className='quote-body'>{quote.body}</div>
                  <div className='quote-author'>- {quote.author}</div>
                </div>
              )}
            </div>
          )}
          {(!quote || !award) && (
            <div className='text-content'>
              {_.map(items, (item, i) => <TextItem key={i} item={item} index={i} {..._.pick(this.props, ['showInquiryForm'])}/>)}
            </div>
          )}
        </div>
      </div>
    );
  }
}
