import {React} from 'components';
import {link/*, colors*/} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'functional-innovation',
  title: 'Functional Innovation',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Functional Innovation'},
          `The bulk of what makes up the vast majority of kitchens – the cabinets, the work surfaces, and the adjacent walls – has been unimproved in terms of functionality for 100 years. This leaves most of us with a lack of order and difficulty in storing and finding things that keeps a lot of people from fully enjoying the experience of cooking and cleaning and even socializing in the kitchen.`
        ]
      },
      media: [{orientation: 'square', src: link('flexible/flexible-1.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `We are focused on functional innovation to create an intuitive, free flowing everyday experience. One that you can easily arrange so it works for you. If you want your cutting board off the counter and it looks good enough to go on the wall, you can make that happen. If you prefer it in a drawer, it will fit in a clean, modular way into the drawer.`
        ]
      },
      media: [{orientation: 'square', src: link('flexible/flexible-2.jpg')}]
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('flexible/flexible-1.mp4#t=0.1')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The tools you need to create the space you want are designed as part of the system. Stainless steel and wood drawer components are designed to fit neatly in a flexible way into the soft close drawers. The drawer interiors are designed as carefully as the rest of the system.`
        ]
      },
      media: [{orientation: 'square', src: link('flexible/flexible-3.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Happy clutter'},
          `If you prefer everything out of view, the fitted drawer helps you get more into a drawer, but in a way that allows you to find it quickly. The Space Theory Opencase wall system allows you to keep what you want to in plain sight. It can be used as part of a backsplash wall, or separately in a pantry or an adjacent wall in the kitchen.`
        ]
      },
      media: [{orientation: 'square', src: link('flexible/flexible-4.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Add on easily'},
          `If you need a shelf, or a wine rack or a utensil bar later, after you decide you want your utensils hanging on the wall, for example, you can just order one, and arrange your wall. The wall system helps you take advantage of otherwise unused space and keep the right things at your fingertips.`,
          // {render: () => (
          //   <Link className='text-item-link next-page' to={'/page/premium-quality'}>
          //     Premium Quality ⟶
          //   </Link>
          // )},
        ]
      },
      media: [{orientation: 'square', src: link('flexible/flexible-5.jpg')}]
    }
  ]
};
