import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'on-location',
  title: 'Space Theory on Location',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'Space Theory on Location'},
          {className: 'smaller-text-space-after', text: 'Real People. Real Projects.'},
          {className: 'smaller-text', text: `Space Theory was created to offer a new level of relaxed order, quality and pleasure while cooking, socializing and just...living.`}
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
          items: [
          {className: 'larger-text-space-after', text: `“It fits the way we want to live.”`},
          {render: () => (
            <Link className='text-item-link larger-text' to={'/page/understory'}>
              Read The Understory
            </Link>
          )}
        ]
      },
      media: [
        {orientation: 'square', src: link('on-location/1.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'larger-text-space-after', text: `“With Space Theory that precision and the detail is a given, and it is still custom enough to integrate beautifully.”`},
          {render: () => (
            <Link className='text-item-link larger-text' to={'/page/stripped-down-suburban'}>
              Read Stripped Down Suburban
            </Link>
          )}
        ]
      },
      media: [
        {orientation: 'square', src: link('on-location/2.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'larger-text-space-after', text: `“Space Theory helped navigate us in making choices for creating a kitchen we want to live in.”`},
          {render: () => (
            <Link className='text-item-link larger-text' to={'/page/creative-assets'}>
              Read Creative Assets
            </Link>
          )}
        ]
      },
      media: [
        {orientation: 'square', src: link('on-location/3.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'larger-text-space-after', text: `“Space Theory is just so smooth and functional. It makes the chores of life easier and more fun.”`},
          {render: () => (
            <Link className='text-item-link larger-text' to={'/page/a-modern-interpretation'}>
              Read A Modern Interpretation
            </Link>
          )}
        ]
      },
      media: [
        {orientation: 'square', src: link('on-location/4.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'larger-text-space-after', text: `“A lot is happening in the wall of the kitchen, architecturally and practically. It’s the backdrop to life.”`},
          {render: () => (
            <Link className='text-item-link larger-text' to={'/page/the-nonconformist'}>
              Read The Nonconformist
            </Link>
          )}
        ]
      },
      media: [
        {orientation: 'square', src: link('on-location/5.jpg')}
      ]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'larger-text-space-after', text: `“I wanted what their approach offers - an easy process, thoughtful details and functionality, and the trust that comes with that.”`},
          {render: () => (
            <Link className='text-item-link larger-text' to={'/page/mise-en-scene'}>
              Read Mise en Scène
            </Link>
          )}
        ]
      },
      media: [
        {orientation: 'square', src: link('on-location/6.jpg')}
      ]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {render: () => (
            <Link className='no-underline blue' to={'/page/project-stories'}>
              Explore all our project stories from coast to coast
            </Link>
          )}
        ]
      },
    }
  ]
};
