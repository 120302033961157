import {React} from 'components';
import {link} from './helpers';
import {gridCellGroupsFor} from './helpers';

// istanbul ignore file

export default {
  key: 'daylight',
  title: 'Daylight',
  sections: [
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('daylight/1.jpg'), position: 'bottom'}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Daylight is a new standalone kitchen island system developed to meet the competing challenges of lifelong use and the changing needs of our increasingly dynamic lives. This new product embodies Space Theory’s focus on creating premium quality, innovative cooking and socializing spaces with a new level of flexible, built-in functionality… while also making them simple to design and put into use.`,
          {render: ({showInquiryForm}) => (
            <a className='text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get in touch for more information
            </a>
          )},
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('daylight/2.jpg')}],
    },
    {
      layout: 'text',
heightAuto: true,
      text: {
        items: [
          `Daylight is a unique product in a very thinly populated field. Even though islands are a central component of many kitchens, there are very few sophisticated islands available on the market as stand-alone pieces. As we become more mobile and want to make our homes more beautiful and functional with less effort, this becomes particularly appealing.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('daylight/3.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Daylight is an open, flexible concept based on a 24” grid of welded steel. The grid-like structure enables a range of playful configurations of drawers, drawer fittings, appliances and specialized storage units designed specifically for this island.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('daylight/4.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Daylight is designed to be a ‘visually clear’ workbench, so you can see what is where and reach it easily, but there is an aesthetic structure provided by the design of the individual components in each bay that keeps things looking good. Configurations do allow a balance of open and closed bays, if desired.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('daylight/5.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Using the Space Theory Design Engine, Daylight can be quickly and easily configured to contain from 2 to 5 bays. Each bay can be specified with a range of components so that it is tailored to your needs.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('daylight/daylight.mp4#t=0.1')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Flexible in both length and in terms of the components within the grid, the design engine allows precise control of the options, and pricing in real time as changes are made.`
        ]
      }
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        `The drawer division and accessory system is interchangeable with the Space Theory kitchen system program. The stainless steel system of storage trays used in the drawers are made from cutoffs created during the fabrication of the sinks used in the Space Theory line.`,
        {render: () => (
          <div>
            <a className='text-item-link blue' href='/page/flexible-organization' target='_blank'>Learn More</a>
          </div>
        )}
      ]},
      cellGroups: gridCellGroupsFor({page: 'daylight', section: 'details', groups: [
        {cells: [
          {title: '', key: 'detail-1a', details: {}},
          {title: '', key: 'detail-1b', details: {}},
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        `Dovetailed solid wood drawers sit as sculptural elements within the frame.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'daylight', section: 'details', groups: [
        {cells: [
          {title: '', key: 'detail-2', details: {}},
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        `Daylight can be configured and designed to fit select ovens from Fisher & Paykel, Bosch, and Miele.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'daylight', section: 'details', groups: [
        {cells: [
          {title: '', key: 'detail-3', details: {}},
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        `A flexible tray unit is available that optimizes space, puts trays at your fingertips, while also keeping them visually in order.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'daylight', section: 'details', groups: [
        {cells: [
          {title: '', key: 'detail-4', details: {}},
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        `Concealed outlets are designed into partitions as needed.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'daylight', section: 'details', groups: [
        {cells: [
          {title: '', key: 'detail-5a', details: {}},
          {title: '', key: 'detail-5b', details: {}},
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'small'},
      text: {items: [
        `Countertops are available in wood, acrylic, Paperstone, or stone.`
      ]},
      // cellGroups: gridCellGroupsFor({page: 'daylight', section: 'details', groups: [
      //   {cells: [
      //     {title: '', key: 'detail-6', details: {}},
      //   ]}
      // ]})
      cellGroups: gridCellGroupsFor({page: 'daylight', section: 'details', groups: [
        {cells: [
          {title: 'Natural Walnut', key: 'natural-walnut', details: {}},
          {title: 'Muted Oak', key: 'muted-oak', details: {}},
          {title: 'Ivory Oak', key: 'ivory-oak', details: {}},
          {title: 'Gray Oak', key: 'gray-oak', details: {}},
          {title: 'Black Oak', key: 'black-oak', details: {}},
          {title: 'White', key: 'white', details: {}},
          {title: 'Ivory', key: 'ivory', details: {}},
          {title: 'Cloud', key: 'cloud', details: {}},
          {title: 'Paperstone-Onyx', key: 'paperstone-onyx', details: {}},
        ]}
      ]}),
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        `Heavy duty, built in, concealed leveler feet allow leveling of the island.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'daylight', section: 'details', groups: [
        {cells: [
          {title: '', key: 'detail-7', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        `Produced domestically in Henrybuilt’s Seattle-based production facility, the Daylight Island can be ordered for delivery throughout North America and select other countries.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'daylight', section: 'details', groups: [
        {cells: [
          {title: '', key: 'detail-8', details: {}},
        ]}
      ]})
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {items: [
        {className: 'sub-heading', text: 'Pricing'},
        `Pricing starts at $12,000 for a 3-bay configuration without appliances with the more extensive iterations being priced between $19,000 - $21,000, depending on the selection or omission of certain features.`,
        {render: ({showInquiryForm}) => (
          <a className='text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
            Get in touch for more information
          </a>
        )},
      ]}
    }
  ]
};
