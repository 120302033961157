import {_, Component, React, PropTypes, className, addOnClickOutside} from 'components'; //eslint-disable-line
import { Link } from 'react-router-dom';
import './list-item.scss';

export class ListItem extends Component {
  static propTypes = {
    page: PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string.isRequired,
      children: PropTypes.array
    }).isRequired,
    match: PropTypes.object.isRequired
  }

  static defaultProps = {
    level: 0,
    fadeInDelay: 50,
    fadeInFrequency: 25
  }

  constructor(props) {
    super(props);

    this.bind(['handleClick']);

    this.state = {isExpanded: false, isVisible: this.props.level > 0};
  }

  componentDidMount() {
    this.setTimeout(() => {
      this.setState({isVisible: true});
    }, this.props.fadeInDelay + this.props.fadeInFrequency * this.props.index);
  }

  toggleExpanded() {
    this.setState({isExpanded: !this.state.isExpanded});
  }

  handleClick(event) {
    if (this.props.navigationIsVisible) {
      this.toggleExpanded();
      this.trigger('click', {page: this.props.page});
    }
    else {
      // istanbul ignore next
      event.preventDefault();
    }
  }

  handleClickOutside() {
    if (this.state.isExpanded) this.setState({isExpanded: false});
  }

  render() {
    var {key, title, children, url, isStatic, spaceBefore, isUnlisted} = this.props.page;
    var isLink = (key || url) && !isStatic;
    var colors = [
      '#71706d', '#7e7d7a', '#8b8a87',
      '#989794', '#a5a5a2', '#b2b2af', '#c0bfbc', '#cdcdca', '#dadad7',
      '#e7e7e5', '#ededed'
    ];
    var color = colors[_.get(this.props, 'parentIndex', this.props.index)];

    var listItemTargetContent = (
      <div className='list-item-target-content'>
        <div className='list-item-target-text'>{title}</div>
      </div>
    );

    return (
      <div>
        {!isUnlisted && (
          <div {...className([
            'app-navigation-list-item',
            `list-item-level-${this.props.level}`,
            this.props.page.key && `page-key-${this.props.page.key}`,
            this.isExpanded && 'is-expanded',
            this.isActive && 'is-active',
            this.state.isVisible && 'is-visible',
            spaceBefore && 'space-before'
          ])}>
            {isLink ? (
              url ? (
                <a
                  className='list-item-target list-item-link'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={url}
                >{listItemTargetContent}</a>
              ) : (
                <Link
                  className='list-item-target list-item-link'
                  onClick={this.handleClick}
                  to={key === 'landing-page' ? '/' : `/page/${key}`}
                >{listItemTargetContent}</Link>
              )
            ) : (children ? (
              <div
                className='list-item-target list-item-group'
                onClick={this.handleClick}
              >{listItemTargetContent}</div>
            ) : (
              <div className='list-item-target' onClick={this.handleClick}>{listItemTargetContent}</div>
            ))}
            <div {...className(['list-item-children', children && children.length && 'has-children'])}>
              {_.map(children, (page, p) => (
                <ListItem
                  index={p}
                  parentIndex={this.props.index}
                  level={this.props.level + 1}
                  color={colors[p]}
                  key={p} page={page}
                  match={this.props.match}
                  on={{click: ({page}) => this.trigger('click', {page})}}
                  navigationIsVisible={this.props.navigationIsVisible}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  get isExpanded() {
    return this.state.isExpanded || (this.props.match.params.key && _.includes(_.map(this.allChildPages, 'key'), this.props.match.params.key));
  }

  get isActive() {
    var ownKey = this.props.page.key;
    var browserKey = this.props.match.params.key;

    if (this.props.match.path === '/' && ownKey === 'landing-page') {
      return true;
    }
    else {
      return ownKey && browserKey === ownKey;
    }
  }

  get allChildPages() {
    return this.props.page.children;
  }
}

export default addOnClickOutside(ListItem);
