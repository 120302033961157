import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'san-francisco-minimalist',
  title: 'San Francisco Minimalist',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'heading', text: `San Francisco Minimalist`},
          {className: 'sub-heading', text: `San Francisco, California`},
          {className: 'smaller-text', render: () => (
            <div>
              <div>Architecture: Min Design</div>
              <div>Contractor: AMG Construction</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('san-francisco-minimalist/1.jpg')
      }],
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('san-francisco-minimalist/2.jpg')
      }],
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('san-francisco-minimalist/3.jpg')
      }],
      text: {
        items: []
      }
    },
    {
      layout: 'doubleMedia',
      media: [
        {
          orientation: 'square',
          src: link('san-francisco-minimalist/4.jpg')
        },
        {
          orientation: 'square',
          src: link('san-francisco-minimalist/5.jpg')
        }
      ],
    },
    {
      layout: 'media',
      media: [
        {
          orientation: 'horizontal',
          src: link('san-francisco-minimalist/6.jpg')
        }
      ]
    },
    {
      layout: 'doubleMedia',
      media: [
        {
          orientation: 'square',
          src: link('san-francisco-minimalist/7.jpg')
        },
        {
          orientation: 'square',
          src: link('san-francisco-minimalist/8.jpg')
        }
      ],
    },
    {
      layout: 'media',
      media: [
        {
          orientation: 'horizontal',
          src: link('san-francisco-minimalist/9.jpg')
        }
      ]
    },
  ]
};
