import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'seattle',
  title: 'Seattle',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          'Monday - Friday by appointment',
          '4632 Ohio Ave S',
          'Seattle, WA 98134',
          {render: () => (
            <a className='phone' href='tel:2064543160'>206.454.3160</a>
          )},
          {render: () => (
            <a className='email' href='mailto:seattle@spacetheory.com'>seattle@spacetheory.com</a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('seattle/1.jpg')}],
    }
  ],
  structuredData: {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Space Theory",
    "description": "An intelligent kitchen system from the founders of Henrybuilt. Made to order in Seattle for homes throughout North America.",
    "url": "https://www.spacetheory.com/",
    "logo": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
    "image": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
    "hasMap": "https://maps.app.goo.gl/11hYnn7ReXcAhonH9",
    "branchOf": {
      "@type": "Organization",
      "name": "Space Theory",
      "url": "https://www.spacetheory.com"
    },
    "location": [
      {
        "@type": "LocalBusiness",
        "@id": "https://www.spacetheory.com/page/seattle",
        "name": "Seattle Location",
        "url": "https://www.spacetheory.com/page/seattle",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "4632 Ohio Ave S",
          "addressLocality": "Seattle",
          "addressRegion": "WA",
          "postalCode": "98134",
          "addressCountry": "United States"
        },
        "telephone": "+1-206-454-3160",
        "openingHours": "By Appointment",
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "47.561731247420255",
          "longitude": "-122.336926842055"
        }
      }
    ],
    "sameAs": [
      "https://www.facebook.com/spacetheorykitchens/",
      "https://www.instagram.com/space.theory/"
    ]
  }
};
