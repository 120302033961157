import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'budget-guide',
  title: 'Budget Guide',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Plan your budget'},
          `Coming up with an accurate budget for your new kitchen can be a daunting task. We have put together a quick guide below that helps walk you through the cost and factors that bring a kitchen together.`,
          {render: () => (
            <a className='text-item-link blue' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-budget-guide.pdf' target='_blank'>
              Space Theory Budget Guide
            </a>
          )},
          // {render: () => (
          //   <a className='text-item-link next-page' href= 'https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-pricelist.pdf' target='_blank'>
          //     Pricelist ⟶
          //   </a>
          // )}
        ]
      },
      media: [{orientation: 'square', src: link('budget/budget-1.jpg')}]
    }
  ]
}
