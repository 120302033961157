import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'stripped-down-suburban',
  title: 'Stripped Down Suburban',
  sections: [
    {
      layout: 'mediaTopTextBottom',
      media: [{
        orientation: 'horizontal',
        src: link('stripped-down-suburban/stripped-down-suburban-2.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>Stripped Down Suburban</div>
              <div className='project-story-location'>Williamstown, Massachusetts</div>
            </div>
          )
        }
      }],
      text: {
        items: [
          {render: () => (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              <div style={{marginRight: '2em', marginBottom: '0.25em'}}>Architecture: Group AU</div>
              <div style={{marginRight: '2em', marginBottom: '0.25em'}}>Contractor: Dan Barenski</div>
              <div>Photography: Jamie Goldenberg</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Architect Tessa Kelly and her clients – who relocated from Mexico to Williamstown, Massachusetts, where one is a chemistry professor at Williams College - transformed a rather mundane suburban split-level home into something quite magical.`,
          `Not bound by American suburban aesthetic norms, the clients delivered an invitation to step out of the ordinary.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('stripped-down-suburban/stripped-down-suburban-2.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {render: () => (
            <div>
            <b>The task:</b> renovate the kitchen into a gathering place for the family to cook and socialize. Central to their vision was a large island reminiscent of the laboratory benches of the chemistry department. Seventies American suburban architecture, Mexican heritage, and chemistry lab. An unusual trio of influences to braid together.
            </div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('stripped-down-suburban/stripped-down-suburban-3.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The solution was subtractive – taking away pieces rather than adding new elements.`,
          `“As we began working with the space, the project took on a design investigation into the bones of a typical 1970s American house, stripping the interior down to its elemental components,” explains Architect Tessa Kelly.`
        ]
      },
      media: [{orientation: 'square', src: link('stripped-down-suburban/stripped-down-suburban-4.jpg')}]
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('stripped-down-suburban/stripped-down-suburban-5.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The ceiling was opened, exposing the wood boards and metal strapping. Trim was removed from the original wood windows.`,
          `“Inspired by what we found, we developed a language of simple planes expressed through custom concrete elements, plywood benches, and the streamlined Space Theory kitchen,” says Tessa.`,
          {render: () => (
            <a className='text-item-link blue' href='/page/a-complete-system' target='_blank'>
              Learn more about Space Theory's Complete System
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('stripped-down-suburban/stripped-down-suburban-6.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `“It is very difficult to realize the precision we needed in the kitchen with custom millwork. Specifically, flat fronts and tight relationships with the architecture and custom concrete,” says Tessa. “With Space Theory that precision and the detail is a given, and it is still custom enough to integrate beautifully.”`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('stripped-down-suburban/stripped-down-suburban-7.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `The 18-foot custom concrete island is one part dining table (complete with chemistry lab stools) and one part storage.`,
          `The client chose Space Theory’s “Peacock Blue” to celebrate the blues inherent to Mexico. The concrete was custom colored to match, so the island reads as a singular bold form.`,
          {render: () => (
            <a className='text-item-link blue' href='/page/materials' target='_blank'>
              Explore Space Theory materials
            </a>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('stripped-down-suburban/stripped-down-suburban-8.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `The perimeter is a quiet gray. The laminate kitchen system fronts and concrete read as one, receding into the background as a single and visually quiet plane.`,
          {render: () => (
            <div className='long-paragraph' style={{opacity: 0.5}}>Custom concrete by Marveled Designs</div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('stripped-down-suburban/stripped-down-suburban-9.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'heading', text: 'This is a Space Theory kitchen.'},
          {render: () => (
            <div className='long-paragraph' style={{width: '100%'}}>
              We work with homeowners who don't have an architect. <b>We love that</b>. We work with architect, homeowner, designer teams. <b>We love that</b>. And quite often, we work with architects or interior designers who are the homeowner. <b>We love that, too</b>.
            </div>
          )},
          {render: ({showInquiryForm}) => (
            <a className='text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get started on your own Space Theory Kitchen
            </a>
          )},
        ]
      },
      media: [{orientation: 'square', src: link('stripped-down-suburban/stripped-down-suburban-10.jpg')}]
    },
  ]
};
