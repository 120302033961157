import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';
import {gridCellGroupsFor} from './helpers';

// istanbul ignore file

export default {
  key: 'design-guide',
  title: 'Design Guide',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'Design Guide'},
          `Below we have put together a guide to planning your kitchen. It’s a great way to get familiar with measuring your space, creating a budget, designing for function, and typical layouts. `
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Planning Your Kitchen'},
          `When laying out your space, it may be hard to know where to start. In the link below we have put together a simple guide to help you start this process including how to draw a plan and what to consider when laying out your space.`,
          {render: () => (
            <a className='text-item-link blue' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-planning-guide.pdf' target='_blank'>
            SPACE THEORY PLANNING GUIDE
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('layout-and-considerations/layout-and-considerations-6.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Areas of the Kitchen'},
          `Designing your kitchen around specific functional areas makes it more efficient and enjoyable. Creating work centers uses space efficiently, supports the workflow, and keeps items stored right where need them. Our product catalog is organized around work center areas to help simplify your planning process.`
        ]
      },
      media: [{orientation: 'square', src: link('layout-and-considerations/layout-and-considerations-1.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        {className: 'heading', text: 'Clean'},
        `This area of the kitchen supports the clean up of items used during cooking.  It is usually centered around the sink and is used to clean and dry dishes, wash produce, and store cleaning products. It is best located close to the area where cleaned items can be stored away.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'kitchen-areas', groups: [
        {cells: [
          {title: '', key: 'clean-1', details: {}},
          {title: '', key: 'clean-2', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        {className: 'heading', text: 'Storage'},
        `The storage area should be located close to the prep and cook space for ease of movement.  It is used to store pantry items, pots and pans, as well as refrigerated and frozen food. Typical store areas are tall pantries, however you can also use base storage drawers to hold dry goods and small appliances.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'kitchen-areas', groups: [
        {cells: [
          {title: '', key: 'storage-1', details: {}},
          {title: '', key: 'storage-2', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        {className: 'heading', text: 'Prep'},
        `The prep area, is where most of the work in the kitchen takes place and is often the hardest working area in the kitchen. The food prep area should allow for easy flow and transition between clean and cook areas. This is a perfect area to create  run of base units to store mixing bowls, trays, baking and cook utensils as the countertop surface above can be used as a workspace.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'kitchen-areas', groups: [
        {cells: [
          {title: '', key: 'prep-1', details: {}},
          {title: '', key: 'prep-2', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        {className: 'heading', text: 'Cook'},
        `This area is used to cook the food that has been previously prepared and is generally adjacent to the prep area. It will typically include a heat and ventilation source, whether from a range or a cooktop. For a streamlined cooking experience, the layout should include easy access to spices, herbs, and seasonings, along with sauce pans, frying pans, and additional cooking appliances.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'kitchen-areas', groups: [
        {cells: [
          {title: '', key: 'cook-1', details: {}},
          {title: '', key: 'cook-2', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Work Triangle'},
          `Most efficient kitchens utilize a principle called “the triangle.” This term refers to the space and walking distance between three critical areas of the kitchen: prep(sink), cook (range), and store (refrigerator). Each area can be separated but when combined form all the operations and functions needed to have a successful kitchen design.`
        ]
      },
      media: [{orientation: 'square', src: link('layout-and-considerations/layout-and-considerations-2.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {
        items: [
          `The triangle, to be most efficient, should be no more than 20ft in it's length. This allows for quick travel between the three areas to prepare and serve meals.`,
          `Idealy the distance between each work area in the kitchen should be more than 3ft apart to provide enough working room and to keep the space from feeling cramped.`
        ]
      },
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'work-triangle', groups: [
        {cells: [
          {title: '', key: 'work-triangle-2', details: {}},
          {title: '', key: 'work-triangle-3', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Layout types'},
          `Below are the classic layouts for an efficient kitchen. All kitchen layouts come with their own pros and cons. You will want to think through which one will provide you with the most efficient work zone layout for your specific situation. We use these standard layouts in our pricing examples as well to give you a good idea of how each can be used in our system.`
        ]
      },
      media: [{orientation: 'square', src: link('layout-and-considerations/layout-and-considerations-3.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        {className: 'heading', text: 'In-Line'},
        `The in-line kitchen takes the function of a typical workflow and stretches it into one line along a wall. These kitchens work best when space is minimal and narrow. Typically, these layouts require a minimum of 10’ of wall space and are intended to be used by 1- 2 people. Since the countertop space in this kitchen layout is at a premium, it is best to make sure that storage, backsplash and work areas are well thought through to keep counters as clear as possible.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'layouts', groups: [
        {cells: [
          {title: '', key: 'inline-1', details: {}},
          {title: '', key: 'inline-2', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        {className: 'heading', text: 'Galley'},
        `This layout consists of two lines of kitchen units facing each other in a narrow space with a corridor down the middle to provide access. This layout can provide a very efficient use of space as the workflow triangle is minimized. To make sure the space can accommodate two people working, it is best to have at least 48” between the lines of facing units. Because this is such an efficient layout, you will typically see galley layouts in professional chefs’ homes if space is tight.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'layouts', groups: [
        {cells: [
          {title: '', key: 'galley-1', details: {}},
          {title: '', key: 'galley-2', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        {className: 'heading', text: 'L-Shape'},
        `The L- shaped kitchen is a versatile layout that allows the standard triangle workflow to be kept reasonably compact. Prep, cook, and cleaning areas of the kitchen can be kept separate while providing adequate space in between for work areas. Typically, the “dead” spaces in the corners of these kitchens are filled with corner units to take advantage of additional storage. These kitchen layouts work well to provide an open space to add an island or table for serving.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'layouts', groups: [
        {cells: [
          {title: '', key: 'lshape-1', details: {}},
          {title: '', key: 'lshape-2', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        {className: 'heading', text: 'U-Shape'},
        `These kitchens provide a similar amount of versatility as an L-shaped kitchen but allow for considerably more storage space. Dead space in the corners of the layout can be taken up by corner units to provide additional storage. Because there can be a fair amount of space in this layout, it is best to consider the layout of the workflow triangle and at best restrict it to the bottom of the “U”.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'layouts', groups: [
        {cells: [
          {title: '', key: 'ushape-1', details: {}},
          {title: '', key: 'ushape-2', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {items: [
        {className: 'heading', text: 'Island'},
        `Reserved for kitchens with the most amount of available space, the island layout can add the greatest amount of versatility and storage in a kitchen layout. Typical islands are added to L-shaped or U-shaped kitchens to provide additional work/store space, but islands can be utilized on their own if desired. Islands also provide the kitchen with a dedicated space for entertaining or serving while also adding storage and functionality. When adding an island to a design, make sure workflow isn’t impeded by layout of elements on several sides of an island. Typically, it is best to keep function to one side of the island with storage or seating space to another.`
      ]},
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'layouts', groups: [
        {cells: [
          {title: '', key: 'island-1', details: {}},
          {title: '', key: 'island-2', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Interior System Details'},
          `The Space Theory System uses high-quality hardware in standard finishes for all of our units. Below we outline these details.`
        ]
      },
      media: [{orientation: 'square', src: link('layout-and-considerations/layout-and-considerations-4.jpg')}]
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {
        items: [
          `The interior of standard kitchen tall and base units are made with dark gray technical liner. Wood veneer is used for wall units and floating shelves. Interior shelves are slate laminate.`
        ]
      },
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'system-details', groups: [
        {cells: [
          {title: '', key: 'unit-interior-1', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {
        items: [
          `Interior drawer fronts are slate laminate on europly core.`
        ]
      },
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'system-details', groups: [
        {cells: [
          {title: '', key: 'interior-drawer-fronts-1', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {
        items: [
          `Drawer slides and rails are light gray, powdercoated steel which is more durable, functional, and operate more smoothly than wood drawers. Cross rails in drawers provide storage for pan lids or to divide storage space.`
        ]
      },
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'system-details', groups: [
        {cells: [
          {title: '', key: 'drawer-slides-1', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {
        items: [
          `Pulls are sent pre-mounted for ease of installation. During the design process you will be able to decide between multiple standard locations based on your needs.`
        ]
      },
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'system-details', groups: [
        {cells: [
          {title: '', key: 'pulls-1', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {
        items: [
          `Laminate is mounted to birch europly core and sealed.`
        ]
      },
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'system-details', groups: [
        {cells: [
          {title: '', key: 'laminate-1', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {
        items: [
          `Veneer fronts are edge banded with veneer edging and sanded to create a soft touch.`
        ]
      },
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'system-details', groups: [
        {cells: [
          {title: '', key: 'veneer-fronts-1', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {
        items: [
          `Wall units and floating shelves offer optional lighting built into the unit with an interior valance to provide even light across the run.`
        ]
      },
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'system-details', groups: [
        {cells: [
          {title: '', key: 'wall-units-1', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {
        items: [
          `Sink units with flip down front offer an aluminum ledge to house sponges, brushes, and other cleaning supplies. The bottom drawer is also notched to clear plumbing.`
        ]
      },
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'system-details', groups: [
        {cells: [
          {title: '', key: 'sink-units-1', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'large'},
      text: {
        items: [
          `Doors over 36" in height recieve an interior door stiffener to prevent the door from warping.`
        ]
      },
      cellGroups: gridCellGroupsFor({page: 'layout-and-considerations', section: 'system-details', groups: [
        {cells: [
          {title: '', key: 'doors-1', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: 'Capacity and Clearance'},
          `Space Theory has designed our storage units so that you have the most flexible, and optimized storage possible. To help make sure you use every inch of your space efficiently, we have put together a document outlining the clearance and capacity of our units.`,
          {render: () => (
            <a className='text-item-link blue' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-capacity-and-clearance-guide.pdf' target='_blank'>
              Space Theory Capacity and Clearance Guide
            </a>
          )},
          // {render: () => (
          //   <Link className='text-item-link next-page' to={'/page/budget'}>
          //     Budget ⟶
          //   </Link>
          // )},
        ]
      },
      media: [{orientation: 'square', src: link('layout-and-considerations/layout-and-considerations-5.jpg')}]
    },
  ]
}
