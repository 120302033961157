import {React} from 'components';
import {link/*, colors*/} from './helpers';

// istanbul ignore file

export default {
  key: 'design-engine',
  title: 'Design Engine',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'A better path to a great kitchen'},
          {render: () => (
            <div>
              We have developed design software that helps assure you will end up with a highly refined end result. This software is called the Space Theory Design Engine. <span style={{opacity: 0.5, fontSize: '50%'}}>pat. pending</span>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('design-engine/design-engine-2.mp4#t=0.1')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The Design Engine is a sophisticated tool that allows the highly accurate configuration of a Space Theory kitchen into your space.`,
          `We will use it with you to design your kitchen.`
        ]
      },
      media: [{orientation: 'square', src: link('design-engine/design-engine-12.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The Design Engine automatically integrates the refined aesthetic and functional aspects of the Space Theory system into your layout.`,
          `The details of the system are built into the software.`
        ]
      },
      media: [{orientation: 'square', src: link('design-engine/design-engine-7.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The Design Engine even senses the relationship of the product to architecture and creates the right detail.`
        ]
      },
      media: [{orientation: 'square', src: link('design-engine/design-engine-8.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `As we work with you and your design progresses, planning information is available to support decision making in real time.`,
          `Photographs associated with each component make the details and material options clear.`
        ]
      },
      media: [{orientation: 'square', src: link('design-engine/design-engine-9.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `The Design Engine also helps assure a good design by flagging potential problems or even preventing them entirely.`
        ]
      },
      media: [{orientation: 'square', src: link('design-engine/design-engine-10.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {render: () => (
            <div>
              By sharing the screen, real time decisions can be made making it a simpler and quicker path to a new kitchen. If you would like to get started on your project, <a href='/inquiry-form' target='_blank'>let us know</a>.
            </div>
          )},
        ]
      },
      media: [{orientation: 'square', src: link('design-engine/design-engine-11.jpg')}]
    },
  ]
};
