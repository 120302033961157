import {_, React, Component} from 'components';
import {Form as SemanticForm} from 'semantic-ui-react';

import TextInput from './inputs/text/text-input';
import DropdownInput from './inputs/dropdown/dropdown-input';
import RadioInput from './inputs/radio/radio-input';
import HiddenInput from './inputs/hidden/hidden-input';
import SubmitButton from './submit-button/submit-button';

import './form.scss';

class Form extends Component{
  static defaultProps = {
    useSubmitInput: false
  }

  constructor(props) {
    super(props);

    this.bind(['handleSubmit']);

    this.state = {isSubmitting: false};
  }

  handleSubmit({event}) {
    if (this.props.robotFieldValue === '') {
      event.preventDefault();

      alert('Unable to verify that you are not a robot. Please try again.');
    }
    else {
      return new Promise((resolve) => {
        this.setState({isSubmitting: true});

        var onSubmit = _.get(this.props, 'on.submit');

        if (onSubmit) {
          onSubmit()
          .then(() => {
            this.setTimeout(() => this.setState({isSubmitting: false}), 1000);
          })
          .catch((error) => {
            if (process.env.NODE_ENV !== 'test') console.error(error); //eslint-disable-line

            this.setState({isSubmitting: false});
          });
        }
      });
    }
  }

  render() {
    return (
      <SemanticForm {..._.pick(this.props, ['action', 'method', 'encType'])} onSubmit={(event) => this.handleSubmit({event})}>
        {this.props.children}
        {this.props.useSubmitInput && (
          <SubmitButton
            isSubmitting={this.state.isSubmitting}
            submitText={this.props.submitText}
            isSubmittingText={this.props.isSubmittingText}
          />
        )}
      </SemanticForm>
    );
  }
}

Form.TextInput = TextInput;
Form.DropdownInput = DropdownInput;
Form.RadioInput = RadioInput;
Form.HiddenInput = HiddenInput;
Form.SubmitButton = SubmitButton;

export default Form;
