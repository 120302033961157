import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'kindred-spirits',
  title: 'Kindred Spirits',
  sections: [
    {
      layout: 'mediaTopTextBottom',
      media: [{
        orientation: 'horizontal',
        src: link('kindred-spirits/1.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>Kindred Spirits</div>
              <div className='project-story-location'>Block Island, RI</div>
            </div>
          )
        }
      }],
      text: {
        items: [
          {render: () => (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              <div style={{marginRight: '2em', marginBottom: '0.25em'}}>Architecture by GO Logic</div>
              <div>Images by Tony Luong</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `“Everything came from somewhere else.”`,
          `It’s called the Outer Lands region for a reason. In the midst of it sits Block Island, 9 square miles of land lined with beaches and red bluffs. Long on open space, short on resources required to build a home.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('kindred-spirits/2.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `When Tom Risom and Pamela Austen embarked on a project to build a full-time home on what had been the Risom family’s summer habitat, they carried forward a tradition set by Tom’s father Jens Risom and his notorious A-frame on the property – utilizing home systems already designed and built by experts in their fields. A prefab house from Maine. Windows from Germany. And, a Space Theory kitchen from Seattle.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('kindred-spirits/3.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `“Prefab suffers an undeserved reputation as being cheap and low quality,” says Risom. “Like all things, some of it is. More often, however, it can be high caliber, efficient, and create a better result than cobbling together a home the traditional way.”`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Designed and built in Seattle, Space Theory kitchens arrive on site with each unit structurally built. They are packed in reusable wood containers at the shop and not removed until they arrive on site, preventing shipping damage and reducing waste. Minimal internal packing material is required, and the containers are sent back – used upwards of 15 times.`
        ]
      },
      media: [{orientation: 'square', src: link('kindred-spirits/4.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `“Simplicity is what we were after. But, as my father professed all his life, getting a simple result is hard,” says Risom. It requires precise execution, exacting standards, and practice - product development, testing, and improvement – so your home is not the testing ground, rather it is receiving proven performance. It’s what most of us experience and expect in many of the necessities in our lives – cars, electronics, etc.`,
          `And, furniture.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('kindred-spirits/5.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `In addition to simplicity, size was a major player. Especially when it came to the kitchen. Both in terms of the footprint of the kitchen itself, but also because it occupied a spot in the main living space.`,
          `“We wanted the kitchen to be top notch,” says Austen. An academic, her propensity to deep research led her to Space Theory. “They have it all figured out in terms of how it works, which meant that we weren’t overwhelmed with too many decisions. And, the decisions we did have were easy and up our alley.”`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('kindred-spirits/6.jpg')}],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `What Tom and Pamela didn’t know was that years before a photo of the A-frame home on the property, ripped from the pages of Dwell magazine a decade earlier, was tacked at the center of the inspiration board for a new kitchen system being developed by Henrybuilt.`,
          {render: () => (
            <i style={{opacity: 0.5}}>
              Image via Dwell
            </i>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('kindred-spirits/7.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `“It felt like we were kindred spirits,” said Risom. “It told me instantly that we would have a rapport, that we were after the same goals. Being honest with materials, considering function over form, but not too far ahead of it.”`
        ]
      },
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `“It was inspiring to be working within the same family tree,” said Seth Tucker, Space Theory’s Product Manager. “Tom had a great understanding of the system approach, that everything essential to functionality is built into the overall design, so we could put our collaborative focus on how they wanted the space to feel and work for them.”`
        ]
      },
      media: [{orientation: 'square', src: link('kindred-spirits/8.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `“The nature of Space Theory’s design fits the kitchen to the people, rather than the other way around. It feels intuitive to use – all in the mechanism of preparing food,” says Risom. “The thing is, when it is intuitive it becomes invisible, fluid. When it isn’t, that is when you notice it – it becomes something you trip on everyday. Pam and I are left handed, we know that story.”`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('kindred-spirits/9.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `Nature abounds on the island, the site, and through the windows of the home. Wood is used inside to echo its setting. The couple was drawn to Space Theory’s options to add a pop of color into the island - to break up the use of wood, add an element of fun, and give a nod to the legacy of the Risom A-frame.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {type: 'sub-heading', text: `Flexible Organization`},
          `The tools you need to create the organization you want. Space Theory's modular wood and stainless steel drawer inserts keep your tools at hand, while enabling you to change the layout to suit your needs and taste.`,
          {render: () => (
            <a className='text-item-link blue' href='/page/flexible-organization' target='_blank'>
              Learn more
            </a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('kindred-spirits/promo-1.jpg')}]
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('kindred-spirits/10.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          `A holistic approach to building and living. Signed, sealed, delivered.`
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('kindred-spirits/11.jpg')}]
    },
    {
      layout: 'text',
      text: {
        items: [
          {className: 'sub-heading', text: 'This is a Space Theory kitchen.'},
          {render: () => (
            <div className='long-paragraph' style={{width: '100%'}}>
              We work with homeowners who don't have an architect. <b>We love that</b>. We work with architect, homeowner, designer teams. <b>We love that</b>. And quite often, we work with architects or interior designers who are the homeowner. <b>We love that, too</b>.
            </div>
          )},
          {render: ({showInquiryForm}) => (
            <a className='text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
              Get started on your own Space Theory Kitchen
            </a>
          )},
        ]
      }
    },
  ]
};
