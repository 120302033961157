import {React} from 'components';
import {link/*, colors*/} from './helpers';

// istanbul ignore file

export default {
  key: 'lifelong-product-simple-fixes',
  title: 'Lifelong Product. Simple Fixes.',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'Lifelong Product. Simple Fixes.'},
          `From stains to dents and dings, these four care tips will help keep your kitchen in top shape—so you don’t have to worry about the wear and tear of the everyday.`
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: `1. Tuning`},
          `Drawers and doors can move out of adjustment occasionally with heavy use. Our hardware makes it simple to adjust them back into alignment using a screwdriver. For drawers, just gently pry off the Space Theory nameplate on the side of the drawer to access the tuning screws.`
        ]
      },
      media: [{orientation: 'square', src: link('lifelong-product-simple-fixes/1.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: `2. Tightening Loose Pulls`},
          {render: () => (
            <div>Space Theory pulls are held on to drawers with Phillips screws and occasionally loosen with use. Pulls can be tightened using a Phillips screwdriver, which you can purchase <a className="no-underline blue" href="https://www.amazon.com/Blum-625TBIx1-Driver-Nickel-Finish/dp/B078H7W9VC/?_encoding=UTF8&pd_rd_w=P476x&content-id=amzn1.sym.dde481d7-92dc-42ce-a703-f1bc175e21c6%3Aamzn1.symc.d10b1e54-47e4-4b2a-b42d-92fe6ebbe579&pf_rd_p=dde481d7-92dc-42ce-a703-f1bc175e21c6&pf_rd_r=5CJN04RCWP5PRVTNZRWR&pd_rd_wg=Acaox&pd_rd_r=ed1517ed-f4ac-4aff-8f3e-ae5aaf069a8a&ref_=pd_hp_d_atf_ci_mcx_mr_hp_atf_m" target="_blank">here</a>. The screws are located behind the pull on the inside of the door or drawer front.</div>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('lifelong-product-simple-fixes/2.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: `3. Cutting Board Maintenance`},
          `Our food-safe Danish oil, provided in your customer care kit, can be used to revitalize your cutting boards. Oiling regularly provides a barrier to repel water and other liquids, which helps prevent bacteria growth.`,
          {render: () => (
            <div>If you don’t have oil left in your care kit, you can purchase it <a className="no-underline blue" href="https://www.amazon.com/Tried-True-Danish-Oil-Quart/dp/B08V5FJBH1/ref=sr_1_2_sspa?crid=BEJPYBLFQKMI&dib=eyJ2IjoiMSJ9.id-sGK42qgoYRJlgPUVMYBaIcbmmBQ82SgXNznDNGRwBahJ3Ir6HgUT1Jt5xdmzedoNwq5i31A_iFLCsOhn6bsR8iDN4bAlkFYQsIpEqIZ40eu3HQ9tvZEQ2JwYVCf21yfknZ439ajKPzP8PjdEFRUKBbTx-VkUunQFk6cxppz3UYgy6agYYb0wP0pXIAw4aSVjvJWLUE7rZYulRle0mYejTRFqjxGW4qEXcxHtFqndzUQAJnAi13vM1IfhnV16eB8GQe1xgcHKijLR_o4RVABcj-OoIj0gq2pVYAHO63jk.B8msDFo5yr_9_jSO1_ElhwEr5M8KfRGJgu9cw-4xs8I&dib_tag=se&keywords=Danish%2Boil&qid=1712862747&s=hpc&sprefix=Danish%2Boil%2Chpc%2C180&sr=1-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1" target="_blank">here</a>.</div>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('lifelong-product-simple-fixes/3.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: `Knife Block Maintenance`},
          `The same steps can be applied to revitalize knife blocks and other wooden interior components.`
        ]
      },
      media: [{orientation: 'square', src: link('lifelong-product-simple-fixes/4.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'sub-heading', text: `4. Repairing and Refurbishing Drawer Edges`},
          `The tops of drawer fronts and doors take a beating. Small scratches and breaks in the finish can be minimized and protected against by applying a small amount of Danish oil, rubbed in with a lint-free cloth. It only takes a little; just be sure to fully rub the wet residue off. If you get a bit of oil on the front, that’s not a problem—simply make sure to rub it off completely so it doesn’t leave a shiny spot. It’s better to do a second coat later than to leave too much.`
        ]
      },
      media: [{orientation: 'square', src: link('lifelong-product-simple-fixes/5.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {render: () => (
            <div>If you have other tuning and maintenance questions, please reach out to <a className="no-underline blue" href="mailto:fieldexperts@spacetheory.com">fieldexperts@spacetheory.com</a>.</div>
          )}
        ]
      }
    }
  ]
};
