import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'workplace',
  title: 'Workplace',
  sections: [
    // {
    //   layout: 'textLeftMediaRight',
    //   text: {
    //     items: [
    //       `One thing we can all agree on is that the kitchen is the heart of the home. The same is increasingly true at the office.  Now more than ever, communal kitchens serve as  spaces for connecting with teammates and building office culture.`,
    //       `Our award winning system creates  an intuitive, free-flowing experience for everyday use in the kitchen - one that is  ideal for gathering, meeting, and entertaining - talk about office perks.`,
    //     ]
    //   },
    //   media: [{orientation: 'square', src: link('workplace/1.jpg')}]
    // },
    // {
    //   layout: 'textLeftMediaRight',
    //   text: {
    //     items: [
    //       `Our commitment to quality ensures our materials withstands workplace wear and tear. Our  built-in flexibility adapts to the inevitable changes  within the office environment.`,
    //       `Our complete system includes cabinets, pulls, countertops, backsplash, lighting, interchangeable functional accessories, and even the kitchen sink - so you can get an integrated system that installs in one quick push - and get back to managing your business.`,
    //       `Clients include AIASF, BMW, Heath Ceramics, and Kohler.`
    //     ]
    //   },
    //   media: [{orientation: 'square', src: link('workplace/2.jpg')}]
    // },
    {
      layout: 'accordionWithMedia',
      heightAuto: true,
      defaultMedia: [{orientation: 'square', src: link('workplace/1.jpg')}],
      accordionTitle: 'WORKPLACE',
      accordionDescription: {render: () => (
        <div>
          <p>
            One thing we can all agree on is that the kitchen is the heart of the home. The same is increasingly true at the office.  Now more than ever, communal kitchens serve as  spaces for connecting with teammates and building office culture.
          </p>
          <p>
            Our award winning system creates  an intuitive, free-flowing experience for everyday use in the kitchen - one that is  ideal for gathering, meeting, and entertaining - talk about office perks.
          </p>
          <p style={{opacity: 0.8, fontSize: '80%'}}>
            Clients include AIASF, BMW, Heath Ceramics, and Kohler.
          </p>
        </div>)
      },
      accordionSections: [
        {
          title: 'A COMPLETE SYSTEM',
          text: {
            items: [
              `Our complete system includes cabinets, pulls, countertops, backsplash, lighting, interchangeable functional accessories, and even the kitchen sink - so you can get an integrated system that installs in one quick push - and get back to managing your business.`
            ]
          },
          media: [{orientation: 'square', src: link('workplace/2.jpg')}]
        },
        {
          title: 'QUALITY',
          text: {
            items: [
              `Our commitment to quality ensures our materials withstands workplace wear and tear. Our  built-in flexibility adapts to the inevitable changes  within the office environment.`,
              {render: () => (
                  <div>
                    Designing and building a durable and adaptable product is our goal, which is good for your investment and the planet. Our product is built to withstand the wear and tear that comes with the everyday comings and goings of the office. Go <Link className='accordion-text-item-link blue' to={'/page/premium-quality'}>here</Link> to learn more about the quality of our product.
                  </div>
                )
              }
            ]
          },
          media: [{orientation: 'square', src: link('workplace/6.jpg')}]
        },
        {
          title: 'ADAPTABILITY',
          text: {
            items: [
              `Our functionally innovative system allows you to easily transition from an internal brainstorm session to a client happy hour - a shelf easily lifts off to become a serving tray, knobs for the cutting board shift to hanging rods in a matter of a twist and a few seconds. Interior drawer systems are designed for durability and flexibility. Stainless steel inserts easily move from drawer to counter to dishwasher and back. There's a place for everything, and everything is in its place. It's intuitive, because it's built into the system. `
            ]
          },
          media: [{orientation: 'square', src: link('workplace/4.jpg')}]
        },
        {
        title: 'INSTALLATION',
        text: {
          items: [
            `To save you time and money on installation and to assure quality, individual components ship fully assembled. We provide straightforward installation drawings for your installer to clarify layout. Any information required to install a specific product is on the product itself. We provide pre-drilled holes with connectors, indexing jigs, and online and telephone support if you need it. We also provide information on preparing your construction site for installation.`,
            {render: ({showInquiryForm}) => (
              <a className='accordion-text-item-link blue' style={{cursor: 'pointer'}} onClick={() => showInquiryForm()}>
                Get in touch.
              </a>
            )},
          ]
        },
        media: [{orientation: 'square', src: link('workplace/5.jpg')}]
       }
      ]
    }
  ]
};
