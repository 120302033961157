import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'all-about-flow',
  
  title: 'All About Flow',

  sections: [

    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [

          {className: 'heading', text:  `IT'S ALL ABOUT FLOW`},
          {className: 'sub-heading', text:  `How Henrybuilt and Space Theory partner to create refined outcomes`},
          {className: 'smaller-text-space-after', text:  `Henrybuilt’s founders created Space Theory in 2019 to offer the same high quality, flexible kitchen systems at a more accessible price—35 to 40 percent lower, to be specific. Both brands feature exceptional materials and craftsmanship that allow for site-specific designs, architectural integration, and optimal function. The main difference comes down to process: Henrybuilt’s expert architects take a hands-on approach to every stage of a project, while Space Theory’s streamlined production procedure enables its products to flow seamlessly through the design and manufacturing phases without intervention.
          `},
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('its-all-about-flow/1.jpg')
      }]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text:  `Three steps to efficiency`},
          {className: 'smaller-text-space-after', text:  `Space Theory’s quality is nearly the same as Henrybuilt’s, but costs significantly less. How is that possible? Three factors make Space Theory’s flow through production highly efficient.
          `},
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `
          First, its products are carefully edited. Space Theory eliminates the furniture-like, intricately manufactured pieces that Henrybuilt offers—without reducing their quality or potential for customization—in favor of simpler objects. 
          `
        ]
      },
      media: [{orientation: 'vertical', src: link('its-all-about-flow/2.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `
          Second, Henrybuilt’s founders collaborated with Symbolic Frameworks to create a software system called the Design Engine, which applies specified design standards to Space Theory products as a project is configured and created. Space Theory’s Design Engine automatically eliminates most mistakes—from design and pricing to production and engineering—while Henrybuilt’s architects work directly with you throughout the design process.
          `
        ]
      },
      media: [{orientation: 'vertical',  src: link('its-all-about-flow/3.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `
          Third, Space Theory specializes in kitchens, while Henrybuilt can accommodate the whole home. Each brand optimizes its skills, equipment, and production methods accordingly. In this sense, the choice between Henrybuilt and Space Theory may simply depend on a project’s scope: Is it a kitchen or an entire house? Or is there room in the project for both?
          `
        ]
      },
      media: [{orientation: 'vertical',  src: link('its-all-about-flow/4.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text:  `Ultimately, it’s all about flow`},
          {className: 'smaller-text-space-after', text:  `By refining its design and production process, Space Theory’s efficiency results in a lower price point and a streamlined range of products.
          `},
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('its-all-about-flow/5.mp4')
      }]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text-space-after', text:  `Henrybuilt, at a higher cost, features a wide range of superior products that are implemented by our seasoned architects, who serve as your personal design aide. 
          `},
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('its-all-about-flow/6.jpg')
      }]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text-space-after', text:  `Whichever brand you choose, the project’s outcome will be a refined, long-lasting investment that evolves with the ways you live.
          `},
        ]
      }
    }
  ]
};
